import React, { useEffect, useRef, useState } from "react";
import * as images from "../../utilities/images";
import { useDispatch } from "react-redux";
import { addSubCategory, createCategory } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";

const SubCategoryModal = ({
  categoryName,
  close,
  getAllCategories,
  masterCategoryId,
  flag,
  getSubCategory,
}) => {
  const dispatch = useDispatch();
  const myInput = useRef();
  const [inputs, setInputs] = useState([{ id: 0, value: "" }]);
  const [temp, setTemp] = useState(0);
  const toastId = useRef(null);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // get the category names
  const handleInputChange = (index, event) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  // Function to add a new input field
  const handleAddInput = (e) => {
    e.preventDefault();
    const checkCurrentField = inputs?.find((value) => value.value === "");
    if (checkCurrentField) {
      showToast("Please add current field");
      return false;
    }
    const values = [...inputs];
    values.push({ id: values.length, value: "" });
    setInputs(values);
    setTemp((prev) => prev + 1);
  };

  // Function to remove an input field
  const handleRemoveInput = (e, index) => {
    e.preventDefault();
    if (index === 0) {
      showToast("Atleast one type is required");
      return;
    }
    const values = [...inputs];
    values.splice(index, 1);
    setInputs(values);
  };

  const handleSaveSubCategory = () => {
    // Check if any subcategory field is empty
    const isEmptyField = inputs.some((item) => item.value.trim() === "");
    if (isEmptyField) {
      showToast("Please enter Type");
      return;
    }
    const removeEmptyValues = inputs
      ?.filter((value) => value.value !== "" && value.value)
      ?.map((item) => item?.value);

    if (flag === "addSubCate") {
      let params = {
        name: categoryName,
        subCategories: removeEmptyValues,
      };
      dispatch(
        createCategory({
          ...params,
          cb(res) {
            if (res.status === 200) {
              close();
              getAllCategories();
            }
          },
        })
      );
    } else if (flag === "updateSubCate") {
      let params = {
        parentId: masterCategoryId,
        subCategories: removeEmptyValues,
      };
      dispatch(
        addSubCategory({
          ...params,
          cb(res) {
            if (res.status === 200) {
              close();
              getSubCategory();
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    if (myInput.current) myInput.current.focus();
  }, [temp]);

  return (
    <>
      <form>
        <div className="subCategorySection">
          <div className="categoryForm subCategoryInner">
            {inputs?.map((item, index, arr) => (
              <>
                <div key={index} className="inputFlex">
                  <input
                    ref={myInput}
                    value={item?.value}
                    onChange={(e) => handleInputChange(index, e)}
                    className="form-control categoryInput"
                    type="text"
                    placeholder="Enter Type"
                  />
                  {/* {index + 1 === arr.length ? (
                    <button
                      className="crossBtnCategory"
                      onClick={(e) => handleRemoveInput(e, index)}
                    >
                      <img
                        src={images.closeBtnImg}
                        className="img-fluid"
                        alt=""
                      />
                    </button>
                  ) : (
                    <button
                      onClick={(e) => handleRemoveInput(e, index)}
                      className="crossBtnCategory"
                    >
                      <img
                        src={images.closeBtnImg}
                        className="img-fluid"
                        alt=""
                      />
                    </button>
                  )} */}
                </div>
                {/* <div key={index} className="inputFlex">
                <input
                  ref={myInput}
                  value={item?.value}
                  onChange={(e) => handleInputChange(index, e)}
                  className="form-control categoryInput"
                  type="text"
                  placeholder="Enter Sub Category"
                />
                {index + 1 === arr.length ? (
                  <button
                    className="crossBtnCategory"
                    onClick={(e) => handleRemoveInput(e,index)}
                  >
                    <img src={images.closeBtnImg} className="img-fluid" alt="" />
                  </button>
                ) : (
                  <button
                    onClick={(e) => handleRemoveInput(e,index)}
                    className="crossBtnCategory"
                  >
                    <img
                      src={images.closeBtnImg}
                      className="img-fluid"
                      alt=""
                    />
                  </button>
                )}
              </div> */}
              </>
            ))}
            {/* <button className="addMoreBtn" onClick={(e) => handleAddInput(e)}>
              <img className="addMoreImg" src={images.addMoreImg} alt="" />
              Add More
            </button> */}
          </div>
          <button
            className="blockbtn w-100"
            type="button"
            onClick={(e) => {
              handleSaveSubCategory();
            }}
          >
            Add
          </button>
        </div>
      </form>
    </>
  );
};

export default SubCategoryModal;
