import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthSelector } from "../redux/selector/auth";

const ProtectRoute = ({ children }) => {
  const authSelector = useAuthSelector();
  const { loginData, isLoggedIn } = authSelector;

  return isLoggedIn && loginData && Object.keys(loginData)?.length > 0 ? (
    children
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectRoute;
