import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import { adminLogin, onErrorStopLoad } from "../../redux/slices/auth";
import logo from "../Images/logo.png";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastId = useRef(null);
  const authData = useAuthSelector();
  const { loading } = authData;
  const [passwordShown, setPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email.trim()) {
      showToast("Please enter your email");
      return;
    } else if (
      formData.email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formData.email
      )
    ) {
      showToast("Please enter valid email");
      return;
    } else if (!formData.password) {
      showToast("Please enter your password");
      return;
    }

    let params = {
      email: formData.email.trim(),
      password: formData.password,
    };
    dispatch(
      adminLogin({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/home");
          } else {
            toast.error("Please try after sometime!");
          }
        },
      })
    );
  };

  /**
   * toggle password
   */
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg adminlgn">
        <div className="brandlogo">
          <img src={logo} alt="brandlogo" />
        </div>
        <div className="card card-outline card-primary cardTopOutline cardBg">
          <div className="card-header text-center border-0">
            <h3 className="mb-0 logintext">Login</h3>
            <p className="form_subheading mt-3">
              Please enter your email address & password for continue.
            </p>
          </div>
          <div className="card-body login-card-body">
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="input-container  ">
                <input
                  type="text"
                  className="form-control form-input borderinput"
                  name="email"
                  placeholder="Enter Email Address"
                  value={formData.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className="input-container mt-3">
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control form-input borderinput"
                  name="password"
                  placeholder="Enter Your Password"
                  value={formData.password}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />

                <span
                  className="toggle_password_ info_icon"
                  onClick={() => {
                    togglePasswordVisiblity(!passwordShown);
                  }}
                >
                  <span
                    className={
                      passwordShown
                        ? "hide-icon togglePassword"
                        : "show-icon togglePassword"
                    }
                    id=""
                  ></span>
                </span>
              </div>

              <div className="row">
                <div className="col-12 text-end mt-2">
                  <Link
                    to="/forgot-password"
                    className="
                         forgotheading"
                  >
                    Forgot password?
                  </Link>
                </div>

                <div className="col-12 text-center">
                  <button
                    className="loginBtnCommon btnYellow mt-3 mw-100 loginbtn"
                    type="submit"
                    disabled={loading}
                  >
                    Login
                    {loading && (
                      <span className="spinner-border spinner-border-sm ms-1"></span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
