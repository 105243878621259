import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as images from "../../src/utilities/images";
import CustomModal from "../components/Modal/CustomModal";
import LogoutModal from "../components/Modal/logoutModal";

const Sidebar = () => {
  const location = useLocation()
  const pathName = location.pathname;
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  return (
    <div>
      <aside className="sidebarSil main-sidebar sideBar-bg sidebar-dark-primary elevation-4 main">
        <Link to="/home" className="brand-link">
          <img
            src={images.sideLogo}
            className="img-fluid brandSideLogo"
            alt="Brand Logo"
          />
          <img
            src={images.dotsLogo}
            className="img-fluid dotsLogo"
            alt="Brand Logo"
          />
        </Link>
        <div className="sidebar adminSidebar">
          <nav className="mt-2">
            <div className="sidebarSec">
              <h4 className="userDarkPara m-2">ACTIVITY</h4>
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="true"
              >
                <li className="nav-item sideheading">
                  <Link
                    to="/home"
                    className={
                      ["/home"].includes(pathName)
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.dashboardLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Dashboard</p>
                  </Link>
                </li>

                <li className="nav-item sideheading">
                  <Link
                    to="/users"
                    className={
                      ["/users"].includes(pathName) ||
                      pathName.startsWith("/user-detail")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.suscriberLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Subscribers</p>
                  </Link>
                </li>

                <li className="nav-item sideheading">
                  <Link
                    to="/article"
                    className={
                      ["/article","/article-messages"].includes(pathName) ||
                      pathName.startsWith("/article-details")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.articleLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Articles</p>
                  </Link>
                </li>

                <li className="nav-item sideheading">
                  <Link
                    to="/article-categories"
                    className={
                      ["/article-categories"].includes(pathName) || pathName.startsWith("/sub-category/")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.categoryLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Articles Genre & Type</p>
                  </Link>
                </li>
                <li className="nav-item sideheading">
                  <Link
                    to="/tickets"
                    className={
                      ["/tickets"].includes(pathName) ||
                      pathName.startsWith("/ticket-details")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.ticketsLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Tickets </p>
                  </Link>
                </li>
                <li className="nav-item sideheading">
                  <Link
                    to="/publications"
                    className={
                      [
                        "/publications",
                        "/addPublications",
                        "/editPublications",
                        "/publicationsDetails"
                      ].includes(pathName)
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.publicationLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Publications </p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebarSec">
              <h4 className="userDarkPara m-2">BILLING</h4>
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="true"
              >
                <li className="nav-item sideheading">
                  <Link
                    to="/subscription"
                    className={
                      ["/subscription"].includes(pathName)
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.subscriptLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Subscriptions</p>
                  </Link>
                </li>
              </ul>
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="true"
              >
                <li className="nav-item sideheading">
                  <Link
                    to="/transaction"
                    className={
                      ["/transaction"].includes(pathName)
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.transactImg}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Transaction</p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebarSec">
              <h4 className="userDarkPara m-2">GENERAL</h4>
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="true"
              >
                <li className="nav-item sideheading">
                  <Link
                    to="/notification"
                    className={
                      ["/notification"].includes(pathName)
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.notifyLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Notifications</p>
                  </Link>
                </li>
                <li className="nav-item sideheading">
                  <Link
                    to="/content-pages"
                    className={
                      ["/content-pages"].includes(pathName) ||
                      pathName.startsWith("/content-view")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.contentLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Content Pages</p>
                  </Link>
                </li>
                <li className="nav-item sideheading">
                  <Link
                    to="/Social-links"
                    className={
                      ["/Social-links"].includes(pathName)
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.socialLinksLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Social Media Links</p>
                  </Link>
                </li>
                <li className="nav-item sideheading">
                  <Link
                    to="/client-logos"
                    className={
                      ["/client-logos"].includes(pathName)
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <img
                      src={images.clientLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Client Logos</p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebarSec">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="true"
              >
                <li className="nav-item sideheading">
                  <Link
                    to="#"
                    className={
                      [""].includes(pathName) ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      handleOpenModal("logoutModal");
                    }}
                  >
                    <img
                      src={images.logoutLogo}
                      alt="side-icons"
                      className="logoImg img-fluid"
                    />
                    <p className="ms-2 userDarkPara">Logout</p>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </aside>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "logoutModal" ? "deletCategoryModal" : ""}
        child={
          modalDetail.flag === "logoutModal" ? (
            <LogoutModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "logoutModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to logout?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default Sidebar;
