import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteArticle, onErrorStopLoad } from "../../redux/slices/dashboard";

const DeleteCategoryModal = (props) => {
  const { selectedParentCategory, close, getAllCategories } = props;
  const dispatch = useDispatch();

  // delete helper page
  const handleDeActivateUser = () => {
    let params = {
      id: selectedParentCategory?._id,
    };
    dispatch(
      deleteArticle({
        ...params,
        cb(res) {
          if (res?.data) {
            close();
            getAllCategories();
          }
        },
      })
    );
  };

  // stop loading
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="deleteModalsec text-center my-3">
        <button
          className="blockbtn btnYellow w-100"
          onClick={handleDeActivateUser}
        >
          Yes, Delete
        </button>
      </div>
    </>
  );
};

export default DeleteCategoryModal;
