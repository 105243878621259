import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthSelector } from "../redux/selector/auth";

const PublicRoute = ({ children }) => {
  const authSelector = useAuthSelector();
  const { loginData, isLoggedIn } = authSelector;

  if (!isLoggedIn && loginData && !Object.keys(loginData)?.length > 0) {
    return children;
  } else if (isLoggedIn && loginData && Object.keys(loginData)?.length > 0) {
    return <Navigate to="/home" />;
  }
};

export default PublicRoute;
