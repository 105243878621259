import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSocialLinksSelector } from "../../redux/selector/social-links";
import { clientLogos } from "../../redux/slices/socialLinks";
import * as images from "../../utilities/images";
import { DeleteIcon, editPen } from "../../utilities/images";
import AddClientLogos from "../Modal/AddClientLogos";
import CustomModal from "../Modal/CustomModal";
import DeleteLogoModal from "../Modal/DeleteLogoModal";

const ClientLogos = () => {
  document.title = "Client Logos";
  const dispatch = useDispatch();
  const socialLinkSeletor = useSocialLinksSelector();
  const { loading } = socialLinkSeletor;
  const [logoList, setLogoList] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [logoData, setLogoData] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
    setLogoData(data || "");
  };

  // get client information
  useEffect(() => {
    getClientList();
  }, []);

  // get client information
  const getClientList = () => {
    dispatch(
      clientLogos({
        cb(res) {
          if (res?.status === 200) {
            setLogoList(res?.data?.data);
          }
        },
      })
    );
  };

  return (
    <>
      <div className="content-wrap categories publicationsec">
        <div className="innerHeadFlex">
          <h1 className="mainHead32">Client Logos</h1>
          <button
            className="loginBtnCommon btnYellow add_btn m-0"
            onClick={() => {
              handleOpenModal("addClientLogo");
            }}
          >
            Create New
          </button>
        </div>

        <div className="table-responsive recentTable publishTable">
          <table className="table table-dark m-0">
            <thead>
              <tr className="recentRow">
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  SR. NO.
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Create At
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Link
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  Logo
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Name
                </th>
                <th scope="col" className="recentHead text-start ">
                  ACTION
                </th>
              </tr>
            </thead>

            {loading && !modalDetail?.show ? (
              <>
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <tbody>
                {logoList?.length > 0 ? (
                  logoList?.map((data, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">{idx + 1}</h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">
                            {moment(data?.createdAt).format("MMM-DD-YYYY")}
                          </h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <a
                            title={data?.link}
                            target="_black"
                            href={data?.link}
                            className="tableSubText text-lowercase"
                          >
                            {data?.link?.slice(0, 16)}
                            {data?.link?.length > 17 && "..."}
                          </a>
                        </td>
                        <td className="recentSubHead align-middle">
                          <div className="profile-outer">
                            <img
                              src={data?.logo}
                              className="img-fluid profileImg_"
                              alt="profileImg"
                            />
                          </div>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 title={data?.name} className="tableSubText">
                            {data?.name?.slice(0, 16)}
                            {data?.name?.length > 17 && "..."}
                          </h4>
                        </td>
                        <td className="recentSubHead text-center">
                          <div className="categoryOuterBtn">
                            <button
                              className="editBtn"
                              type="button"
                              onClick={() => {
                                handleOpenModal("addClientLogo", data);
                              }}
                            >
                              <img
                                src={editPen}
                                className="img-fluid "
                                alt="editPen image"
                              />
                              Edit
                            </button>
                            <button
                              className="delete_btn"
                              type="button"
                              onClick={() =>
                                handleOpenModal("deleteClientLogo", data)
                              }
                            >
                              <img
                                src={DeleteIcon}
                                className="img-fluid"
                                alt="eyeImg"
                              />
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} className="noFoundHead">
                      <h3 className="text-center subhead24 m-0">
                        No Client Logo Found!
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "addClientLogo"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "addClientLogo" ||
          modalDetail.flag === "deleteClientLogo"
            ? "createCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "addClientLogo" ? (
            <AddClientLogos
              logoData={logoData}
              getClientList={getClientList}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "deleteClientLogo" ? (
            <DeleteLogoModal
              logoData={logoData}
              getClientList={getClientList}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "addClientLogo" ? (
            <>
              <h2 className="modal_Heading">{`${
                logoData ? "Edit Client Logo" : "Add New Client Logo"
              }`}</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "deleteClientLogo" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to Delete?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ClientLogos;
