import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import { userDetail } from "../../redux/slices/dashboard";
import * as Images from "../../utilities/images";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const [adminData, setAdminData] = useState("");

  const getOneUserDetail = () => {
    let params = {
      id: authSelector?.loginData?.userInfo?.id,
    };
    dispatch(
      userDetail({
        ...params,
        cb(res) {
          if (res?.data) {
            setAdminData(res?.data?.data?.userInfo);
          }
        },
      })
    );
  };

  useEffect(() => {
    getOneUserDetail();
  }, []);

  return (
    <>
      <div className="content-wrapper userProfileSec">
        <h1 className="mainHead32 my-4">Profile</h1>
        <div className="profileouterSec">
          <div className="profileInnerSec">
            <img
              src={adminData?.profilePhoto || Images.dummyProfile}
              alt="userImage"
              className="userProfileImg"
            />
            <div className="userDataBx">
              <h4 className="userHeading mb-2">{adminData?.firstName}</h4>
              <h6 className="userLightPara">
                Email: <span className="userDarkPara"> {adminData?.email}</span>
              </h6>
            </div>
          </div>
          <button
            type="button"
            className="mainBtn edit_Profile_Btn"
            onClick={() => {
              navigate("/editProfile", { state: adminData });
            }}
          >
            Edit Profile
          </button>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
