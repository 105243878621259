import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../layout/DashboardLayout";
import AuthLayout from "../layout/AuthLayout";
import * as Containers from "../containers";
import PublicRoute from "../layout/PublicRoute";
import ProtectRoute from "../layout/ProtectRoute";

const Router = () => {
  return (
    <>
      <Routes>
        {/* DASHBOARD_ROUTES */}
        <Route element={<DashboardLayout />}>
          <Route
            path="/home"
            element={
              <ProtectRoute>
                <Containers.Dashboard />
              </ProtectRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectRoute>
                <Containers.Users />
              </ProtectRoute>
            }
          />
          <Route
            path="/article-categories"
            element={
              <ProtectRoute>
                <Containers.ArticleCategories />
              </ProtectRoute>
            }
          />
          <Route
            path="/tickets"
            element={
              <ProtectRoute>
                <Containers.Tickets />
              </ProtectRoute>
            }
          />
          <Route
            path="/ticket-details/:id"
            element={
              <ProtectRoute>
                <Containers.TicketDetails />
              </ProtectRoute>
            }
          />
          <Route
            path="/content-pages"
            element={
              <ProtectRoute>
                <Containers.ContentPages />
              </ProtectRoute>
            }
          />
          <Route
            path="/content-view/:id"
            element={
              <ProtectRoute>
                <Containers.ContentView />
              </ProtectRoute>
            }
          />
          <Route
            path="/article"
            element={
              <ProtectRoute>
                <Containers.Article />
              </ProtectRoute>
            }
          />
          
          <Route
            path="/article/:id"
            element={
              <ProtectRoute>
                <Containers.Article />
              </ProtectRoute>
            }
          />
          <Route
            path="/article-details/:id"
            element={
              <ProtectRoute>
                <Containers.ArticleDetails />
              </ProtectRoute>
            }
          />
          <Route
            path="/article-messages"
            element={
              <ProtectRoute>
                <Containers.ArticleMessage />
              </ProtectRoute>
            }
          />
          <Route
            path="/publications"
            element={
              <ProtectRoute>
                <Containers.Publications />
              </ProtectRoute>
            }
          />
          <Route
            path="/addPublications"
            element={
              <ProtectRoute>
                <Containers.AddPublications />
              </ProtectRoute>
            }
          />
          <Route
            path="/publicationsDetails"
            element={
              <ProtectRoute>
                <Containers.PublicationsDetails />
              </ProtectRoute>
            }
          />
          <Route
            path="/editPublications"
            element={
              <ProtectRoute>
                <Containers.AddPublications />
              </ProtectRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectRoute>
                <Containers.UserProfile />
              </ProtectRoute>
            }
          />
          <Route
            path="/editProfile"
            element={
              <ProtectRoute>
                <Containers.EditProfile />
              </ProtectRoute>
            }
          />
          <Route
            path="/ChangePassword"
            element={
              <ProtectRoute>
                <Containers.ChangePassword />
              </ProtectRoute>
            }
          />
          <Route
            path="/sub-category/:id"
            element={
              <ProtectRoute>
                <Containers.CategorySub />
              </ProtectRoute>
            }
          />
          <Route
            path="/user-detail/:id"
            element={
              <ProtectRoute>
                <Containers.UserDetail />
              </ProtectRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <ProtectRoute>
                <Containers.Subscription />
              </ProtectRoute>
            }
          />
          <Route
            path="/transaction"
            element={
              <ProtectRoute>
                <Containers.Transaction />
              </ProtectRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <ProtectRoute>
                <Containers.Notification />
              </ProtectRoute>
            }
          />
          <Route
            path="/Social-links"
            element={
              <ProtectRoute>
                <Containers.SocialLinks />
              </ProtectRoute>
            }
          />
          <Route
            path="/client-logos"
            element={
              <ProtectRoute>
                <Containers.ClientLogos />
              </ProtectRoute>
            }
          />
          <Route
            path="/user-delete"
            element={
              <ProtectRoute>
                <Containers.DeleteUsers />
              </ProtectRoute>
            }
          />
        </Route>


        {/* AUTH_ROUTES */}
        <Route element={<AuthLayout />}>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Containers.Login />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <Containers.ForgotPassword />{" "}
              </PublicRoute>
            }
          />
          <Route
            path="/enter-otp"
            element={
              <PublicRoute>
                <Containers.EnterOtp />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <Containers.ResetPassword />{" "}
              </PublicRoute>
            }
          />
          <Route path="*" element={<Containers.PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
