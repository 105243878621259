import React, { useEffect, useState } from "react";

const DeleteUsers = () => {
  document.title = "Subscribers";
  
  return (
    <>
      <div className="content-wrap pb-5">
        <h1 className="mainHead32 deleted_User">This user has been deleted</h1>
      </div>
    </>
  );
};

export default DeleteUsers;
