import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

const RejectionReasonModal = (props) => {
  const [rejectReason, setRejectReason] = useState("");
  const toastId = useRef(null);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  /**
   * to send reject reason
   * @returns rejected reason
   */
  const handleSend = () => {
    if (!rejectReason) {
      showToast("Please enter your reason");
      return false;
    }
    props?.handleStatusChange("rejected", rejectReason);
    props?.close();
  };

  return (
    <>
      <div className="categoryModalSection">
        <div className="categoryForm">
          <textarea
            className="form-control categoryInput mt-2"
            name="w3review"
            rows="8"
            cols="50"
            placeholder="Enter reason here ..."
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          ></textarea>
        </div>
        <button
          className="blockbtn btnYellow w-100"
          type="button"
          disabled={props?.loading}
          onClick={() => handleSend()}
        >
          Send
          {props?.loading && (
            <span className="spinner-border spinner-border-sm ms-1"></span>
          )}
        </button>
      </div>
    </>
  );
};

export default RejectionReasonModal;
