import React, { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OtpTimer from "otp-timer";
import { useAuthSelector } from "../../redux/selector/auth";
import {
  enterOtp,
  onErrorStopLoad,
  resendEnterOtp,
} from "../../redux/slices/auth";
import logo from "../Images/logo.png";

const EnterOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state: email } = location;
  const authData = useAuthSelector();
  const { loading } = authData;
  const toastId = useRef(null);
  const [otp, setOtp] = useState("");
  const [key] = useState(Math.random());
  const [isLoading, setIsLoading] = useState("");

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Form submit handler
  const handleSubmit = (e, type) => {
    e.preventDefault();
    setIsLoading(type);
    if (!otp) {
      showToast("Please enter your OTP");
      return;
    }
    let params = {
      otp: otp,
    };
    dispatch(
      enterOtp({
        ...params,
        cb(res) {
          if (res.status === 200) {
            navigate("/reset-password", {
              state: res?.data?.data,
            });
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // resend OTP
  const handleResendOtp = (e, type) => {
    // e.preventDefault();
    // setIsLoading(type);
    let params = {
      type: "forgot",
      email: email,
    };
    dispatch(
      resendEnterOtp({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setOtp("");
          }
        },
      })
    );
  };

  // navigate to login page if email is not provided
  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="login-page authBg adminotp">
        <div className="brandlogo">
          <img src={logo} alt="brandlogo" onClick={() => navigate("/")} />
        </div>
        <div className="card card-outline card-primary cardTopOutline cardBg p-0">
          <div className="loginPage">
            <div className="card-header text-center border-0">
              <h3 className="mb-0 logintext">Enter OTP</h3>
              <p className="form_subheading mt-3">
                Enter the OTP that we just sent you on your email <br />
                address to reset your password.
              </p>
            </div>
            <div className="card-body login-card-body">
              <form
                onSubmit={(e) => handleSubmit(e, "submit")}
                className="otpform"
              >
                <div className="otpForm">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderInput={(props) => (
                      <input {...props} className="enterOtp" />
                    )}
                  />
                </div>

                <div className="buttonBox mt-5">
                  <button
                    type="submit"
                    disabled={
                      loading &&
                      (isLoading === "resend" || isLoading === "resend")
                    }
                    className="loginBtnCommon btnYellow mw-100 mb-0  loginbtn"
                  >
                    Submit
                    {loading && isLoading === "submit" && (
                      <span className="spinner-border spinner-border-sm ms-1"></span>
                    )}
                  </button>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3">
                <p className="resend-top">
                  
                  Don’t Received?{" "}</p>
                  <div className="linkSub ms-1 timer_Btn ms-1">
                        <OtpTimer
                          //minutes={1}
                          seconds={60}
                          key={key}
                          //seconds={otpTimerPeriod}
                          //text="Resend OTP in:"
                          ButtonText="Resend OTP"
                          resend={ handleResendOtp}
                        />
                      </div>
                      </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterOtp;
