import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSubscriptionsSelector } from "../../redux/selector/subscriptions";
import { getSubscription } from "../../redux/slices/subscriptions";
import * as images from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import CreatePlanModal from "../Modal/createPlanModal";
import DeletePlan from "../Modal/deletePlan";

const Subscription = () => {
  document.title = "Subscriptions";
  const dispatch = useDispatch();
  const subscriptionsSelector = useSubscriptionsSelector();
  const [subcriptList, setSubscriptList] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [editValue, setEditValue] = useState("");
  console.log(editValue, "editValue");

  const [selectedPlanType, setSelectedPlanType] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  //get all Subscriptions

  const getAllSubcription = () => {
    dispatch(
      getSubscription({
        cb(res) {
          if (res?.status === 200) {
            setSubscriptList(res?.data?.data);
            let planType = [];
            res?.data?.data?.map((i) => {
              return i?.plans?.forEach((j) => {
                if (j.planType == "month") {
                  planType.push(j);
                } else if (j.planType == "Free") {
                  planType.unshift(j);
                }
              });
            });
            setSelectedPlanType(planType);
          }
        },
      })
    );
  };

  useEffect(() => {
    getAllSubcription();
  }, []);

  return (
    <>
      <div className="content-wrap subscriptionSec pb-4">
        {subscriptionsSelector?.loading ? (
          <>
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          </>
        ) : (
          <>
            <div className="innerHeadFlex mb-5">
              <h1 className="mainHead32">Subscriptions</h1>
            </div>
            {subcriptList?.map((val, idx) => {
              console.log(val, "valsansy");

              const PlanArticleInfo = selectedPlanType?.at(idx);
              const featuresToShow = selectedPlanType?.at(idx)?.features;
              const planPriceToShow = selectedPlanType?.at(idx)?.price;
              const planNameToShow = selectedPlanType?.at(idx)?.planName;
              return (
                <div className="planOuter" key={idx}>
                  <div className="planName">
                    <h4 className="planText">{planNameToShow}</h4>
                    <h4 className="priceText ">
                      ${planPriceToShow}
                      <span className="monthText">
                        /{selectedPlanType?.at(idx)?.planType}{" "}
                        {selectedPlanType?.at(idx)?.planType === "year" &&
                          `- ${
                            selectedPlanType?.at(idx)?.yearlyDiscount
                          }% discount`}
                      </span>
                    </h4>
                    <h6 className="userLightPara">
                      Last Updated:{" "}
                      <span className="form_subheading">
                        {moment(val?.updatedAt).format("MMM-DD-YYYY")}
                      </span>
                    </h6>
                  </div>
                  <div className="includePlan">
                    {selectedPlanType?.at(idx)?.planType !== "Free" && (
                      <ul className="planTabs_">
                        <li
                          className={
                            "planttabslist " +
                            (selectedPlanType?.at(idx)?.planType === "month" &&
                              "active")
                          }
                          onClick={() => {
                            let data = [...selectedPlanType];
                            data?.splice(
                              idx,
                              1,
                              val?.plans?.find((i) => i?.planType == "month")
                            );
                            setSelectedPlanType(data);
                          }}
                        >
                          Monthly
                        </li>
                        <li
                          className={
                            "planttabslist " +
                            (selectedPlanType?.at(idx)?.planType === "year" &&
                              "active")
                          }
                          onClick={() => {
                            let data = [...selectedPlanType];
                            data?.splice(
                              idx,
                              1,
                              val?.plans?.find((i) => i?.planType == "year")
                            );
                            setSelectedPlanType(data);
                          }}
                        >
                          Yearly
                        </li>
                      </ul>
                    )}
                    <h5 className="subgreyText_">INCLUDES:</h5>

                    <div className="flexPlan mt-2">
                      <img
                        src={images.checkMark}
                        alt="checkMark image"
                        className="img-fluid"
                      />
                      <h6 className="planDesc">
                        Maximum Articles{" "}
                        {PlanArticleInfo?.planType !== "Free"
                          ? `per ${PlanArticleInfo?.planType}`
                          : ""}{" "}
                        : {PlanArticleInfo?.maxArticlesPerMonth || "----"}
                      </h6>
                    </div>
                    {PlanArticleInfo?.planType !== "Free" && (
                      <div className="flexPlan mt-2">
                        <img
                          src={images.checkMark}
                          alt="checkMark image"
                          className="img-fluid"
                        />
                        <h6 className="planDesc">
                          Markup :{" "}{PlanArticleInfo?.markUp}%
                        </h6>
                      </div>
                    )}

                    {featuresToShow?.map((feature, index) => (
                      <div className="flexPlan mt-2" key={index}>
                        <img
                          src={images.checkMark}
                          alt="checkMark image"
                          className="img-fluid"
                        />
                        <h6 className="planDesc">{feature || ""}</h6>
                      </div>
                    ))}
                  </div>
                  <div className="planBtn">
                    <button
                      className="mainBtn"
                      type="button"
                      onClick={() => {
                        console.log("grouperData", subcriptList?.at(idx));
                        handleOpenModal(
                          "editPlan",
                          setEditValue(subcriptList?.at(idx))
                        );
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "createPlan"
            ? "createPlanModal"
            : modalDetail.flag === "editPlan"
            ? "createPlanModal"
            : modalDetail.flag === "deletePlan"
            ? "deletCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "createPlan" ? (
            <CreatePlanModal
              close={() => handleOnCloseModal()}
              getSubscription={() => getAllSubcription()}
            />
          ) : modalDetail.flag === "editPlan" ? (
            <CreatePlanModal
              close={() => handleOnCloseModal()}
              getSubscription={() => getAllSubcription()}
              editValues={editValue}
              flag="editPlan"
            />
          ) : modalDetail.flag === "deletePlan" ? (
            <DeletePlan
              close={() => handleOnCloseModal()}
              DeletId={deleteId}
              getSubscription={() => getAllSubcription()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "createPlan" ? (
            <>
              <h2 className="modal_Heading">Add Plan</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "editPlan" ? (
            <>
              <h2 className="modal_Heading updateplan">
                Edit {editValue?.plans[0]?.planName} Plan
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "deletePlan" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to Delete Plan?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Subscription;
