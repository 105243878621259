import { CKEditor } from "ckeditor4-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateOneHelper } from "../../redux/slices/helper";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

const EditHelper = (props) => {
  const [title, setTitle] = useState(
    props?.helperData?.title ? props?.helperData?.title : ""
  );

  const [ckKey, setCkKey] = useState(Math.random());
  const [helperData, setHelperData] = useState(
    props?.helperData?.content ? props?.helperData?.content : ""
  );
  const dispatch = useDispatch();

  const handleEdit = () => {
    if (!title) {
      toast.error("Please enter title");
      return false;
    }
    if (!helperData) {
      toast.error("Please enter content");
      return false;
    }
    let params = {
      id: props?.id,
      title: title,
      content: helperData,
    };
    dispatch(
      updateOneHelper({
        ...params,
        cb(res) {
          props?.getOneHelperDetails();
          props?.close();
        },
      })
    );
  };

  return (
    <>
      <div className="mb-4">
        <label className="formlable">Title</label>
        <input
          type="text"
          className="form-control nameControl"
          name="name"
          placeholder="please enter title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="articleeditor_">
        {(ckKey || helperData) && (
          // <CKEditor
          //   initData={helperData}
          //   config={{
          //     removePlugins: [
          //       "EasyImage",
          //       "ImageUpload",
          //       "MediaEmbed",
          //       "Table",
          //       "table",
          //       "TableToolbar",
          //       "image",
          //     ],
          //     versionCheck: false,
          //     removeButtons:
          //       "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
          //     contentsCss: [
          //       ".ckeditor-content-colour { color: #ffffff !important; }",
          //     ],
          //     bodyClass: "ckeditor-content-colour",
          //   }}
          //   name="editor"
          //   readOnly={false}
          //   onChange={(event) => {
          //     const editor = event.editor;
          //     const data = editor.getData(); // Corrected way to get data
          //     setHelperData(data ? data : "");
          //   }}
          //   type="classic"
          // />
          <CKEditor
            initData={
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {helperData}
              </ReactMarkdown>
            }
            config={{
              extraPlugins: ["easyimage"],
              versionCheck: false,
              removePlugins: [
                "ImageUpload",
                "MediaEmbed",
                "Table",
                "TableToolbar",
              ],
              versionCheck: false,
              toolbarLocation: "top",
              removeButtons:
                "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
              toolbar: [
                { name: "document", items: ["Undo", "Redo"] },
                { name: "styles", items: ["Format"] },
                {
                  name: "basicstyles",
                  items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                },
                {
                  name: "paragraph",
                  items: ["NumberedList", "BulletedList"],
                },
                { name: "links", items: ["Link", "Unlink"] },
                { name: "insert", items: ["EasyImageUpload"] },
              ],
              cloudServices_uploadUrl:
                "https://33333.cke-cs.com/easyimage/upload/",
              cloudServices_tokenUrl:
                "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
              easyimage_styles: {
                gradient1: {
                  group: "easyimage-gradients",
                  attributes: { class: "easyimage-gradient-1" },
                  label: "Blue Gradient",
                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/gradient1.png",
                  iconHiDpi:
                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/gradient1.png",
                },
                gradient2: {
                  group: "easyimage-gradients",
                  attributes: { class: "easyimage-gradient-2" },
                  label: "Pink Gradient",
                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/gradient2.png",
                  iconHiDpi:
                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/gradient2.png",
                },
                noGradient: {
                  group: "easyimage-gradients",
                  attributes: { class: "easyimage-no-gradient" },
                  label: "No Gradient",
                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/nogradient.png",
                  iconHiDpi:
                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/nogradient.png",
                },
              },
              easyimage_toolbar: [
                "EasyImageFull",
                "EasyImageSide",
                "EasyImageGradient1",
                "EasyImageGradient2",
                "EasyImageNoGradient",
                "EasyImageAlt",
              ],
              height: 630,
              removeButtons: "PasteFromWord",
              contentsCss: [
                "ckEditor.css",
                ".ckeditor-content-colour { color: #ffffff !important; }",
              ],
              bodyClass: "ckeditor-content-colour",
            }}
            name="editor"
            readOnly={false}
            onChange={(event) => {
              const editor = event.editor;
              const data = editor.getData();
              setHelperData(data ? data : "");
            }}
            type="classic"
          />
        )}
      </div>

      <button
        className={`blockbtn`}
        type="button"
        onClick={() => {
          props?.close();
        }}
        disabled={props?.loading}
      >
        Cancel
      </button>
      <button
        className={`blockbtn completeTicket`}
        type="button"
        onClick={() => {
          handleEdit();
        }}
        disabled={props?.loading}
      >
        Update
        {props?.loading && (
          <span className="spinner-border spinner-border-sm ms-1"></span>
        )}
      </button>
    </>
  );
};

export default EditHelper;
