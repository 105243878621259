import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../components/Modal/CustomModal";
import LogoutModal from "../components/Modal/logoutModal";
import { useAuthSelector } from "../redux/selector/auth";
import { useNotificateSelector } from "../redux/selector/notification";
import { onErrorStopLoad } from "../redux/slices/auth";
import { adminDetail, userDetail } from "../redux/slices/dashboard";
import { getNotification } from "../redux/slices/notification";
import * as images from "../utilities/images";
import NotificationModal from "../components/Modal/NotificationModal";

const MainNav = () => {
  const authSelector = useAuthSelector();
  const notification = useNotificateSelector();
  const loginData = authSelector;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState(Math.random());
  const [notificationRead, setNotificationRead] = useState(false);
  const [adminData, setAdminData] = useState("");

  const [notifyList, setNotifyList] = useState([]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

console.log(modalDetail,"modalDetail");
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const fetchNotification = () => {
    dispatch(
      getNotification({
        cb(res) {
          if (res?.status === 200) {
            setNotifyList(res?.data?.data);
          }
        },
      })
    );
  };

  const getOneUserDetail = () => {
    let params = {
      id: authSelector?.loginData?.userInfo?.id,
    };
    dispatch(
      adminDetail({
        ...params,
        cb(res) {
          if (res?.data) {
            setAdminData(res?.data?.data?.userInfo);
          }
        },
      })
    );
  };

  useEffect(() => {
    getOneUserDetail();
  }, [loginData?.success]);

  useEffect(() => {
    fetchNotification();

    const interval = setInterval(() => {
      fetchNotification();
    }, 10000); // 20 second interval

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [notification?.success]);

  useEffect(() => {
    const findRead = notifyList?.some((item) => item?.is_read == false);
    setNotificationRead(findRead ? true : false);
  }, [notifyList]);

  // Only re-run the effect if 'notification' changes
  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <nav className="main-header navbar navbar-inner navbar-expand navbar-white navbar-light adminav dashboardNav ">
        <ul className="navbar-nav">
          <li className="nav-item ">
            <Link
              className="nav-link dashBar_"
              data-widget="pushmenu"
              to="#"
              role="button"
            >
              <i className="fas text-white fa-bars"></i>
            </Link>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown toggledash_">
            <button
              className="notifyBell position-relative"
              onClick={()=> handleOpenModal("notificationModal")}
            >
              <img className="img-fluid" src={images.bellImg} alt="bellImg" />
              {notificationRead ? <span className="countValue"></span> : ""}
            </button>
          </li>
          <li className="nav-item dropdown toggledash_">
            <Link className="nav-link h-auto" data-toggle="dropdown" to="#">
              <img
                className="userImages_"
                src={
                  adminData?.profilePhoto
                    ? adminData?.profilePhoto
                    : images.dummyProfile
                }
                alt=""
              />
            </Link>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right headerDrop">
              <Link className="dropdown-item dropItems" to="/profile">
                Profile
              </Link>
              <Link className="dropdown-item dropItems" to="/ChangePassword">
                Change Password
              </Link>
              <Link
                onClick={() => {
                  handleOpenModal("logoutModal");
                }}
                className="dropdown-item dropItems"
                to="#"
              >
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"

            : ""
        }
        ids={modalDetail.flag === "logoutModal" ? "deletCategoryModal" : "notificationModal"}
        child={
          modalDetail.flag === "logoutModal" ? (
            <LogoutModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "notificationModal" ? (
            <NotificationModal close={() => handleOnCloseModal()}/>
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "logoutModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to logout?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) :modalDetail.flag === "notificationModal" ? (
            <>
              <h2 className="modal_Heading">
                Notifications
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) :(
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default MainNav;
