import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTicketSelector } from "../../redux/selector/ticket";
import { getAllTickets } from "../../redux/slices/ticket";
import * as images from "../../utilities/images";

const Tickets = () => {
  document.title = "Tickets";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ticketSelector = useTicketSelector();
  const { loading } = ticketSelector;
  const [ticketsList, setTicketList] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleSuggestionClick = (suggestion) => {
  
    // Check if the search term matches the full name or email
    const fullName = `${suggestion?.Users?.firstName} ${suggestion?.Users?.lastName}`;
    const email = suggestion?.Users?.email;
    
    // Set the search value based on what the user clicked
    if (fullName.toLowerCase().includes(search.toLowerCase())) {
      setSearch(fullName);
    } else if (email?.toLowerCase().includes(search.toLowerCase())) {
      setSearch(email);
    } else {
      // Fallback to the name if no match was found
      setSearch(fullName);
    }
  
    // Clear the suggestions after selection
    setFilteredSuggestions([]);
  };

  // List of suggestions (replace with actual data)
  const suggestions = ticketsList || [];

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setCurrentPage(1);

    // Filter suggestions based on input
    const filtered = suggestions.filter(
      (item) =>
        `${item?.Users?.firstName?.toLowerCase()} ${item?.Users?.lastName?.toLowerCase()}`.includes(
          value.toLowerCase()
        ) || item?.Users?.email?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filtered);
  };

  // get all users
  useEffect(() => {
    let params = {
      page: currentPage,
      limit: 10,
      search: search || undefined,
      status: status || undefined,
    };
    if (Boolean(search)) {
      params.search = search;
    }
    dispatch(
      getAllTickets({
        ...params,
        cb(res) {
          if (res?.data) {
            setTicketList(res?.data?.data?.data);
            setPageCount(res.data.data.total_pages);
          }
        },
      })
    );
  }, [status, search, currentPage]);

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleToggleExpand = (e, data) => {
    navigate(`/ticket-details/${data?._id}`, {
      state: {
        ticketInfo: data,
      },
    });
  };

  return (
    <>
      <div className="content-wrap pb-5 ">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="mainHead32">Tickets</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="searchSection">
          <input
            class="form-control searchInput"
            type="text"
            placeholder="Search by name & email"
            value={search}
            // onChange={(e) => {
            //   setSearch(e.target.value);
            //   setCurrentPage(1);
            // }}
            onChange={handleSearchChange}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIcon"
          />
          {search && filteredSuggestions.length > 0 && (
            <ul className="autocomplete-list pointCursor">
              {filteredSuggestions.map((suggestion) => {
                const fullName = `${suggestion?.Users?.firstName} ${suggestion?.Users?.lastName}`;
                const email = suggestion?.Users?.email;

                // Determine whether to show the name or email based on the search input
                const searchValue = search.toLowerCase();
                const displayValue = fullName
                  .toLowerCase()
                  .includes(searchValue)
                  ? fullName
                  : email && email.toLowerCase().includes(searchValue)
                  ? email
                  : fullName; // Default to full name if neither matches
                return (
                  <li
                    key={suggestion._id}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="autocomplete-item"
                  >
                    {displayValue}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="recentUserSec">
          <div className="userListStatus">
            <h4
              className={
                status === "" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("")}
            >
              All
            </h4>
            <h4
              className={
                status === "pending" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("pending")}
            >
              Open
            </h4>
            <h4
              className={
                status === "completed"
                  ? "statusHeading active"
                  : "statusHeading"
              }
              onClick={() => setStatus("completed")}
            >
              Closed
            </h4>
          </div>
          <div className="table-responsive recentTable ticketTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead createdAtHead">
                  Ticket NO.
                  </th>
                  <th scope="col" className="recentHead createdAtHead">
                    User Name
                  </th>
                  <th scope="col" className="recentHead createdAtHead">
                    Email
                  </th>
                  <th scope="col" className="recentHead createdAtHead">
                    Article Name
                  </th>
                  <th scope="col" className="recentHead createdAtHead">
                    Title
                  </th>
                  <th scope="col" className="recentHead createdAtHead">
                    Created On
                  </th>
                  {/* <th scope="col" className="recentHead createdAtHead">
                    Description
                  </th> */}
                  <th scope="col" className="recentHead createdAtHead">
                    Status
                  </th>
                  <th scope="col" className="recentHead createdAtHead text-left">
                    ACTION
                  </th>
                </tr>
              </thead>

              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <>
                  <tbody>
                    {ticketsList?.length > 0 ? (
                      ticketsList?.map((data, idx) => {
                        return (
                          <tr >
                            <td onClick={() =>
                                    navigate(`/ticket-details/${data?._id}`, {
                                      state: {
                                        flag: "subscribers",
                                      },
                                    })
                                  }>
                              <h4 className="tableSubText table_UserName">#{data?.ticketNo}</h4>
                            </td>
                            <td
                              className="recentSubHead table_UserName"
                              onClick={() =>
                                navigate(`/user-detail/${data?.userId}`, {
                                  state: {
                                    flag: "ticket",
                                  },
                                })
                              }
                            >
                              <div className="nameProfile">
                                <div className="profile-outer">
                                  <img
                                    src={
                                      data?.Users?.profilePhoto
                                        ? data?.Users?.profilePhoto
                                        : images.dummyProfile
                                    }
                                    className="img-fluid profileImg_"
                                    alt="profileImg"
                                  />
                                </div>
                                <h4 className="tableSubText">
                                  {`${data?.Users?.firstName} ${data?.Users?.lastName}`}
                                </h4>
                              </div>
                            </td>
                            <td >
                              <h4 className="tableSubText table_UserName">{data?.Users?.email}</h4>
                            </td>

                            <td onClick={() =>
                                    navigate(`/article-details/${data?.articleId}`, {
                                      state: {
                                        flag: "article",
                                      },
                                    })
                                  }>
                              <h4 className="tableSubText table_UserName">{data?.articleTitle}</h4>
                            </td>

                            <td className="recentSubHead table_UserName" onClick={() =>
                                    navigate(`/ticket-details/${data?._id}`, {
                                      state: {
                                        flag: "subscribers",
                                      },
                                    })
                                  }>
                              <h4 className="tableSubText">
                                {data?.title?.length <= 15
                                  ? data?.title
                                  : `${data?.title?.slice(0, 15)}...`}
                              </h4>
                            </td>
                            <td className="recentSubHead table_UserName">
                              <h4 className="tableSubText">
                                {moment(data?.createdAt).format("MMM-DD-YYYY")}
                              </h4>
                            </td>

                            {/* <td className="recentSubHead">
                              <h4 className="tableSubText DescHead">
                                <span className="DescTicket">
                                  {isExpanded || data?.description?.length <= 60
                                    ? data?.description
                                    : `${data?.description?.slice(0, 60)}...`}
                                </span>
                              
                              </h4>
                            </td> */}
                            <td className="recentSubHead">
                              <h4 className="tableSubText">
                                <button
                                  type="button"
                                  className={
                                    data?.status == "pending"
                                      ? "pendingTkt"
                                      : "completeTkt"
                                  }
                                >
                                  {data?.status}
                                </button>
                              </h4>
                            </td>
                            <td className="recentSubHead text-center" onClick={() =>
                                    navigate(`/ticket-details/${data?._id}`, {
                                      state: {
                                        flag: "subscribers",
                                      },
                                    })
                                  }>
                              <button className="editBtn" type="button">
                                <img
                                  src={images.eyeImg}
                                  className="img-fluid"
                                  alt="editPen image"
                                  
                                />
                                View
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8} className="noFoundHead">
                          <h3 className="text-center subhead24 m-0">
                            No Tickets Found!
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
        {ticketsList && ticketsList?.length > 0 && (
          <div className=" paginateSec">
            <ReactPaginate
              className="paginationBox mb-0"
              nextLabel=" >"
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Tickets;
