import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import { onErrorStopLoad, setGetAllHelper, setGetOneHelper, setUpdateOneHelper } from "../../slices/helper";
const queryString = require('query-string');

function* getAllHelper(action) {
  let data = {...action.payload}
  delete data.cb
  let query = queryString.stringify(data);
  let url = `${ApiPath.helperApiPath.HELPER_LIST}?${query}`;
  try {
    const resp = yield call(ApiClient.get, (action.url = url), action.payload = action.payload);
    if (resp.status) {
      yield put(setGetAllHelper(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getOneHelper(action) {
  let url = `${ApiPath.helperApiPath.HELPER_LIST}/${action.payload.id}`;
  try {
    const resp = yield call(ApiClient.get, (action.url = url), action.payload = action.payload);
    if (resp.status) {
      yield put(setGetOneHelper(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateOneHelper(action) {
  let data = {...action.payload}
  let url = `${ApiPath.helperApiPath.HELPER_LIST}/${data.id}`;
  delete data.id
  try {
    const resp = yield call(ApiClient.put, (action.url = url), action.payload = data);
    if (resp.status) {
      yield put(setUpdateOneHelper(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* helperSaga() {
  yield all([takeLatest("ticket/getAllHelper", getAllHelper)]);
  yield all([takeLatest("ticket/getOneHelper", getOneHelper)]);
  yield all([takeLatest("ticket/updateOneHelper", updateOneHelper)]);
}

export default helperSaga;
