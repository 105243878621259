import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import ApiPath from "../../../constants/apiPath";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setAdminChangePassword,
  setAdminLogin,
  setAdminLogout,
  setAdminUpdateProfile,
  setEnterOtp,
  setForgotPassword,
  setResendEnterOtp,
  setResetPassword,
  setUploadProfilePhoto,
} from "../../slices/auth";

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* resendEnterOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RESEND_ENTER_OTP),
      action.payload
    );
    if (resp.status) {
      yield put(setResendEnterOtp(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* adminLogin(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.ADMIN_LOGIN),
      action.payload
    );
    if (resp.status) {
      yield put(setAdminLogin(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* resetPassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RESET_PASSWORD),
      action.payload
    );
    if (resp.status) {
      yield put(setResetPassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* enterOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.ENTER_OTP),
      action.payload
    );
    if (resp.status) {
      yield put(setEnterOtp(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* forgotPassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.FORGOT_PASSWORD),
      action.payload
    );
    if (resp.status) {
      yield put(setForgotPassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* adminUpdateProfile(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = ApiPath.AuthApiPath.ADMIN_UPDATE_PROFILE),
      action.payload
    );
    if (resp.status) {
      yield put(setAdminUpdateProfile(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* adminChangePassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.ADMIN_CHANGE_PASSWORD),
      action.payload
    );
    if (resp.status) {
      yield put(setAdminChangePassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* uploadProfilePhoto(action) {
  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = ApiPath.AuthApiPath.ADMIN_UPLOAD_PROFILE_PHOTO),
      action.payload
    );
    if (resp.status) {
      yield put(setUploadProfilePhoto(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* adminLogout(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.LOGOUT_ADMIN),
      action.payload
    );
    if (resp.status) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("persist:root");
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
      yield put(setAdminLogout());
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

function* authSaga() {
  yield all([takeLatest("auth/adminLogin", adminLogin)]);
  yield all([takeLatest("auth/adminLogout", adminLogout)]);
  yield all([takeLatest("auth/forgotPassword", forgotPassword)]);
  yield all([takeLatest("auth/enterOtp", enterOtp)]);
  yield all([takeLatest("auth/resetPassword", resetPassword)]);
  yield all([takeLatest("auth/resendEnterOtp", resendEnterOtp)]);
  yield all([takeLatest("auth/adminUpdateProfile", adminUpdateProfile)]);
  yield all([takeLatest("auth/adminChangePassword", adminChangePassword)]);
  yield all([takeLatest("auth/uploadProfilePhoto", uploadProfilePhoto)]);
}

export default authSaga;
