import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import { forgotPassword, onErrorStopLoad } from "../../redux/slices/auth";
import logo from "../Images/logo.png";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastId = useRef(null);
  const authData = useAuthSelector();
  const { loading } = authData;
  const [email, setEmail] = useState("");

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      showToast("Please enter your email.");
      return;
    } else if (
      email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      showToast("Please enter valid email");
      return;
    }
    let params = {
      email: email.trim(),
    };
    dispatch(
      forgotPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/enter-otp", {
              state: email,
            });
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg adminpwd">
        <div className="brandlogo">
          <img src={logo} alt="brandlogo" onClick={() => navigate("/")} />
        </div>
        <div className="card card-outline card-primary cardTopOutline cardBg">
          <div className="loginPage">
            <div className="card-header button-es text-center border-0">
              <h3 className="mb-0 logintext">Forgot Password</h3>
              <p className="form_subheading mt-3">
                Enter registered email address to reset your password.
              </p>
            </div>
            <div className="card-body login-card-body">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control form-input borderinput"
                    name="email"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      className="loginBtnCommon btnYellow  mw-100  loginbtn"
                      type="submit"
                      disabled={loading}
                    >
                      Request OTP
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-1"></span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
