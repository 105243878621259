import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateOneCategory } from "../../redux/slices/dashboard";

const UpdateCategoryModal = ({
  selectedParentCategory,
  close,
  getAllCategories,
  flag,
  status,
}) => {

  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const [categoryName, setCategoryName] = useState(
    selectedParentCategory.name ? selectedParentCategory.name : ""
  );

  /**
   * to update category name
   * @param {*} e
   * @returns
   */
  const handleUpdateCategoryName = (e) => {
    e.preventDefault();
    if (!categoryName && !toast.isActive(toastId.current)) {
      const message =
        flag === "updateSubCategory"
          ? "Please Add Type Name"
          : "Please Add Genre Name";
      toastId.current = toast.error(message);
      return false;
    }
    let params = {
      name: categoryName,
      id: selectedParentCategory?._id,
    };
    dispatch(
      updateOneCategory({
        ...params,
        cb(res) {
          if (res.status === 200) {
            close();
            getAllCategories();
          }
        },
      })
    );
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          handleUpdateCategoryName(e);
        }}
      >
        <div className="categoryModalSection">
          <div className="categoryForm">
            <label className="input_Label">
              {status === "genre" ? "Genre" : "Type"}
            </label>
            <input
              class="form-control categoryInput "
              type="text"
              placeholder={
                status === "genre" ? "Enter Genre Name" : "Enter Type Name"
              }
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </div>
          <button className="blockbtn btnYellow w-100" type="submit">
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default UpdateCategoryModal;
