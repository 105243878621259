import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
};

export const helperSlice = createSlice({
    name: "ticket",
    initialState,
    reducers: {
        onErrorStopLoad: (state) => {
            state.loading = false;
        },
        getAllHelper: (state) => {
            state.loading = true;
        },
        setGetAllHelper: (state) => {
            state.loading = false;
        },
        getOneHelper: (state) => {
            state.loading = true;
          },
          setGetOneHelper: (state) => {
            state.loading = false;
          },
          setUpdateOneHelper: (state) => {
            state.loading = true;
          },
          updateOneHelper: (state) => {
            state.loading = false;
          },
    },
});

// Action creators are generated for each case reducer function
export const {
    onErrorStopLoad,
    getAllHelper,
    setGetAllHelper,
    getOneHelper,
    setGetOneHelper,
    updateOneHelper,
    setUpdateOneHelper
} = helperSlice.actions;

export default helperSlice.reducer;
