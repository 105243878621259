import React from "react";
import { useDispatch } from "react-redux";
import {
  deletePublications
} from "../../redux/slices/dashboard";

const DeletePublishModal = (param) => {
  const dispatch = useDispatch();
  //API for delete Publication...............
  const handleDeletePublication = (data) => {
    let params = {
      id: param.deletId,
    };
    dispatch(
      deletePublications({
        ...params,
        cb(res) {
          if (res?.data) {
            param?.getPublications();
            param?.close();
          }
        },
      })
    );
  };
  return (
    <>
      <div className="deleteModalsec text-center my-3">
        <button
          className="blockbtn btnYellow w-100"
          onClick={handleDeletePublication}
        >
          Yes, Delete
        </button>
      </div>
    </>
  );
};

export default DeletePublishModal;
