import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  loading: false,
  success: false,
  loginData: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    adminLogin: (state) => {
      state.loading = true;
    },
    setAdminLogin: (state, action) => {
      state.loading = false;
      state.loginData = action.payload;
      state.isLoggedIn = true;
    },
    forgotPassword: (state) => {
      state.loading = true;
    },
    setForgotPassword: (state, action) => {
      state.loading = false;
      state.forgotPassword = action.payload;
    },
    resetPassword: (state) => {
      state.loading = true;
    },
    setResetPassword: (state) => {
      state.loading = false;
    },
    resendEnterOtp: (state) => {
      state.loading = true;
    },
    setResendEnterOtp: (state) => {
      state.loading = false;
    },
    enterOtp: (state) => {
      state.loading = true;
    },
    setEnterOtp: (state, action) => {
      state.loading = false;
      state.enterOtp = action.payload;
    },
    adminUpdateProfile: (state) => {
      state.loading = true;
      state.success = false;
    },
    setAdminUpdateProfile: (state, action) => {
      state.loading = false;
      state.loginData = action.payload;
      state.success = true;
    },
    adminChangePassword: (state) => {
      state.loading = true;
    },
    setAdminChangePassword: (state, action) => {
      state.loading = false;
    },
    uploadProfilePhoto: (state) => {
      state.loading = true;
    },
    setUploadProfilePhoto: (state) => {
      state.loading = false;
    },
    adminLogout: (state) => {
      state.loading = true;
    },
    setAdminLogout: (state) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.loginData = {};
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  forgotPassword,
  setForgotPassword,
  adminLogin,
  setAdminLogin,
  onErrorStopLoad,
  adminLogout,
  setAdminLogout,
  enterOtp,
  setEnterOtp,
  resendEnterOtp,
  setResendEnterOtp,
  resetPassword,
  setResetPassword,
  adminUpdateProfile,
  setAdminUpdateProfile,
  adminChangePassword,
  setAdminChangePassword,
  uploadProfilePhoto,
  setUploadProfilePhoto,
  getFcmTocken
} = authSlice.actions;

export default authSlice.reducer;
