import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { checkExistCategory, createCategory } from "../../redux/slices/dashboard";
import * as images from "../../utilities/images";
import CustomModal from "./CustomModal";
import SubCategoryModal from "./subCategoryModal";

const CreateCategoryModal = ({ getAllCategories, close,selectedParentCategory ,categoryType}) => {
  console.log(selectedParentCategory,"selectedParentCategory");
  
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [categoryName, setCategoryName] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const category = selectedParentCategory === "genre" ? "Genre" : "Type";

  //closeModal
  // const handleOnCloseModal = () => {
  //   setModalDetail({
  //     show: false,
  //     title: "",
  //     flag: "",
  //   });
  //   setKey(Math.random());
  // };

  // const handleOpenModal = (flag) => {
  //   setModalDetail({
  //     show: true,
  //     flag: flag,
  //     type: flag,
  //   });
  //   setKey(Math.random());
  // };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  const handleCheckCategoryName = (flag, e) => {
    e.preventDefault();
    if (!categoryName) {
      showToast(`Please Add ${category} Name`);
      return;
    }
    let params = {
      name: categoryName,
      flag:selectedParentCategory
    };
    dispatch(
      createCategory({
        ...params,
        cb(res) {
          if (res.status === 200) {
            categoryType(res?.data?.data?.flag)
            close();
            getAllCategories()
          
            // handleOpenModal(flag);
          }
        },
      })
    );
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          handleCheckCategoryName("createsubcategory", e);
        }}
      >
        <div className="categoryModalSection">
          <div className="categoryForm  mt-2">
          <label className="input_Label">{category}</label>
            <input
              class="form-control categoryInput"
              type="text"
              placeholder={`Enter ${category} Name`}
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </div>
          <button className="blockbtn btnYellow w-100" type="submit">
            Add
          </button>
        </div>
      </form>
      {/* <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createsubcategory"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "createsubcategory" ? "subCategoryModal" : ""}
        child={
          modalDetail.flag === "createsubcategory" ? (
            <SubCategoryModal
              getAllCategories={getAllCategories}
              close={() => {
                handleOnCloseModal();
                close();
              }}
              flag={"addSubCate"}
              categoryName={categoryName}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "createsubcategory" ? (
            <>
              <h2 className="modal_Heading">Add Type</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCross}
                  className="ModalCancel img-fluid"
                  alt=""
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      /> */}
    </>
  );
};

export default CreateCategoryModal;
