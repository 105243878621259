import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSocialLinksSelector } from "../../redux/selector/social-links";
import {
  deleteClientLogo,
  onErrorStopLoad,
} from "../../redux/slices/socialLinks";

const DeleteLogoModal = ({ close, getClientList, logoData }) => {
  const dispatch = useDispatch();
  const socialSelector = useSocialLinksSelector();
  const { loading } = socialSelector;
  // delete
  const handleDeleteClientLogo = () => {
    const { _id } = logoData;
    let params = {
      id: _id,
    };
    dispatch(
      deleteClientLogo({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            close();
            getClientList();
          }
        },
      })
    );
  };

  // stop loading on error
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="deleteModalsec text-center my-3">
        <button
          className="blockbtn btnYellow w-100"
          onClick={handleDeleteClientLogo}
        >
          Yes, Delete
          {loading && (
            <span className="spinner-border spinner-border-sm ms-1"></span>
          )}
        </button>
      </div>
    </>
  );
};

export default DeleteLogoModal;
