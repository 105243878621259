import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useHelperSelector } from "../../redux/selector/helper";
import { getOneHelper } from "../../redux/slices/helper";
import * as images from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import EditHelper from "./EditHelper";

const ContentView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [helperDetails, setHelperDetails] = useState("");
  const helperSelector = useHelperSelector();
  const { loading } = helperSelector;

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  /**
   * helper details
   */
  const getOneHelperDetails = () => {
    let params = {
      id: id,
    };
    dispatch(
      getOneHelper({
        ...params,
        cb(res) {
          if (res?.data) {
            setHelperDetails(res?.data?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    getOneHelperDetails();
  }, []);

  return (
    <>
      <div className="content-wrap pb-5 ">
        <div className="container-fluid my-4">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <Link to="/content-pages">
                <div className="userDeatilHead d-inline-flex align-items-center ">
                  <img
                    src={images.arrowMain}
                    alt="arrowMainimg"
                    className="img-fluid pointCursor"
                  />
                  <h1 className="mainHead32">{helperDetails?.title}</h1>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 text-end">
              <button
                className={`blockbtn completeTicket`}
                type="button"
                onClick={() => {
                  setModalDetail({
                    show: true,
                    flag: "editHelper",
                  });
                  setKey(Math.random());
                }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        <div className="contentInfo">
          <div dangerouslySetInnerHTML={{ __html: helperDetails?.content }} />
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "editHelper" ||
          modalDetail.flag === "updateCategoryModal "
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "editHelper" ||
          modalDetail.flag === "updateCategoryModal"
            ? "helpingModal"
            : ""
        }
        size="lg"
        child={
          modalDetail.flag === "editHelper" ? (
            <EditHelper
              close={() => handleOnCloseModal()}
              helperData={helperDetails}
              getOneHelperDetails={() => getOneHelperDetails()}
              id={id}
              loading={loading}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "editHelper" ? (
            <>
              <h2 className="modal_Heading">Edit Helper</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ContentView;
