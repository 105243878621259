import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
import { userList } from "../../redux/slices/dashboard";
import * as images from "../../utilities/images";
import moment from "moment";

const Users = () => {
  document.title = "Subscribers";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashBoardSelector = UseDashBoardSelector();
  const { loading } = dashBoardSelector;
  const [userLists, setUserList] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleSuggestionClick = (suggestion) => {
  
    // Check if the search term matches the full name or email
    const fullName = `${suggestion?.userInfo?.firstName} ${suggestion?.userInfo?.lastName}`;
    const email = suggestion?.email;
    
    // Set the search value based on what the user clicked
    if (fullName.toLowerCase().includes(search.toLowerCase())) {
      setSearch(fullName);
    } else if (email?.toLowerCase().includes(search.toLowerCase())) {
      setSearch(email);
    } else {
      // Fallback to the name if no match was found
      setSearch(fullName);
    }
  
    // Clear the suggestions after selection
    setFilteredSuggestions([]);
  };

  // List of suggestions (replace with actual data)
  const suggestions = userLists || [];

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setCurrentPage(1);

    // Filter suggestions based on input
    const filtered = suggestions.filter(
      (item) =>
        `${item?.userInfo?.firstName?.toLowerCase()} ${item?.userInfo?.lastName?.toLowerCase()}`.includes(
          value.toLowerCase()
        ) || item?.email?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filtered);
  };

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // get all users
  useEffect(() => {
    let params = {
      page: currentPage,
      limit: 10,
      search: search,
      status: status || "",
    };
    if (Boolean(search)) {
      params.search = search;
    }
    dispatch(
      userList({
        ...params,
        cb(res) {
          if (res?.data) {
            setUserList(res?.data?.data?.data);
            setPageCount(res.data.data.total_pages);
          }
        },
      })
    );
  }, [status, search, currentPage]);

  return (
    <>
      <div className="content-wrap pb-5">
        <h1 className="mainHead32 mb-4 mt-3">Subscribers</h1>
        <div className="searchSection">
          <input
            class="form-control searchInput"
            type="text"
            placeholder="Search by name & email"
            value={search || ""}
            // onChange={(e) => {
            //   setSearch(e.target.value);
            //   setCurrentPage(1);
            // }}
            onChange={handleSearchChange}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIcon"
          />
          {search && filteredSuggestions.length > 0 && (
            <ul className="autocomplete-list pointCursor">
              {filteredSuggestions.map((suggestion) => {
                const fullName = `${suggestion?.userInfo?.firstName} ${suggestion?.userInfo?.lastName}`;
                const email = suggestion?.email;

                // Determine whether to show the name or email based on the search input
                const searchValue = search.toLowerCase();
                const displayValue = fullName
                  .toLowerCase()
                  .includes(searchValue)
                  ? fullName
                  : email && email.toLowerCase().includes(searchValue)
                  ? email
                  : fullName; // Default to full name if neither matches
                return (
                  <li
                    key={suggestion._id}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="autocomplete-item"
                  >
                    {displayValue}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="recentUserSec">
          <div className="userListStatus">
            <h4
              className={
                status === "" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("")}
            >
              All
            </h4>
            <h4
              className={
                status === "Active" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("Active")}
            >
              Active
            </h4>
            <h4
              className={
                status === "Inactive" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("Inactive")}
            >
              Inactive
            </h4>
            {/* <h4
              className={
                status === "Blocked" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("Blocked")}
            >
              Blocked
            </h4> */}
          </div>
          <div className="table-responsive recentTable userRecentTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead recentTable_Head">
                    NAME
                  </th>
                  <th scope="col" className="recentHead">
                    EMAIL
                  </th>
                  <th scope="col" className="recentHead">
                    CREATED ON
                  </th>

                  <th scope="col" className="recentHead recentTable_Head">
                    PHONE NUMBER
                  </th>
                  <th scope="col" className="recentHead recentTable_Head">
                    ACTION
                  </th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={12}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {userLists?.length > 0 ? (
                      userLists?.map((data, idx) => {
                        const serialNumber = (currentPage - 1) * 10 + idx + 1;
                        return (
                          <tr>
                            <td
                              className="recentSubHead subcribers_Users"
                              onClick={() =>
                                navigate(`/user-detail/${data?._id}`, {
                                  state: {
                                    flag: "subscribers",
                                  },
                                })
                              }
                            >
                              <h4 className="tableSubText">{data?.userId}</h4>
                            </td>
                            <td
                              className="recentSubHead subcribers_Users"
                              onClick={() =>
                                navigate(`/user-detail/${data?._id}`, {
                                  state: {
                                    flag: "subscribers",
                                  },
                                })
                              }
                            >
                              <div className="nameProfile">
                                <div className="profile-outer">
                                  <img
                                    src={
                                      data?.userInfo?.profilePhoto
                                        ? data?.userInfo?.profilePhoto
                                        : images.dummyProfile
                                    }
                                    className="img-fluid profileImg_"
                                    alt="profileImg"
                                  />
                                </div>
                                <h4 className="tableSubText">
                                  {`${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`}
                                </h4>
                              </div>
                            </td>
                            <td className="recentSubHead">
                              <h4 className="tableSubText textTrans">
                                {data?.email}
                              </h4>
                            </td>
                            <td className="recentSubHead">
                              <h4 className="tableSubText textTrans">
                                {moment(data?.createdAt).format("MMM-DD-YYYY")}
                              </h4>
                            </td>

                            <td className="recentSubHead">
                              <h4 className="tableSubText">
                                +{data?.userInfo?.dialCode}{" "}
                                {data?.userInfo?.phoneNo}
                              </h4>
                            </td>
                            <td className="recentSubHead text-center">
                              <button
                                className="editBtn"
                                type="button"
                                onClick={() =>
                                  navigate(`/user-detail/${data?._id}`, {
                                    state: {
                                      flag: "subscribers",
                                    },
                                  })
                                }
                              >
                                <img
                                  src={images.eyeImg}
                                  className="img-fluid"
                                  alt="editPen image"
                                />
                                View
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="noFoundHead">
                          <h3 className="text-center subhead24  m-0">
                            No Users Found!
                          </h3>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {userLists && userLists?.length > 0 && (
          <div className=" paginateSec">
            <ReactPaginate
              className="paginationBox mb-0"
              nextLabel=" >"
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Users;
