import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSocialLinksSelector } from "../../redux/selector/social-links";
import {
  addClientLogo,
  editClientLogo,
  onErrorStopLoad,
  uploadClientLogo,
} from "../../redux/slices/socialLinks";
import * as images from "../../utilities/images";

const AddClientLogos = ({ getClientList, close, logoData }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const socialSelector = useSocialLinksSelector();
  const { loading } = socialSelector;
  const [clientName, setClientName] = useState("");
  const [clientUrl, setClientUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // get single client data
  useEffect(() => {
    if (logoData) {
      setClientName(logoData.name);
      setClientUrl(logoData.link);
      setLogoUrl(logoData.logo);
    }
  }, []);

  // Handle file change
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      file.type !== "image/png" &&
      file.type !== "image/svg" &&
      file.type !== "image/svg+xml" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      showToast("Please upload valid image");
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      showToast("File size should be less than 5 MB");
      return;
    }

    let params = {
      image: file,
    };
    dispatch(
      uploadClientLogo({
        ...params,
        cb(res) {
          if (res?.data) {
            setLogoUrl(res?.data?.data?.profile_photo);
          }
        },
      })
    );
  };

  // stop loader on error
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let validUrl = clientUrl;
    if (!logoUrl) {
      showToast("Please add client logo");
      return;
    } else if (!clientName?.trim()) {
      showToast("Please enter client name");
      return;
    } else if (!validUrl?.trim()) {
      showToast("Please enter client url");
      return;
    }
    // else if (
    //   validUrl &&
    //   !/^(https?:\/\/)?[a-z0-9\-\.]{3,}\.[a-z]{2,}$/.test(validUrl)
    // ) {
    //   showToast("Please enter a valid URL");
    //   return;
    // }
    else if (
      validUrl &&
      !/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([\/\?].*)?$/i.test(
        validUrl
      )
    ) {
      showToast("Please enter valid url");
      return;
    }

    if (!validUrl.includes("https://") && !validUrl.includes("http://")) {
      validUrl = "https://" + validUrl;
    }

    let params = {
      name: clientName,
      logo: logoUrl,
      link: validUrl,
    };

    if (logoData) {
      params.id = logoData._id;
    }

    if (logoData) {
      dispatch(
        editClientLogo({
          ...params,
          cb(res) {
            if (res?.status === 200) {
              getClientList();
              close();
            }
          },
        })
      );
    } else {
      dispatch(
        addClientLogo({
          ...params,
          cb(res) {
            if (res?.status === 200) {
              getClientList();
              close();
            }
          },
        })
      );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="categoryModalSection">
          <div className="client-logo-outer w-25 m-auto">
            <div className="uploadOuter flex-column uploadOuterLogo">
              <figure className="uploadInner upload-client-logo">
                <label className="uploadLabel">
                  <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleImageUpload}
                    accept="image/*"
                  />
                  {logoUrl ? (
                    <img
                      src={logoUrl}
                      alt="defaultUser"
                      className="publishLogoImg"
                    />
                  ) : (
                    <img
                      src={images.uploadfile}
                      alt="defaultUser"
                      className="fileImg"
                    />
                  )}
                </label>
              </figure>
              <h4 className="form_subheading mt-1">Upload Logo</h4>
            </div>
          </div>

          <div className="categoryForm">
            <label className="input_Label">Client Name</label>
            <input
              class="form-control categoryInput"
              type="text"
              placeholder="Enter Client Name"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
          </div>
          <div className="categoryForm">
          <label className="input_Label">Client URL</label>
            <input
              class="form-control categoryInput"
              type="text"
              placeholder="Enter Client Url"
              value={clientUrl}
              onChange={(e) => setClientUrl(e.target.value)}
            />
          </div>
          <button className="blockbtn btnYellow w-100" type="submit">
            Continue
            {loading && (
              <span className="spinner-border spinner-border-sm ms-1"></span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddClientLogos;
