import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { onErrorStopLoad } from "../../redux/slices/dashboard";
import { activeInactiveSocialLink } from "../../redux/slices/socialLinks";

const UpdateActiveInactiveLink = (props) => {
  const { close, selectedSocialLinks, getAllSocialLinks } = props;
  const dispatch = useDispatch();

  // delete helper page
  const handleUpdateActiveInactive = () => {
    let params = {
      id: selectedSocialLinks?._id,
      isActive: selectedSocialLinks?.isActive ? "false" : "true",
    };
    dispatch(
      activeInactiveSocialLink({
        ...params,
        cb(res) {
          if (res?.data) {
            getAllSocialLinks();
            close();
          }
        },
      })
    );
  };

  // stop loading
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="deleteModalsec text-center my-3">
        <button
          className="blockbtn btnYellow w-100"
          onClick={() => handleUpdateActiveInactive()}
        >
          Yes, {selectedSocialLinks.isActive ? "Deactive" : "Active"}
        </button>
      </div>
    </>
  );
};

export default UpdateActiveInactiveLink;
