import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  adminUpdateProfile,
  uploadProfilePhoto,
} from "../../redux/slices/auth";
import * as images from "../../utilities/images";
import { useAuthSelector } from "../../redux/selector/auth";

const EditProfile = (props) => {
  const toastId = useRef(null);
  const location = useLocation();
  const { state } = location;
  const authSelector = useAuthSelector();
  const { loading } = authSelector;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState(state?.firstName || "");
  const [email, setEmail] = useState(state?.email || "");
  const [image, setImage] = useState(state?.profilePhoto || "");

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  /**
   * Update profile functionality
   * @param {*} e
   * @returns updated profile response
   */
  const handleUpdateProfile = (e) => {
    e.preventDefault();
    if (!name) {
      if (!toast.isActive(toastId.current)) {
        showToast("Please enter your name");
        return false;
      }
    }
    // else if (!image) {
    //   showToast("Please select pofile photo");
    //   return;
    // }
    let params = {
      firstName: name,
      profilePhoto: image || undefined,
    };
    dispatch(
      adminUpdateProfile({
        ...params,
        cb(res) {
          if (res.status === 200) {
            navigate("/profile");
          }
        },
      })
    );
  };

  /**
   * to update profile photo
   * @param {*} e
   */

  const handleProfilePhotoUpload = (e) => {
    const file = e.target.files[0];
    let params = {
      image: file,
    };
    dispatch(
      uploadProfilePhoto({
        ...params,
        cb(res) {
          if (res?.data) {
            setImage(res?.data?.data?.profile_photo);
          }
        },
      })
    );
  };

  return (
    <>
      <div className="content-wrapper editProfileSec">
        <div className="userDeatilHead my-4">
          <img
            src={images.arrowMain}
            alt="arrowMainimg"
            className="img-fluid pointCursor"
            onClick={() => {
              navigate("/profile");
            }}
          />
          <h1 className="mainHead32">Edit Profile</h1>
        </div>
        <form
          onSubmit={(e) => {
            handleUpdateProfile(e);
          }}
        >
          <div className="row ">
            <div className="col-lg-3 my-4">
              <div className="editLeft">
                {loading ? (
                  <>
                    <div className="loaderOuter d-flex justify-content-center text-light">
                      <div
                        className="spinner-grow text-light"
                        role="status"
                      ></div>
                    </div>
                  </>
                ) : (
                  <>
                    <figure className="uploadSec">
                      <img
                        src={image || images.dummyProfile}
                        alt="defaultUser"
                        className="userImg"
                      />
                      <label className="uploadUserlabel">
                        <input
                          type="file"
                          id="myFile"
                          name="filename"
                          onChange={(e) => handleProfilePhotoUpload(e)}
                        />

                        <div className="camImgBx">
                          <img
                            src={images.cameraImg}
                            alt="cameraImg"
                            className="img-fluid"
                          />
                        </div>
                      </label>
                    </figure>
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-9 my-4">
              <div className="editRight">
                <div className="publishInput mt-3">
                  <input
                    type="text"
                    className="form-control nameControl"
                    name="name"
                    value={name}
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="publishInput mt-3">
                  <input
                    type="email"
                    className="form-control nameControl"
                    name="name"
                    value={email}
                    placeholder="johndoe123@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="editProfileBtn">
                <button
                  type="button"
                  className="cancelBtn"
                  onClick={() => navigate("/profile")}
                >
                  {" "}
                  cancel
                </button>
                <button className="blockbtn" type="submit">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditProfile;
