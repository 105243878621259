import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  setGetAllSocialLink,
  onErrorStopLoad,
  setGetOneSocialLink,
  setUpdateOneSocialLink,
  setActiveInactiveSocialLink,
  setClientLogos,
  setUploadClientLogo,
  setAddClientLogo,
  setEditClientLogo,
  setDeleteClientLogo
} from "../../slices/socialLinks";
const queryString = require("query-string");

function* deleteClientLogo(action) {
  let deleteParams = { ...action.payload };
  delete deleteParams.id;

  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.socialLinkApiPath.EDIT_CLIENT_LOGO}/${action.payload.id}`),
      deleteParams
    );
    if (resp.status) {
      yield put(setDeleteClientLogo(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* editClientLogo(action) {
  let deleteParams = { ...action.payload };
  delete deleteParams.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.socialLinkApiPath.EDIT_CLIENT_LOGO}/${action.payload.id}`),
      deleteParams
    );
    if (resp.status) {
      yield put(setEditClientLogo(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* addClientLogo(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.socialLinkApiPath.ADD_CLIENT_LOGO),
      action.payload
    );
    if (resp.status) {
      yield put(setAddClientLogo(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* uploadClientLogo(action) {
  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = ApiPath.socialLinkApiPath.UPLOAD_CLIENT_LOGO),
      action.payload
    );
    if (resp.status) {
      yield put(setUploadClientLogo(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUploadClientLogo({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* clientLogos(action) {
  let data = { ...action.payload };
  delete data.cb;
  let url = `${ApiPath.socialLinkApiPath.GET_CLIENT_LOGO}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setClientLogos(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getAllSocialLink(action) {
  let data = { ...action.payload };
  delete data.cb;
  let query = queryString.stringify(data);
  let url = `${ApiPath.socialLinkApiPath.GET_ALL_SOCAIL_LINK}?${query}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetAllSocialLink(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getOneSocialLink(action) {
  let url = `${ApiPath.socialLinkApiPath.GET_ONE_SOCAIL_LINK}/${action.payload.id}`;
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetOneSocialLink(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateOneSocialLink(action) {
  let url = `${ApiPath.socialLinkApiPath.GET_UPDATE_SOCAIL_LINK}/${action.payload.id}`;
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdateOneSocialLink(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp?.data?.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* activeInactiveSocialLink(action) {
  let url = `${ApiPath.socialLinkApiPath.ACTIVE_INACTIVE_LINK}/${action.payload.id}`;
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setActiveInactiveSocialLink(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* socialLinksSaga() {
  yield all([takeLatest("socialLink/getAllSocialLink", getAllSocialLink)]);
  yield all([takeLatest("socialLink/getOneSocialLink", getOneSocialLink)]);
  yield all([takeLatest("socialLink/clientLogos", clientLogos)]);
  yield all([takeLatest("socialLink/uploadClientLogo", uploadClientLogo)]);
  yield all([takeLatest("socialLink/editClientLogo", editClientLogo)]);
  yield all([takeLatest("socialLink/deleteClientLogo", deleteClientLogo)]);
  yield all([
    takeLatest("socialLink/updateOneSocialLink", updateOneSocialLink),
  ]);
  yield all([
    takeLatest("socialLink/activeInactiveSocialLink", activeInactiveSocialLink),
  ]);
  yield all([takeLatest("socialLink/addClientLogo", addClientLogo)]);
}

export default socialLinksSaga;
