import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  onErrorStopLoad,
  setArticleTransaction,
  setGetTransaction,
} from "../../slices/transaction";


function* getTransaction(action) {
  try {
    let mainUrl = `${ApiPath.transactionApiPath.GET_TRANSACTION}`;
    const resp = yield call(ApiClient.get, (action.url = mainUrl));
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      // toast.success(resp.data.message);
      yield put(setGetTransaction(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getArticleTransaction(action) {
  try {
    let mainUrl = `${ApiPath.transactionApiPath.GET_ARTICLE_TRANSACTION}`;
    const resp = yield call(ApiClient.get, (action.url = mainUrl));
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setArticleTransaction(resp.data.data));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}

function* transactionSaga() {
  yield all([takeLatest("transaction/getTransaction", getTransaction)]);
  yield all([takeLatest("transaction/getArticleTransaction", getArticleTransaction)]);
}

export default transactionSaga;
