import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
    getSubscription: (state, action) => {
      state.loading = action.payload.needLoader ? true : false;
    },
    setGetSubscription: (state) => {
      state.loading = false;
    },
    getChangeSubscriptions: (state, action) => {
      state.loading = action.payload.needLoader ? true : false;
    },
    setGetChangeSubscriptions: (state) => {
      state.loading = false;
    },
    createSubscription: (state) => {
      state.loading = true;
    },
    setCreateSubscription: (state) => {
      state.loading = false;
    },
    deleteSubscription: (state) => {
      state.loading = true;
    },
    setDeleteSubscription: (state) => {
      state.loading = false;
    },
    updateSubscription: (state) => {
      state.loading = true;
    },
    setUpdateSubscription: (state) => {
      state.loading = false;
    },
    changeUserPlan: (state) => {
      state.loading = true;
    },
    setChangeUserPlan: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onErrorStopLoad,
  getSubscription,
  setGetSubscription,
  getChangeSubscriptions,
  setGetChangeSubscriptions,
  createSubscription,
  setCreateSubscription,
  deleteSubscription,
  setDeleteSubscription,
  updateSubscription,
  setUpdateSubscription,
  changeUserPlan,
  setChangeUserPlan,
} = subscriptionsSlice.actions;
