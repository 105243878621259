import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    onErrorStopLoad: (state) => {
        state.loading = false;
      },
      getAllArticle: (state) => {
        state.loading = true;
      },
      setGetAllArticle: (state) => {
        state.loading = false;
      },
      getOneArticle: (state) => {
        state.loading = true;
      },
      setGetOneArticle: (state) => {
        state.loading = false;
      },
      setUpdateOneArticle: (state) => {
        state.loading = true;
      },
      updateOneArticle: (state) => {
        state.loading = false;
      },
    },
  });
  
  // Action creators are generated for each case reducer function
  export const {
    onErrorStopLoad,
    getAllArticle,
    setGetAllArticle,
    setGetOneArticle,
    getOneArticle,
    updateOneArticle,
    setUpdateOneArticle
  } = articleSlice.actions;
  
  export default articleSlice.reducer;
  