import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as images from "../../utilities/images";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  ARTICLEPARENTCOLLECTIONNAME,
  ARTICLECHILDCOLLECTIONNAME,
  db,
} from "../../config/firebase-config";
import { ticketUploadDocument } from "../../redux/slices/ticket";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const ArticleMessageModal = ({ articleDetails }) => {
  const messagesEndRef = useRef(null);
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const [imageUrl, setImgUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState("");
  const [isChatLoading, setIsChatLoading] = useState(false);


  // scroll bottom
  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      messagesEndRef?.current?.scrollIntoView({
        block: "end",
        inline: "end",
        behavior: "smooth",
      });
    }
  };

  // remove selected image
  const handleRemoveImage = (url) => {
    if (url === imageUrl) {
      setImgUrl("");
    }
  };
  //onDrop AND UPLOAD IMAGE
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      showToast("Please choose a file");
      return;
    }

    const fileType = acceptedFiles[0].type.split("/")[1]; // Extract file extension
    setFileType(fileType);
    const allowedTypes = [
      "pdf",
      "svg",
      "jpg",
      "jpeg",
      "png",
      "msword",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedTypes.includes(fileType)) {
      showToast("Please choose only mentioned supported file types");
      return;
    }

    const imageFile = acceptedFiles[0]; // Only taking the first file
    const params = {
      image: imageFile,
    };

    dispatch(
      ticketUploadDocument({
        ...params,
        cb(res) {
          if (res.status) {
            setImgUrl(res?.data?.data?.photo);
          }
        },
      })
    );
  }, []);

  // Convert UTC time to local date and time without seconds
  const convertTimeFormat = (milliseconds) => {
    const timestamp = new Date(milliseconds); // Create Date object from milliseconds
    const now = new Date();
    const timeDifferenceInSeconds = Math.floor((now - timestamp) / 1000);

    if (timeDifferenceInSeconds < 5) {
      return "just now";
    }

    const dateFormatOptions = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };

    const timeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const formattedDate = timestamp.toLocaleDateString(
      "en-US",
      dateFormatOptions
    );
    const formattedTime = timestamp.toLocaleTimeString(
      "en-US",
      timeFormatOptions
    );

    return `${formattedDate}, ${formattedTime}`;
  };

  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // showing only images
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "application/msword": [],
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/svg": [],
    },
    multiple: false,
  });

  // send initial message
  const handleInitialMsg = async (e) => {
    e.preventDefault();
    try {
      const roomDocRef = doc(
        db,
        ARTICLEPARENTCOLLECTIONNAME,
        articleDetails?._id
      );
      const messagesCollectionRef = collection(
        roomDocRef,
        ARTICLECHILDCOLLECTIONNAME
      );
      await setDoc(roomDocRef, {
        clearChat: false,
        deletedChatUserIds: [],
        lastMessage: {
          createdAt: Math.floor(Date.now()),
          senderId: articleDetails?.adminDetails?._id,
          text: msg,
          imageUrl: imageUrl,
          recieverId: articleDetails?.userId,
        },
        roomId: articleDetails?._id,
        unseenMessageCount: 1,
        users: [articleDetails?.adminDetails?._id, articleDetails?.userId],
      });

      await addDoc(
        messagesCollectionRef,
        {
          createdAt: Math.floor(Date.now()),
          text: msg,
          imageUrl: imageUrl,
          senderId: articleDetails?.adminDetails?._id,
          recieverId: articleDetails?.userId,
        },
        setMsg(""),
        setImgUrl(""),
        scrollToBottom()
      );
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleUpdateMessage = async (e) => {
    e.preventDefault();
    if (msg || imageUrl) {
      const roomDocRef = doc(
        db,
        ARTICLEPARENTCOLLECTIONNAME,
        articleDetails?._id
      );
      const roomDocSnapshot = await getDoc(roomDocRef);
      const previousUnseenMessageCount =
        roomDocSnapshot.data()?.unseenMessageCount || 0;

      if (roomDocSnapshot.exists()) {
        const messagesCollectionRef = collection(
          roomDocRef,
          ARTICLECHILDCOLLECTIONNAME
        );
        await addDoc(
          messagesCollectionRef,
          {
            createdAt: Math.floor(Date.now()),
            text: msg,
            imageUrl: imageUrl,
            senderId: articleDetails?.adminDetails?._id,
            recieverId: articleDetails?.userId,
          },
          setMsg(""),
          setImgUrl(""),
          scrollToBottom()
        );
        try {
          const roomDocRef = doc(
            db,
            ARTICLEPARENTCOLLECTIONNAME,
            articleDetails?._id
          );
          await updateDoc(
            roomDocRef,
            {
              clearChat: false,
              lastMessage: {
                createdAt: Math.floor(Date.now()),
                senderId: articleDetails?.adminDetails?._id,
                text: msg,
                imageUrl: imageUrl,
                recieverId: articleDetails?.userId,
              },
              roomId: articleDetails?._id,
              unseenMessageCount: previousUnseenMessageCount + 1,
              users: [
                articleDetails?.userId,
                articleDetails?.adminDetails?._id,
              ],
            },
            setMsg(""),
            setImgUrl(""),
            scrollToBottom()
          );
        } catch (error) {
          console.error("Error creating room:", error);
        }
      } else {
        handleInitialMsg(e);
      }
    }
  };

  // get all messages
  useEffect(() => {
    const parentCollectionChat = query(
      collection(db, ARTICLEPARENTCOLLECTIONNAME)
    );
    // const parentCollectionTicket = query(collection(db, TICKETCOLLECTIONNAME));
    setIsChatLoading(true);
    const unsubscribe = onSnapshot(parentCollectionChat, (snap) => {
      const messagesList = snap.docs.map((doc) => {
        const id = doc.id;
        return { id, ...doc.data() };
      });
      const getMyChats = messagesList?.filter((item) => {
        return item?.roomId === articleDetails?._id;
      });
      setIsChatLoading(false);
      getFireStoreData(getMyChats);
    });
    return () => {
      unsubscribe();
    };
  }, [articleDetails?._id]);

  // get inner messages
  const getFireStoreData = (allChats) => {
    if (articleDetails?._id) {
      const allMessageQuery = query(
        collection(
          db,
          ARTICLEPARENTCOLLECTIONNAME,
          articleDetails?._id,
          ARTICLECHILDCOLLECTIONNAME
        ),
        orderBy("createdAt", "asc")
      );

      onSnapshot(allMessageQuery, (snap) => {
        const messagesList = snap.docs.map((doc) => {
          const id = doc.id;
          return { id, ...doc.data() };
        });
        // let filteredMessages = messagesList;
        // if (messagesList && messagesList.length > 0 && lastDeletedAt) {
        //   filteredMessages = messagesList?.filter(
        //     (val) => val?.createdAt > Math.floor(lastDeletedAt)
        //   );
        // }
        const updatedData = messagesList?.map((item) => {
          if (item?.imageUrl === "") {
            const { imageUrl, ...rest } = item;
            return rest;
          }
          return item;
        });
        const removeEmptyTextField = updatedData?.map((item) => {
          if (item?.text === "") {
            const { text, ...rest } = item;
            return rest;
          }
          return item;
        });
        setMessages(removeEmptyTextField);
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, imageUrl]);

  return (
    <>
      <div className="chatOuter">
        <div className="chatInner_Parent">
          {isChatLoading ? (
            <div className="loaderOuter d-flex justify-content-center h-100 align-items-center">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          ) : (
            <>
              {messages && messages?.length > 0 ? (
                <>
                  {messages?.map((message, index) => {
                    let extension;
                    if (message?.imageUrl) {
                      const parts = message.imageUrl.split(".");
                      extension = parts[parts.length - 1];
                    }
                    return (
                      <div
                        key={index}
                        className="chatInner"
                        ref={messagesEndRef}
                      >
                        <div
                          className={
                            articleDetails?.adminDetails?._id ===
                            message?.senderId
                              ? "chatRightOuter"
                              : "chatLeftOuter"
                          }
                        >
                          <div
                            className={
                              articleDetails?.adminDetails?._id ===
                              message?.senderId
                                ? "firstPersonChat"
                                : "secondPersonChat"
                            }
                          >
                            <figure className="secondPersonImg">
                              {articleDetails?.adminDetails?._id !==
                              message?.senderId ? (
                                <img
                                  src={
                                    articleDetails?.Users?.profilePhoto ||
                                    images.dummyProfile
                                  }
                                  alt="searchImg"
                                  className="img-fluid "
                                />
                              ) : (
                                <img
                                  src={
                                    articleDetails?.adminDetails?.userInfo?.profilePhoto ||
                                    images.dummyProfile
                                  }
                                  alt="searchImg"
                                  className="img-fluid "
                                />
                              )}
                            </figure>
                            <div className="personInfo">
                              {articleDetails?.adminDetails?._id !==
                              message?.senderId ? (
                                <h6 className="userText mt-1">{`${
                                  articleDetails?.Users?.firstName || ""
                                } ${articleDetails?.Users?.lastName || ""}`}</h6>
                              ) : (
                                <h6 className="userText mt-1">{`${
                                  articleDetails?.adminDetails?.userInfo?.firstName || ""
                                } ${articleDetails?.adminDetails?.userInfo?.lastName || ""}`}</h6>
                              )}
                              {message?.imageUrl && extension !== "pdf"  && extension !== "doc" && extension !== "docx" && (
                                <div className="message-img mt-2 pointCursor">
                                  <img
                                    alt="upload-img"
                                    src={message.imageUrl}
                                    className="upload_Img"
                                    onClick={() =>
                                      window.open(message.imageUrl, "_blank")
                                    }
                                  />
                                </div>
                              )}

                              {message?.imageUrl && extension === "pdf" && (
                                <a
                                  href={message.imageUrl}
                                  target="_blank"
                                  className="me-3 text-center pointCursor"
                                >
                                  <div className="message-img mt-2">
                                    <img alt="PDF-icon" src={images.PDFImage} />
                                  </div>
                                </a>
                              )}
                              {(message?.imageUrl) && (extension === "doc" || extension === "docx")  && (
                                    <a
                                      href={message.imageUrl}
                                      target="_blank"
                                      className="me-3 text-center pointCursor"
                                    >
                                      <div className="message-img mt-2">
                                        <img
                                          alt="PDF-icon"
                                          src={images.DOCImage}
                                        />
                                      </div>
                                    </a>
                                  )}
                              {message?.text && (
                                <div className="messageBox">{message.text}</div>
                              )}
                              <p className="timeText text-end m-0">
                                {convertTimeFormat(message?.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}
              {imageUrl && (
                <aside className="w-100">
                  <ul className="uploadedArticle" ref={messagesEndRef}>
                    <div className="uploadImgeboxNewArticle ">
                      <div className="position-relative">
                        <img
                          className="w3-round uploadfileIcon "
                          src={
                            fileType === "pdf"
                              ? images.PDFImage
                              : fileType ===
                                  "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                fileType === "msword"
                              ? images.DOCImage
                              : imageUrl
                          }
                        />
                        <button
                          className="deleteUpload"
                          onClick={() => handleRemoveImage(imageUrl)}
                        >
                          <img
                            src={images.DeleteIcon}
                            alt="delet image"
                            className="img-fluid deletIcon"
                          />
                        </button>
                      </div>
                    </div>
                  </ul>
                </aside>
              )}
            </>
          )}
        </div>
      </div>

      <form onSubmit={(e) => handleUpdateMessage(e)}>
        <div className="chatBottom">
          <div className="searchSection w-100 d-flex align-items-center">
            <input
              class="form-control searchInput me-2"
              type="text"
              placeholder="Type your message here ...."
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
              }}
            />
            {!imageUrl && (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <img
                  src={images.attachIcon}
                  alt="searchImg"
                  className="img-fluid attachIcon"
                />
              </div>
            )}
            <button
              type="submit"
              className="crossBtnCategory"
              onClick={(e) => handleUpdateMessage(e)}
            >
              <img
                type="file"
                src={images.sendIcon}
                className="img-fluid"
                alt=""
              />
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ArticleMessageModal;
