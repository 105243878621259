import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
import { getAllSocialLink } from "../../redux/slices/socialLinks";
import * as images from "../../utilities/images";
import { editPen } from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import EditSocialLinksModal from "../Modal/editSocialLinksModal";
import UpdateActiveInactiveLink from "../Modal/updateActiveInactiveLinkModal";

const SocialLinks = () => {
  document.title = "Social Links";
  const dispatch = useDispatch();
  const caregorySelector = UseDashBoardSelector();

  const [socialLinksList, setSocialLinksList] = useState([]);
  const [selectedSocialLinks, setSelectedSocialLinks] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  //open modal
  const handleOpenModal = (flag, data) => {
    setSelectedSocialLinks(data);
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //API for get all type category...............
  const getAllSocialLinks = () => {
    dispatch(
      getAllSocialLink({
        cb(res) {
          if (res?.data) {
            setSocialLinksList(res?.data?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    getAllSocialLinks();
  }, []);

  return (
    <>
      <div className="content-wrap categories publicationsec">
        <div className="innerHeadFlex">
          <h1 className="mainHead32">Social Links</h1>
          {/* <button
            className="loginBtnCommon btnYellow add_btn m-0"
            onClick={() => {
              navigate("/addPublications");
            }}
          >
            Create New
          </button> */}
        </div>

        <div className="table-responsive recentTable publishTable">
          <table class="table table-dark m-0">
            <thead>
              <tr className="recentRow">
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  NAME
                </th>
                {/* <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Slug
                </th> */}
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Link
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie socialLink_StatusHead"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Updated At
                </th>
                <th scope="col" className="recentHead text-start ">
                  ACTION
                </th>
              </tr>
            </thead>

            {caregorySelector?.loading ? (
              <>
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <tbody>
                {socialLinksList?.length > 0 ? (
                  socialLinksList?.map((data, idx) => {
                    return (
                      <tr>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">{data?.name}</h4>
                        </td>
                        {/* <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">{data?.slug}</h4>
                        </td> */}
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">{data?.link}</h4>
                        </td>
                        <td
                          className="recentSubHead align-middle"
                          onClick={() => {
                            handleOpenModal("updateActiveSocialLink", data);
                          }}
                        >
                          <button
                            className={
                              data?.isActive
                                ? "articleActive"
                                : "acrticleInactive"
                            }
                          >
                            {data?.isActive ? "Active" : "InActive"}
                          </button>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">
                            {moment(data?.updatedAt).format("MMM-DD-YYYY")}
                          </h4>
                        </td>

                        <td className="recentSubHead text-center">
                          <div className="categoryOuterBtn">
                            <button
                              className="editBtn"
                              type="button"
                              onClick={() => {
                                handleOpenModal("updateSocialLink", data);
                              }}
                            >
                              <img
                                src={editPen}
                                className="img-fluid "
                                alt="editPen image"
                              />
                              Edit
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} className="noFoundHead">
                      <h3 className="text-center subhead24 m-0">
                        No Social Link Found!
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "updateSocialLink" ||
          modalDetail.flag === "updateActiveSocialLink"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "updateSocialLink"
            ? "updateSocialLink"
            : "updateActiveSocialLink"
        }
        child={
          modalDetail.flag === "updateSocialLink" ? (
            <EditSocialLinksModal
              selectedSocialLinks={selectedSocialLinks}
              close={() => handleOnCloseModal()}
              getAllSocialLinks={() => getAllSocialLinks()}
            />
          ) : modalDetail.flag === "updateActiveSocialLink" ? (
            <UpdateActiveInactiveLink
              selectedSocialLinks={selectedSocialLinks}
              close={() => handleOnCloseModal()}
              getAllSocialLinks={() => getAllSocialLinks()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "updateSocialLink" ? (
            <>
              <h2 className="modal_Heading">Update Social Link</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "updateActiveSocialLink" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to{" "}
                {selectedSocialLinks.isActive ? "Deactive" : "Active"}?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};
export default SocialLinks;
