import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { webSlice } from "./dashboard";
import { ticketSlice } from "./ticket";
import { helperSlice } from "./helper";
import { articleSlice } from "./article";
import { notificationSlice } from "./notification";
import { subscriptionsSlice } from "./subscriptions";
import { transactionSlice } from "./transaction";
import { socialLinksSlice } from "./socialLinks";



export const mainReducer = combineReducers({
  auth: authSlice.reducer,
  web: webSlice.reducer,
  ticket: ticketSlice.reducer,
  helper: helperSlice.reducer,
  article: articleSlice.reducer,
  notification: notificationSlice.reducer,
  subscriptions: subscriptionsSlice.reducer,
  transaction: transactionSlice.reducer,
  socialLink:socialLinksSlice.reducer
});
