import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSubscriptionsSelector } from "../../redux/selector/subscriptions";
import {
  changeUserPlan,
  getChangeSubscriptions,
  getSubscription,
} from "../../redux/slices/subscriptions";
import * as images from "../../utilities/images";
import { toast } from "react-toastify";

const ChangeUserPlan = ({ userDetails, userId, close, getOneUserDetail }) => {
  const toastId = useRef(null);
  const { recentSubscription } = userDetails;
  console.log(recentSubscription
    ,"recentSubscription");
  
  const { planId, _id: subScriptionId } = recentSubscription;
  const dispatch = useDispatch();
  const subscriptionsSelector = useSubscriptionsSelector();
  const { loading } = subscriptionsSelector;
  const [selectedPlanType, setSelectedPlanType] = useState([]);
  const [subcriptList, setSubscriptList] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(planId._id);
  console.log(selectedPlan, "selectedPlan");
  const [isLoading, setIsLoading] = useState(false);
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  //get all Subscriptions
  const getAllSubcription = () => {
    dispatch(
      getSubscription({
        cb(res) {
          if (res?.status === 200) {
            setSubscriptList(res?.data?.data);
            let planType = [];
            res?.data?.data?.map((i) => {
              let isexist = i.plans.find((item) => item._id === planId?._id);
              return i?.plans?.forEach((j) => {
                if (isexist && j._id == planId?._id) {
                  planType.push(j);
                } else if (!isexist && j.planType == "month") {
                  planType.push(j);
                } else if (!isexist && j.planType == "Free") {
                  planType.unshift(j);
                }
              });
            });
            setSelectedPlanType(planType);
          }
        },
      })
    );
  };

  // change user plan
  const handleChangeUserPlan = () => {
    if (!selectedPlan) {
      showToast("Please select plan");
      return;
    }
    let params = {
      planId: selectedPlan,
      // subId: subScriptionId,
      userId: userId,
    };
    dispatch(
      changeUserPlan({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            close();
            getOneUserDetail();
          }
        },
      })
    );
  };

  useEffect(() => {
    getAllSubcription();
  }, []);

  return (
    <>
      {/* <div className=" subscriptionSec">
        {isLoading ? (
          <>
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          </>
        ) : (
          <> */}
      <div className=" subscriptionSec pb-4">
        {subscriptionsSelector?.loading ? (
          <>
            <div className="loaderOuter d-flex justify-content-center text-light">
              <div className="spinner-grow text-light" role="status"></div>
            </div>
          </>
        ) : (
          <>
            {/* <div className="innerHeadFlex mb-5">
              <h1 className="mainHead32">Subscriptions</h1>
            </div> */}
            {subcriptList?.map((val, idx) => {
              const curentPlan = val?.plans.find(
                (data) => data?._id == planId?._id
              );
           
              const isCurrentPlanActive =
              curentPlan &&
              selectedPlanType?.at(idx)?.planType ===
                curentPlan.planType;
                console.log(isCurrentPlanActive,"isCurrentPlanActive");

              const planPriceToShow = isCurrentPlanActive ? recentSubscription?.price:selectedPlanType?.at(idx)?.price;
              if (selectedPlanType?.at(idx)?.planType === "Free") return null;
              const planNameToShow = isCurrentPlanActive ? recentSubscription?.planId?.planName:selectedPlanType?.at(idx)?.planName;
              const PlanArticleInfo = selectedPlanType?.at(idx);
              const MaximumArticleToShow = isCurrentPlanActive ? recentSubscription?.maxArticlesPerMonth:selectedPlanType?.at(idx)?.maxArticlesPerMonth
              const MarkupToShow = isCurrentPlanActive ? recentSubscription?.markUp:selectedPlanType?.at(idx)?.maxArticlesPerMonth
              const featuresToShow = isCurrentPlanActive ? recentSubscription?.features:selectedPlanType?.at(idx)?.features;
              console.log(featuresToShow,"featuresToShow");
              
              return (
                <div className="planOuter" key={idx}>
                  <div className="planName changePlan_first">
                    <h4 className="planText">{planNameToShow}</h4>
                    <h4 className="priceText ">
                      ${planPriceToShow}
                      <span className="monthText">
                        /{selectedPlanType?.at(idx)?.planType}{" "}
                        {selectedPlanType?.at(idx)?.planType === "year" &&
                          `- ${
                            selectedPlanType?.at(idx)?.yearlyDiscount
                          }% discount`}
                      </span>
                    </h4>
                    <h6 className="userLightPara">
                      Last Updated:{" "}
                      <span className="form_subheading">
                        {moment(val?.updatedAt).format("MMM-DD-YYYY")}
                      </span>
                    </h6>
                  </div>
                  <div className="includePlan changePlan_Second">
                    {selectedPlanType?.at(idx)?.planType !== "Free" && (
                      <ul className="planTabs_">
                        <li
                          className={
                            "planttabslist " +
                            (selectedPlanType?.at(idx)?.planType === "month" &&
                              "active")
                          }
                          onClick={() => {
                            let data = [...selectedPlanType];
                            data?.splice(
                              idx,
                              1,
                              val?.plans?.find((i) => i?.planType == "month")
                            );
                            setSelectedPlanType(data);
                          }}
                        >
                          Monthly
                        </li>
                        <li
                          className={
                            "planttabslist " +
                            (selectedPlanType?.at(idx)?.planType === "year" &&
                              "active")
                          }
                          onClick={() => {
                            let data = [...selectedPlanType];
                            data?.splice(
                              idx,
                              1,
                              val?.plans?.find((i) => i?.planType == "year")
                            );
                            setSelectedPlanType(data);
                          }}
                        >
                          Yearly
                        </li>
                      </ul>
                    )}
                    <h5 className="subgreyText_">INCLUDES:</h5>

                    <div className="flexPlan mt-2">
                      <img
                        src={images.checkMark}
                        alt="checkMark image"
                        className="img-fluid"
                      />
                      <h6 className="planDesc">
                        Maximum Articles{" "}
                        {PlanArticleInfo?.planType !== "Free"
                          ? `per ${PlanArticleInfo?.planType}`
                          : ""}{" "}
                        : {MaximumArticleToShow|| "----"}
                      </h6>
                    </div>
                    {PlanArticleInfo?.planType !== "Free" && (
                      <div className="flexPlan mt-2">
                        <img
                          src={images.checkMark}
                          alt="checkMark image"
                          className="img-fluid"
                        />
                        <h6 className="planDesc">
                          Markup :{" "}{MarkupToShow}%
                        </h6>
                      </div>
                    )}

                    {featuresToShow?.map((feature, index) => (
                      <div className="flexPlan mt-2" key={index}>
                        <img
                          src={images.checkMark}
                          alt="checkMark image"
                          className="img-fluid"
                        />
                        <h6 className="planDesc">{feature || ""}</h6>
                      </div>
                    ))}
                  </div>
                  <div className="planBtn select_Role changePlan_Third">
                    <div>
                      <input
                        type="radio"
                        id={idx}
                        name="radio-group"
                        checked={PlanArticleInfo?._id === selectedPlan || false}
                        onChange={() => setSelectedPlan(PlanArticleInfo._id)}
                      />
                      <label for={idx}> </label>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {/* </>
        )}
      </div> */}
      <div className="mt-2 d-flex align-items-center justify-content-center gap-3">
        <button onClick={close} type="button" className="deactivateBtn">
          Cancel
        </button>
        <button
          disabled={!selectedPlan || loading}
          onClick={handleChangeUserPlan}
          type="button"
          className="blockbtn"
        >
          Change
          {loading && (
            <span className="spinner-border spinner-border-sm ms-1"></span>
          )}
        </button>
      </div>
    </>
  );
};

export default ChangeUserPlan;
