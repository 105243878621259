import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminChangePassword } from "../../redux/slices/auth";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [passwordNewVisibility, setPasswordNewVisibility] = useState(false);
  const [passwordOldVisibility, setPasswordOldVisibility] = useState(false);
  const [passwordConfirmVisibility, setPasswordConfirmVisibility] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  var specialCharacter =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
      newPassword
    );

  /**
   *Password toggle functionality
   * @param {*} data
   */

  const togglePasswordVisiblity = (data) => {
    if (data == "oldpassword") {
      setPasswordNewVisibility(!passwordNewVisibility);
    } else if (data == "newpassword") {
      setPasswordOldVisibility(!passwordOldVisibility);
    } else if (data == "confirmpassword") {
      setPasswordConfirmVisibility(!passwordConfirmVisibility);
    }
  };

  /**
   * form change function
   * @param {*} e 
   * @returns 
   */
  const handleChangePassword = (e) => {
    e.preventDefault();
    if (!oldPassword) {
      showToast("Please enter old password");
      return;
    } else if (!newPassword) {
      showToast("Please enter new password");

      return;
    } else if (!specialCharacter) {
      showToast(
        "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
      );
      return;
    } else if (!confirmPassword) {
      showToast("Please enter confirm password");
      return;
    } else if (confirmPassword != newPassword) {
      showToast("Confirm password not matched");
      return;
    }

    let params = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    dispatch(
      adminChangePassword({
        ...params,
        cb(res) {
          if(res.data){
            setNewPassword("")
            setOldPassword("")
            setConfirmPassword("")
          }
        },
      })
    );
  };
  
  return (
    <>
      <div className="content-wrapper changePasswordSec ">
        <h1 className="mainHead32 my-4">Change Password</h1>
        <form onSubmit={(e) => handleChangePassword(e)}>
          <div className="changePasswordOuter ">
            <div className="row">
              <div className="col-lg-4">
                <div className="input-container mt-3">
                  <input
                    type={passwordNewVisibility ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="password"
                    value={oldPassword}
                    placeholder="Old Password"
                    onChange={(e) => setOldPassword(e.target.value)}
                  />

                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      togglePasswordVisiblity("oldpassword");
                    }}
                  >
                    <span
                      className={
                        passwordNewVisibility
                          ? "hide-icon togglePassword"
                          : "show-icon togglePassword"
                      }
                    ></span>
                  </span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-container mt-3">
                  <input
                    type={passwordOldVisibility ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="password"
                    value={newPassword}
                    placeholder="New Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      togglePasswordVisiblity("newpassword");
                    }}
                  >
                    <span
                      className={
                        passwordOldVisibility
                          ? "hide-icon togglePassword"
                          : "show-icon togglePassword"
                      }
                    ></span>
                  </span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-container mt-3">
                  <input
                    type={passwordConfirmVisibility ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="password"
                    value={confirmPassword}
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      togglePasswordVisiblity("confirmpassword");
                    }}
                  >
                    <span
                      className={
                        passwordConfirmVisibility
                          ? "hide-icon togglePassword"
                          : "show-icon togglePassword"
                      }
                    ></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="editProfileBtn">
                <button
                  type="button"
                  className="cancelBtn"
                  onClick={() => navigate("/home")}
                >
                  {" "}
                  cancel
                </button>
                <button className="blockbtn" type="submit">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
