import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  onErrorStopLoad,
  setDeleteSubscription,
  setGetSubscription,
  setUpdateSubscription,
  setChangeUserPlan,
  setGetChangeSubscriptions,
} from "../../slices/subscriptions";

function* changeUserPlan(action) {
  // let deleteParams = { ...action.payload };
  // delete deleteParams.subId;
  let updateUrl = ApiPath.dashboardApiPath.CHANGE_USER_PLAN;

  // if (action.payload.subId) {
  //   updateUrl += `/${action.payload.subId}`;
  // }
  // else{
  //   updateUrl +=`/:id`
  // }
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = updateUrl),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setChangeUserPlan(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getSubscriptions(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.GET_SUBSCRIPTION}`),
      action.payload
    );
    if (resp.status) {
      yield put(setGetSubscription(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getChangeSubscriptions(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.GET_CHANGE_SUBSCRIPTION}`),
      action.payload
    );
    if (resp.status) {
      yield put(setGetChangeSubscriptions(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* createSubscription(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.dashboardApiPath.CREATE_SUBSCRIPTION),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      toast.success(action.res.data.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* deleteSubscription(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.dashboardApiPath.DELETE_SUBSCRIPTION}/${action.payload.id}`)
    );
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.msg);
  } catch (e) {
    yield put(setDeleteSubscription({}));
  }
}

function* updateSubscription(action) {
    let deleteParams = { ...action.payload };
  delete deleteParams.id;
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.dashboardApiPath.UPDATE_SUBSCRIPTION}/${action.payload.id}`),
      (action.payload = deleteParams)
    );
    if (resp.status) {
      yield put(setUpdateSubscription(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateSubscription({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* subscriptionsSaga() {
  yield all([takeLatest("subscriptions/getSubscription", getSubscriptions)]);

  yield all([takeLatest("subscriptions/getChangeSubscriptions", getChangeSubscriptions)]);
  
  yield all([
    takeLatest("subscriptions/createSubscription", createSubscription),
  ]);
  yield all([
    takeLatest("subscriptions/deleteSubscription", deleteSubscription),
  ]);
  yield all([
    takeLatest("subscriptions/updateSubscription", updateSubscription),
  ]);
  yield all([takeLatest("subscriptions/changeUserPlan", changeUserPlan)]);
}

export default subscriptionsSaga;
