import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteSubscription } from "../../redux/slices/subscriptions";

const DeletePlan = (flag) => {
  const dispatch = useDispatch();

  //API for delete Publication...............
  const DeletePlan = (data) => {
    let params = {
      id: flag?.DeletId,
    };
    dispatch(
      deleteSubscription({
        ...params,
        cb(res) {
          if (res?.data) {
            flag?.getSubscription();
            flag?.close();
            toast.success("Subscription Deleted successfully!");
          }
        },
      })
    );
  };
  return (
    <>
      <div className="deleteModalsec text-center my-3">
        <button
          className="blockbtn btnYellow w-100"
          onClick={() => {
            DeletePlan();
          }}
        >
          Yes, Delete
        </button>
      </div>
    </>
  );
};

export default DeletePlan;
