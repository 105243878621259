import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteUserByadmin } from "../../redux/slices/dashboard";

const DeleteUserModal = (props) => {
  const { userId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleDeleteUser = () => {
    let params = {
      id: userId,
    };
    dispatch(
      deleteUserByadmin({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            toast.success("User deleted successfully!");
            navigate("/users");
          }
        },
      })
    );
  };
  return (
    <>
      <>
        <div className="deleteModalsec text-center my-3">
          <button
            className="blockbtn btnYellow w-100"
            onClick={(e) => handleDeleteUser(e)}
          >
            Yes, Delete
          </button>
        </div>
      </>
    </>
  );
};

export default DeleteUserModal;
