import { all, fork, spawn } from "redux-saga/effects";
import authSaga from "./auth";
import webSaga from "./dashboard";
import ticketSaga from "./ticket";
import helperSaga from "./helper";
import articleSaga from './article';
import notificationSaga from "./notification";
import subscriptionsSaga from "./subscriptions";
import socialLinksSaga from "./socialLinks";
import transactionSaga from "./transaction";

export default function* rootSaga() {
  yield all([spawn(authSaga), spawn(webSaga), spawn(ticketSaga), spawn(helperSaga), spawn(articleSaga) , spawn(notificationSaga),spawn(subscriptionsSaga),spawn(socialLinksSaga),spawn(transactionSaga) ]);
}
