import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Showdown from "showdown";
import { useArticleSelector } from "../../redux/selector/article";
import { getOneArticle, updateOneArticle } from "../../redux/slices/article";
import * as images from "../../utilities/images";
import ArticleMessageModal from "../Modal/articleMessageModal";
import CustomModal from "../Modal/CustomModal";
import ArticleMessage from "./ArticleMessage";
import RejectionReasonModal from "./RejectionReasonModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ArticleDetails = () => {
  const scrollChatBox = useRef(null);
  const articleSelector = useArticleSelector();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loading } = articleSelector;
  const [articleDetails, setArticleDetails] = useState("");
  console.log(articleDetails, "articleDetails");

  const [isShowMessageBox, setIsShowMessageBox] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [htmlContent, setHtmlContent] = useState("");
  const converter = new Showdown.Converter();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const convertMarkdownToHtml = (text) => {
    const convertedHtml = converter.makeHtml(text);
    setHtmlContent(convertedHtml);
  };

  useEffect(() => {
    if (articleDetails?.description) {
      convertMarkdownToHtml(articleDetails?.description);
    }
  }, [articleDetails?.description]);

  /**
   * modal close function
   */

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  /**
   * Article details api calling
   */

  const getOneArticleDetail = () => {
    let params = {
      id: id,
    };
    dispatch(
      getOneArticle({
        ...params,
        cb(res) {
          if (res?.data) {
            setArticleDetails(res?.data?.data);
          }
        },
      })
    );
  };

  /**
   * status change api function
   * @param {*} status
   * @param {*} rejectReason
   */

  const handleStatusChange = (status, rejectReason) => {
    let params = {
      id: id,
      userId: articleDetails?.userId,
      status: status,
      rejectedReason: rejectReason || undefined,
    };
    dispatch(
      updateOneArticle({
        ...params,
        cb(res) {
          if (res?.data) {
            getOneArticleDetail();
          }
        },
      })
    );
  };

  const handleNavigation = () => {
    if (location.state?.flag === "userDetails") {
      navigate(`/user-detail/${articleDetails?.userId}`);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    getOneArticleDetail();
  }, [id]);

  useEffect(() => {
    if (scrollChatBox?.current && isShowMessageBox) {
      scrollChatBox?.current?.scrollIntoView({
        block: "end",
        inline: "end",
        behavior: "smooth",
      });
    }
  }, [isShowMessageBox]);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-right"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginRight: "10px 11px",
         
          }}
        ></i>
      </div>
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-left"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginLeft: "10px 11px",
         
          }}
        ></i>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
   
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <>
      <section className="content-wrap dashboardSection articleDetail_Section">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <Link to="/article"> */}
                <div className="userDeatilHead d-inline-flex align-items-center ">
                  <img
                    src={images.arrowMain}
                    alt="arrowMainimg"
                    className="img-fluid pointCursor"
                    onClick={handleNavigation}
                  />
                  <h1 className="mainHead32">
                    Article: #{articleDetails?.articleId}
                  </h1>
                </div>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="loaderOuter d-flex justify-content-center text-light">
            <div className="spinner-grow text-light" role="status"></div>
          </div>
        ) : (
          <>
            <div className="dashArticle pt-0">
              <div className="articleDetail_Fixed">
                <div className="innerHead">
                  <div className="innerHeadleft">
                    <h2 className="heading_22">{articleDetails?.title}</h2>
                  </div>
                  <div className="headRightbox_">
                    <h4 className="categoryText fw-400">
                      {moment(articleDetails?.createdAt).format("MM/DD/YYYY")}
                    </h4>
                    <button
                      type="button"
                      className={
                        articleDetails?.status === "under_review"
                          ? "pendingTkt"
                          : articleDetails?.status === "approved"
                          ? "completeTkt"
                          : articleDetails?.status === "rejected"
                          ? "rejectedTkt"
                          : "completeTkt"
                      }
                    >
                      {articleDetails?.status === "under_review"
                        ? "Pending"
                        : articleDetails?.status}
                    </button>
                  </div>
                </div>
                {/* <div className="articleSubcategory_ mt-3">
                <h4 className="categoryText fw-400">
                  Article Genre:{" "}
                  <strong className="textWhite fw-500">
                    {" "}
                    {articleDetails?.categoryName}
                  </strong>
                </h4>
                <h4 className="categoryText fw-400">
                  Type:{" "}
                  <strong className="textWhite fw-500">
                    {" "}
                    {articleDetails?.subCategoryName}
                  </strong>
                </h4>
              </div> */}

                <div className="articlePublication_ mt-3">
                  <h5 className="heading_22">Publication:</h5>
                  <div className="publicationMedia_">
                    {articleDetails?.publications?.map((value, idx) => (
                      <div
                        className="mediaInner_ pointCursor"
                        onClick={() =>
                          navigate("/publicationsDetails", {
                            state: { publicationId: value?.publicationId },
                          })
                        }
                      >
                        <img
                          src={value?.publicationLogo}
                          className="img-fluid me-2 articlePublish"
                          alt="media_image"
                        />
                        <span className="form_subheading">
                          {value?.publicationName}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                {articleDetails?.url && (
                  <div className="articlePublication_ article_Publication_Resp mt-3">
                    <h5 className="heading_22">Url:</h5>
                    <div className="mediaInner_ d-flex align-items-center">
                      {/* <Link target="_blank" > */}
                      <a
                        href={articleDetails?.url}
                        target="_blank"
                        className="form_subheading"
                      >
                        {articleDetails?.url}
                      </a>
                      {/* </Link> */}
                    </div>
                  </div>
                )}
              </div>

              <div className="articleImage text-center">
                {articleDetails?.photos?.length > 0 && (
                  <img
                    src={articleDetails?.photos[0]}
                    className="img-fluid articleCover_Img"
                    alt="media_image"
                  />
                )}
                {articleDetails?.creditImage && (
                  <>
                    <p className="form_subheading mt-2 text-center">
                      {articleDetails?.creditImage}
                    </p>
                  </>
                )}
              </div>

              <div className="textWhite mt-3 underimage_">
                <div
                  className="app text-center"
                  dangerouslySetInnerHTML={{
                    __html: htmlContent,
                  }}
                />
              </div>

              {/* <div className="row">
                {articleDetails?.multipleImages?.length > 0 &&
                  articleDetails?.multipleImages?.map((item) => (
                    <div className="col-md-4">
                      <div className="mt-3">
                        <img
                          src={item}
                          className="img-fluid articleCover_Img articleMultiple_Img"
                          alt="media_image"
                        />
                      </div>
                    </div>
                  ))}
                   </div> */}
              <div className="row mt-4 ">
                <div className="slider-container">
                  {articleDetails?.multipleImages?.length > 1 ? (
                    <Slider {...settings}>
                      {articleDetails?.multipleImages?.map((item, index) => (
                        <div className="p-2">
                          {console.log(item,"itemsssss")}
                          <a
                            key={index}
                            href={item}
                            className="marquee_Links"
                            target="_blank"
                          >
                            <div className="clientLogoOuter">
                              <img
                                src={item}
                                alt="slider_img"
                                className="img-fluid w-100 h-100 home_slider_img"
                              />
                            </div>
                          </a>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <>
                      {articleDetails?.multipleImages?.map((item) => (
                        <div className="col-md-4">
                          <div className="mt-3">
                            <img
                              src={item}
                              className="img-fluid articleCover_Img"
                              alt="media_image"
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              {articleDetails?.googleDriveUrl && (
                <div className="articlePublication_ article_Publication_Resp mt-3">
                  <h5 className="heading_22">Google Drive Url :-</h5>
                  <div className="mediaInner_ d-flex align-items-center">
                    <a
                      className="form_subheading"
                      href={articleDetails?.googleDriveUrl}
                      target="_blank"
                    >
                      {articleDetails?.googleDriveUrl}
                    </a>
                  </div>
                </div>
              )}

              <div className="contentPreview_ mt-3">
                <h2 className="userNameText fw-500 text-capitalize">
                  Posted By:
                </h2>
                <div className="userDetailInfo">
                  <div
                    className="userDetailLeft pointCursor"
                    onClick={() =>
                      navigate(`/user-detail/${articleDetails?.userId}`)
                    }
                  >
                    <img
                      src={
                        articleDetails?.Users?.profilePhoto ||
                        images.profileMain
                      }
                      alt="arrowMainimg"
                      className="img-fluid profileMainImg"
                    />
                    <div className="userDeatilInner">
                      <h2 className="userNameText text-capitalize">
                        {`${articleDetails?.Users?.firstName} ${articleDetails?.Users?.lastName}`}
                      </h2>
                      <h6 className="userLabelText mt-3">
                        Email:{" "}
                        <span className="labelBold">
                          {articleDetails?.Users?.email}
                        </span>
                      </h6>
                      <h6 className="userLabelText mt-2">
                        Phone Number:{" "}
                        <span className="labelBold">
                          +{articleDetails?.Users?.dialcode}{" "}
                          {articleDetails?.Users?.phoneNo}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>

                {articleDetails?.status == "under_review" ? (
                  <>
                    <button
                      type="button"
                      className="blockbtn  me-3 mt-4"
                      disabled={loading}
                      onClick={() => {
                        handleStatusChange("approved");
                      }}
                    >
                      Approve
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-1"></span>
                      )}
                    </button>
                    <button
                      type="button"
                      className="secondaryBtb_ me-3 mt-4 "
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "rejectModal",
                        });
                        setKey(Math.random());
                      }}
                    >
                      Reject
                    </button>
                    {/* <button
                      type="button"
                      className="blockbtn mt-4"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "messageModal",
                        });
                        setKey(Math.random());
                      }}
                    >
                      message
                    </button> */}
                  </>
                ) : articleDetails?.status === "approved" ? (
                  ""
                ) : (
                  <button type="button" className="blockbtn me-3 mt-4" disabled>
                    {articleDetails?.status === "under_review"
                      ? "Pending"
                      : articleDetails?.status}
                  </button>
                )}
                <button
                  type="button"
                  className="blockbtn mt-4"
                  onClick={() => {
                    setIsShowMessageBox(true);
                  }}
                >
                  message
                </button>
              </div>
            </div>
          </>
        )}
        <div
          ref={scrollChatBox}
          className={isShowMessageBox ? "d-block" : "d-none"}
        >
          <ArticleMessage articleDetails={articleDetails} />
        </div>
      </section>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createsubcategory" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "messageModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "rejectModal" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "messageModal"
            ? "subCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "rejectModal" ? (
            <RejectionReasonModal
              close={() => handleOnCloseModal()}
              handleStatusChange={(e, reject) => handleStatusChange(e, reject)}
              loading={loading}
            />
          ) : modalDetail.flag === "messageModal" ? (
            <div className="messageModalContainer">
              <ArticleMessageModal
                close={() => handleOnCloseModal()}
                articleDetails={articleDetails}
                // handleStatusChange={(e, reject) => handleStatusChange(e, reject)}
                loading={loading}
              />
            </div>
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "rejectModal" ? (
            <>
              <h2 className="modal_Heading">Rejection Reason</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCross}
                  className="ModalCancel img-fluid"
                  alt=""
                />
              </p>
            </>
          ) : modalDetail.flag === "messageModal" ? (
            <>
              <h2 className="modal_Heading">Messages</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCross}
                  className="ModalCancel img-fluid"
                  alt=""
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ArticleDetails;
