import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
import { articleCategory } from "../../redux/slices/dashboard";
import * as images from "../../utilities/images";
import { DeleteIcon, editPen } from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import DeleteCategoryModal from "../Modal/DeleteCategoryModal";
import SubCategoryModal from "../Modal/subCategoryModal";
import UpdateCategoryModal from "../Modal/updateCategoryModal";

const CategorySub = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const caregorySelector = UseDashBoardSelector();
  const { state } = location;
  const [subcategory, setSubCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [key, setKey] = useState(Math.random());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //on change page pagination
  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected + 1);
  };

  const handleOpenModal = (flag, data) => {
    setSelectedSubcategory(data);
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  //API for get all sub category...............
  const getSubCategory = () => {
    let params = {
      page: currentPage,
      limit: 10,
      categoryId: id ? id : "",
    };
    dispatch(
      articleCategory({
        ...params,
        cb(res) {
          if (res?.data) {
            setSubCategory(res?.data?.data?.data);
            setTotalPage(res?.data?.data?.total_pages);
          }
        },
      })
    );
  };

  useEffect(() => {
    getSubCategory();
  }, [currentPage]);

  return (
    <>
      <div className="content-wrap categories pb-5">
        <div className="content-header">
          <div className="categories_group justify-content-between p-0">
            <div className="userDeatilHead">
              <img
                src={images.arrowMain}
                alt="arrowMainimg"
                className="img-fluid pointCursor"
                onClick={() => navigate("/article-categories")}
              />
              <h1 className="mainHead32">{state?.selectedParentCategory}</h1>
            </div>
            <button
              className="blockbtn"
              onClick={() => {
                handleOpenModal("createsubcategory");
              }}
            >
              Add New Type
            </button>
          </div>
        </div>
        <div className="table-responsive recentTable mt-4 categoryTable">
          <table class="table table-dark m-0">
            <thead>
              <tr className="recentRow">
                <th scope="col" className="recentHead">
                  SR. NO.
                </th>
                <th scope="col" className="recentHead">
                Type
                </th>
                <th scope="col" className="recentHead text-start">
                  ACTION
                </th>
              </tr>
            </thead>
            {caregorySelector?.loading ? (
              <>
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <>
                <tbody>
                  {subcategory?.length > 0 ? (
                    subcategory?.map((data, index) => {
                      return (
                        <tr>
                          <td className="recentSubHead">
                            <h4 className="tableSubText">{index + 1}</h4>
                          </td>
                          <td className="recentSubHead">
                            <h4 className="tableSubText">{data?.name}</h4>
                          </td>

                          <td className="recentSubHead text-center">
                            <div className="categoryOuterBtn">
                              <button
                                className="editBtn"
                                type="button"
                                onClick={() => {
                                  handleOpenModal("updateCategoryModal", data);
                                }}
                              >
                                <img
                                  src={editPen}
                                  className="img-fluid "
                                  alt="editPen image"
                                />
                                Edit
                              </button>
                              <button
                                className="delete_btn"
                                type="button"
                                onClick={() =>
                                  handleOpenModal("deleteCategoryModal", data)
                                }
                              >
                                <img
                                  src={DeleteIcon}
                                  className="img-fluid "
                                  alt="eyeImg"
                                />
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3} className="noFoundHead">
                        <h3 className="text-center subhead24  m-0">
                          No Type Found!
                        </h3>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </table>
          {subcategory && subcategory?.length > 0 && (
            <div className=" paginateSec">
              <ReactPaginate
                className="paginationBox mb-0"
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="< "
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createsubcategory" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "deleteCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "createsubcategory" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "deleteCategoryModal"
            ? "subCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "createsubcategory" ? (
            <SubCategoryModal
              masterCategoryId={id}
              flag={"updateSubCate"}
              close={() => {
                handleOnCloseModal();
              }}
              getSubCategory={() => getSubCategory()}
            />
          ) : modalDetail.flag === "updateCategoryModal" ? (
            <UpdateCategoryModal
              selectedParentCategory={selectedSubcategory}
              getAllCategories={() => getSubCategory()}
              close={() => handleOnCloseModal()}
              flag={"updateSubCategory"}
            />
          ) : modalDetail.flag === "deleteCategoryModal" ? (
            <DeleteCategoryModal
              getAllCategories={() => getSubCategory()}
              selectedParentCategory={selectedSubcategory}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "createsubcategory" ? (
            <>
              <h2 className="modal_Heading">Add Type</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCross}
                  className="ModalCancel img-fluid"
                  alt=""
                />
              </p>
            </>
          ) : modalDetail.flag === "updateCategoryModal" ? (
            <>
              <h2 className="modal_Heading">Update Type</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "deleteCategoryModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to Delete?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default CategorySub;
