import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
import {
  getAllPublications,
  getCategories,
  getAllRegions,
} from "../../redux/slices/dashboard";
import * as images from "../../utilities/images";
import { DeleteIcon, editPen, eyeImg } from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import DeletePublishModal from "../Modal/deletePublishModal";
import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Slider from "rc-slider";
import PublicationGenreModal from "../Modal/PublicationGenreModal";

const Publications = () => {
  document.title = "Publications";
  const dispatch = useDispatch();
  const [deletId, setDeleteId] = useState("");
  const navigate = useNavigate();
  const caregorySelector = UseDashBoardSelector();
  const [publicationsList, setPublicationsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [key, setKey] = useState(Math.random());
  const [genrePublication, setGenrePublication] = useState([]);
  const [activeGenre, setActiveGenre] = useState([]);
  const [activeType, setActiveType] = useState([]);
  const [showMoreGenre, setShowMoreGenre] = useState(false);
  const [showMoreType, setShowMoreType] = useState(false);
  const [typePublication, setTypePublication] = useState([]);
  const [region, setRegion] = useState([]);
  const [allCategoryNames, setAllCategoryNames] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    publicationType: "free",
  });
  console.log(Number.isInteger(search), "searchsearch");

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleSuggestionClick = (suggestion) => {
    // Check if the search term matches the full name or email
    const Name = suggestion?.name;
    const subCategoryName = suggestion?.subCategoryName;
    const Da = suggestion?.da;
    const region = suggestion?.region;
    const websiteUrl = suggestion?.websiteUrl;
    const selectTime = suggestion?.selectTime;
    const niche = suggestion?.niche;
    const markup = suggestion?.markup;
    const amount = suggestion?.amount;
    const sponsored = suggestion?.sponsored;
    const indexed = suggestion?.indexed;
    const doFollow = suggestion?.doFollow;
    const rating = suggestion?.rating;

    // Set the search value based on what the user clicked
    if (Name.toLowerCase().includes(search.toLowerCase())) {
      setSearch(Name);
    } else if (subCategoryName?.toLowerCase().includes(search.toLowerCase())) {
      setSearch(subCategoryName);
    } else if (Da?.toString().includes(search)) {
      setSearch(Number(Da));
    } else if (region?.toString().includes(search)) {
      setSearch(region);
    } else if (websiteUrl?.toLowerCase().includes(search)) {
      setSearch(websiteUrl);
    } else if (selectTime?.toLowerCase().includes(search.toLowerCase())) {
      setSearch(selectTime);
    } else if (niche?.toLowerCase().includes(search)) {
      setSearch(niche);
    } else if (markup?.toString().includes(search)) {
      setSearch(markup);
    } else if (amount?.toString().includes(search)) {
      setSearch(amount);
    } else if (sponsored?.toLowerCase().includes(search)) {
      setSearch(sponsored);
    } else if (indexed?.toLowerCase().includes(search)) {
      setSearch(indexed);
    } else if (doFollow?.toLowerCase().includes(search)) {
      setSearch(doFollow);
    } else if (rating?.toString().includes(search)) {
      setSearch(rating);
    } 
    else {
      // Fallback to the name if no match was found
      setSearch(Name);
    }

    // Clear the suggestions after selection
    setFilteredSuggestions([]);
  };


  const suggestions = publicationsList || [];
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setCurrentPage(1);
    // Filter suggestions based on input
    const filtered = suggestions?.filter(
      (item) =>
        item?.name?.toLowerCase().includes(value.toLowerCase()) ||
        item?.subCategoryName?.toLowerCase().includes(value.toLowerCase()) ||
        (item?.da && item?.da?.toString().includes(value)) ||
        (item?.region && item?.region?.toString().includes(value).toString()) ||
        (item?.websiteUrl &&
          item?.websiteUrl?.toString().includes(value).toLowerCase()) ||
        (item?.selectTime && item?.selectTime?.toLowerCase().includes(value.toLowerCase())) ||
        (item?.niche &&
          item?.niche?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.markup && item?.markup?.toString().includes(value)) ||
        (item?.amount && item?.amount?.toString().includes(value)) ||
        (item?.sponsored &&
          item?.sponsored?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.indexed &&
          item?.indexed?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.doFollow &&
          item?.doFollow?.toLowerCase().includes(value).toLowerCase()) ||
        (item?.rating && item?.rating?.toString().includes(value)) 
    );
    setFilteredSuggestions(filtered);
  };
  console.log();

  //open modal
  const handleOpenModal = (flag, data) => {
    setDeleteId(data);
    setAllCategoryNames(data);
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //on change page pagination
  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected + 1);
  };

  // get all regions
  useEffect(() => {
    dispatch(
      getAllRegions({
        cb(res) {
          if (res?.status === 200) {
            handeRemoveDupRegions(res?.data?.data);
          }
        },
      })
    );
  }, []);

  //API for get all type category...............
  const getPublications = () => {
    let params = {
      page: currentPage,
      limit: 10,
      genre: activeGenre,
      type: activeType,
    };
    params = {
      ...params,
      ...selectedFilters,
      search: search||undefined,
    };
    dispatch(
      getAllPublications({
        ...params,
        cb(res) {
          if (res?.data) {
            setPublicationsList(res?.data?.data?.data);
            setTotalPage(res?.data?.data?.total_pages);
          }
        },
      })
    );
  };

  useEffect(() => {
    getPublications();
  }, [currentPage, selectedFilters, activeGenre, activeType, search]);

  // get all type category or subcategory
  useEffect(() => {
    getAllCategories();
  }, []);

  // get all type category or subcategory
  const getAllCategories = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res.status) {
            setGenrePublication(
              res?.data?.data?.data.filter((item) => item.flag === "genre")
            );
            setTypePublication(
              res?.data?.data?.data.filter((item) => item.flag === "type")
            );
          }
        },
      })
    );
  };

  // active genre or publication
  const handleActiveGenreType = (flag, id) => {
    if (flag === "genre") {
      setActiveGenre((pre) => {
        if (pre.includes(id)) {
          return pre.filter((value) => value !== id);
        } else {
          return [...pre, id];
        }
      });
    } else {
      setActiveType((pre) => {
        if (pre.includes(id)) {
          return pre.filter((value) => value !== id);
        } else {
          return [...pre, id];
        }
      });
    }
  };

  // remove duplicate regions
  const handeRemoveDupRegions = (data) => {
    const allRegion = data?.map(
      (value) => value?.slice(0, 1)?.toUpperCase() + value?.slice(1)
    );
    setRegion([...new Set(allRegion)]);
  };

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // select filters
  const handleSelectFilters = (name, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (prevFilters[name] === value) {
        // If the value is already selected, remove the field from the object
        delete updatedFilters[name];
      } else {
        // Otherwise, update or add the field with the new value
        updatedFilters[name] = value;
      }

      return updatedFilters;
    });
  };

  // reset all filters
  const handleResetAllFilters = () => {
    setSelectedFilters({
      publicationType: "free",
    });
    setActiveGenre([]);
    setActiveType([]);
  };

  return (
    <>
      <div className="content-wrap categories publicationsec">
        <div className="innerHeadFlex">
          <h1 className="mainHead32">Publications </h1>
          <button
            className="loginBtnCommon btnYellow add_btn m-0"
            onClick={() => {
              navigate("/addPublications");
            }}
          >
            Create New
          </button>
        </div>

        <div className="searchSection">
          <input
            class="form-control searchInput"
            type="text"
            placeholder="Search..."
            value={search || ""}
            // onChange={(e) => {
            //   handleSelectFilters("search", e.target.value);
            //   setCurrentPage(1);
            // }}
            onChange={handleSearchChange}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIcon"
          />
          {search && filteredSuggestions.length > 0 && (
            <ul className="autocomplete-list pointCursor">
              {filteredSuggestions.map((suggestion) => {
                const Name = suggestion?.name;
                const subCategoryName = suggestion?.subCategoryName;
                const Da = suggestion?.da;
                const region = suggestion?.region;
                const websiteUrl = suggestion?.websiteUrl;
                const selectTime = suggestion?.selectTime;
                const niche = suggestion?.niche;
                const markup = suggestion?.markup;
                const amount = suggestion?.amount;
                const sponsored = suggestion?.sponsored;
                const indexed = suggestion?.indexed;
                const doFollow = suggestion?.doFollow;
                const rating = suggestion?.rating;
                // Determine whether to show the name or email based on the search input
                const searchValue = search.toLowerCase();
                const displayValue = Name.toLowerCase().includes(searchValue)
                  ? Name
                  : subCategoryName &&
                    subCategoryName.toLowerCase().includes(searchValue)
                  ? subCategoryName
                  : Da && Da?.toString().includes(searchValue)
                  ? Da
                  : region && region?.toString().includes(searchValue)
                  ? region
                  : websiteUrl &&
                    websiteUrl?.toLowerCase().includes(searchValue)
                  ? websiteUrl
                  : selectTime && selectTime?.toLowerCase().includes(searchValue)
                  ? selectTime
                  : niche && niche?.toLowerCase().includes(searchValue)
                  ? niche
                  : markup && markup?.toString().includes(searchValue)
                  ? markup
                  : amount && amount?.toString().includes(searchValue)
                  ? amount
                  : sponsored && sponsored?.toLowerCase().includes(searchValue)
                  ? sponsored
                  : indexed && indexed?.toLowerCase().includes(searchValue)
                  ? indexed
                  : doFollow && doFollow?.toLowerCase().includes(searchValue)
                  ? doFollow
                  : rating && rating?.toString().includes(searchValue)
                  ? rating
                  : Name; // Default to full name if neither matches
                return (
                  <li
                    key={suggestion._id}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="autocomplete-item"
                  >
                    {displayValue}
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className="categoryBox">
          <div className="row">
            {selectedFilters?.publicationType === "premium" && (
              <div className="col-lg-6 col-md-6 col-12">
                <h4 className="categoryText">Sort By</h4>
                <FormControl>
                  <Select
                    className="customSelect"
                    value={selectedFilters.sortBy || ""}
                    onChange={(e) => {
                      handleSelectFilters("sortBy", e.target.value);
                    }}
                    label="Category"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      fontFamily: "Poppins",
                      ".MuiSvgIcon-root": {
                        color: "#fff",
                      },
                      fontStyle: "normal",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px",
                          border: "1px solid #FFFFFF4D",
                          background: "#616161",
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginTop: "5px",
                          " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                            {
                              color: "#ffffff",
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: "inherit",
                              marginTop: "3px",
                              "&.Mui-selected": {
                                backgroundColor: "#757575",
                              },
                              "&:hover": {
                                backgroundColor: "#757575",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "#757575",
                              },
                            },
                        },
                      },
                    }}
                  >
                    <MenuItem disabled value={""}>
                      Price (Asc)
                    </MenuItem>
                    <MenuItem value={"asc"}>{"Low To High"}</MenuItem>
                    <MenuItem value={"desc"}>{"High To Low"}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            <div
              className={
                selectedFilters?.publicationType === "premium"
                  ? "col-lg-6 col-md-6 col-12"
                  : "col-12"
              }
            >
              <h4 className="categoryText mb-2">Select Region</h4>
              <FormControl>
                <Select
                  className="customSelect"
                  value={selectedFilters.region || ""}
                  onChange={(e) => {
                    handleSelectFilters("region", e.target.value);
                  }}
                  label="Category"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    fontFamily: "Poppins",
                    ".MuiSvgIcon-root": {
                      color: "#fff",
                    },
                    fontStyle: "normal",
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "12px",
                        border: "1px solid #FFFFFF4D",
                        background: "#616161",
                        color: "#ffffff",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "5px",
                        " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "500",
                          fontFamily: "inherit",
                          marginTop: "3px",
                          "&.Mui-selected": {
                            backgroundColor: "#757575",
                          },
                          "&:hover": {
                            backgroundColor: "#757575",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#757575",
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem disabled value={""}>
                    Select Region
                  </MenuItem>
                  {region?.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* {selectedFilters?.publicationType === "premium" && (
              <div className="categoryBox">
                <div className="col-12">
                  <h4 className="categoryText">Price Range</h4>
                  <Slider
                    min={0}
                    max={10000}
                    className="custom-slider"
                    value={selectedFilters?.amount || 0}
                    onChange={(value) => handleSelectFilters("amount", value)}
                  />
                  <div className="range-prices">
                    <span className="min-range-price">
                      {USDollar.format(selectedFilters?.amount || 0)}
                    </span>
                    <span className="max-range-price">
                      {USDollar.format(10000)}
                    </span>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>

        <div className="categoryBox">
          <div className="flexBox">
            <h4 className="categoryText">Filter by Genre</h4>
            {activeGenre && activeGenre?.length > 0 && (
              <button
                onClick={() => setActiveGenre([])}
                type="button"
                className="clearBtn"
              >
                {" "}
                Clear All
              </button>
            )}
          </div>

          <div className="categoryOuterBox">
            {genrePublication
              ?.slice(0, showMoreGenre ? genrePublication?.length : 11)
              ?.map((val, index) => {
                return (
                  <div
                    className={`${
                      activeGenre?.includes(val?._id)
                        ? "categoryBx active"
                        : "categoryBx"
                    }`}
                    key={index}
                    onClick={() => {
                      handleActiveGenreType("genre", val?._id);
                    }}
                  >
                    {val?.name}
                  </div>
                );
              })}
            {genrePublication && genrePublication.length > 11 && (
              <button
                type="button"
                className="clearBtn"
                onClick={() => setShowMoreGenre((pre) => !pre)}
              >
                {showMoreGenre ? "View Less" : "View All ..."}
              </button>
            )}
          </div>
        </div>

        <div className="categoryBox">
          <div className="flexBox">
            <h4 className="categoryText">Filter by Type</h4>
            {activeType && activeType?.length > 0 && (
              <button
                onClick={() => setActiveType([])}
                type="button"
                className="clearBtn"
              >
                {" "}
                Clear All
              </button>
            )}
          </div>

          <div className="categoryOuterBox">
            {typePublication
              ?.slice(0, showMoreType ? typePublication?.length : 11)
              ?.map((val, index) => {
                return (
                  <div
                    className={`${
                      activeType?.includes(val?._id)
                        ? "categoryBx active"
                        : "categoryBx"
                    }`}
                    key={index}
                    onClick={() => {
                      handleActiveGenreType("type", val?._id);
                    }}
                  >
                    {val?.name}
                  </div>
                );
              })}
            {typePublication && typePublication?.length > 11 && (
              <button
                type="button"
                className="clearBtn"
                onClick={() => setShowMoreType((pre) => !pre)}
              >
                {showMoreType ? "View Less" : "View All ..."}
              </button>
            )}
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Image</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("image", "yes")}
              className={
                selectedFilters?.image === "yes"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Yes
            </div>
            <div
              onClick={() => handleSelectFilters("image", "maybe")}
              className={
                selectedFilters?.image === "maybe"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Maybe
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Do Follow</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("doFollow", "yes")}
              className={
                selectedFilters?.doFollow === "yes"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Yes
            </div>
            <div
              onClick={() => handleSelectFilters("doFollow", "no")}
              className={
                selectedFilters?.doFollow === "no"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              No
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Indexed</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("indexed", "yes")}
              className={
                selectedFilters?.indexed === "yes"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Yes
            </div>
            <div
              onClick={() => handleSelectFilters("indexed", "maybe")}
              className={
                selectedFilters?.indexed === "maybe"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Maybe
            </div>
            <div
              onClick={() => handleSelectFilters("indexed", "no")}
              className={
                selectedFilters?.indexed === "no"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              No
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Filter by Sponsered</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("sponsored", "yes")}
              className={
                selectedFilters?.sponsored === "yes"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Yes
            </div>
            <div
              onClick={() => handleSelectFilters("sponsored", "no")}
              className={
                selectedFilters?.sponsored === "no"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              No
            </div>
            <div
              onClick={() => handleSelectFilters("sponsored", "discrete")}
              className={
                selectedFilters?.sponsored === "discrete"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Discrete
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <h4 className="categoryText">Niche</h4>
          <div className="categoryOuterBox">
            <div
              onClick={() => handleSelectFilters("niche", "erotic")}
              className={
                selectedFilters?.niche === "erotic"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Erotic
            </div>
            <div
              onClick={() => handleSelectFilters("niche", "health")}
              className={
                selectedFilters?.niche === "health"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Health
            </div>
            <div
              onClick={() => handleSelectFilters("niche", "crypto")}
              className={
                selectedFilters?.niche === "crypto"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Crypto
            </div>
            <div
              onClick={() => handleSelectFilters("niche", "cbd")}
              className={
                selectedFilters?.niche === "cbd"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Cbd
            </div>
            <div
              onClick={() => handleSelectFilters("niche", "gambling")}
              className={
                selectedFilters?.niche === "gambling"
                  ? "categoryBx active"
                  : "categoryBx"
              }
            >
              Gambling
            </div>
          </div>
        </div>

        <div className="categoryBox">
          <button
            onClick={handleResetAllFilters}
            type="button"
            className="clearBtn"
          >
            Reset All Filters
          </button>
        </div>

        <div className="priceInput mt-3 mb-3 publication_Premium">
          <div>
            <input
              type="radio"
              id="Free"
              name="radio-group"
              checked={selectedFilters?.publicationType === "free"}
              onClick={() => {
                setCurrentPage(1);
                handleSelectFilters("publicationType", "free");
              }}
            />
            <label htmlFor="Free" className="premiumLabel free text-white">
              Free
            </label>
          </div>
          <div className="premiumLabel_Parent">
            <input
              type="radio"
              id="Premium"
              name="radio-group"
              checked={selectedFilters?.publicationType === "premium"}
              onClick={() => {
                handleSelectFilters("publicationType", "premium");
                setCurrentPage(1);
              }}
            />
            <label htmlFor="Premium" className="premiumLabel text-white">
              Premium
            </label>
          </div>
        </div>

        <div className="table-responsive recentTable publishTable">
          <table class="table table-dark m-0">
            <thead>
              <tr className="recentRow">
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  NAME
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Genre
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Type
                </th>

                {selectedFilters?.publicationType === "premium" && (
                  <>
                    <th
                      scope="col"
                      className="recentHead publishTable_content_wie recentTable_Head"
                    >
                      PRICE FOR PREMIUM
                    </th>
                    <th
                      scope="col"
                      className="recentHead publishTable_content_wie recentTable_Head"
                    >
                      MARKUP
                    </th>
                  </>
                )}

                {/* <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  PRICE FOR PREMIUM
                </th> */}
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  DA
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  Region
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  Website URL
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie recentTable_Head"
                >
                  TIME
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  SPONSORED
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  INDEXED
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  NICHE
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  DO FOLLOW
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  IMAGE
                </th>
                <th
                  scope="col"
                  className="recentHead publishTable_content_wie "
                >
                  RATING
                </th>
                <th
                  scope="col"
                  className="recentHead text-start publicationTable_Action"
                >
                  ACTION
                </th>
              </tr>
            </thead>

            {caregorySelector?.loading ? (
              <>
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <tbody>
                {publicationsList?.length > 0 ? (
                  publicationsList.map((data, idx) => {
                    return (
                      <tr>
                        <td
                          className="recentSubHead table_UserName"
                          key={idx}
                          onClick={() =>
                            navigate("/publicationsDetails", {
                              state: { publicationId: data?._id },
                            })
                          }
                        >
                          <div className="publishInfo">
                            <figure className="publishOutLogo">
                              <img
                                src={data?.publicationLogo}
                                alt="publication Logo"
                                className="img-fluid publishImg"
                              />
                            </figure>
                            <h4 className="tableSubText">{data?.name}</h4>
                          </div>
                        </td>
                        <td className="recentSubHead align-middle">
                          {data?.categoryIds?.length === 1 ? (
                            <h4 className="tableSubText">
                              {data?.categoryIds[0]?.categoryName || "---"}
                            </h4>
                          ) : (
                            <p className="tableSubText">
                              {`${data?.categoryIds[0]?.categoryName}`}
                              &nbsp;&nbsp;
                              <span
                                onClick={() => {
                                  handleOpenModal(
                                    "publicationGenreModal",
                                    data?.categoryIds
                                  );
                                }}
                                className="view-all-genres pointCursor"
                              >
                                +{data?.categoryIds?.length - 1}
                              </span>
                            </p>
                          )}
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">
                            {data?.subCategoryName || "---"}
                          </h4>
                        </td>
                        {selectedFilters?.publicationType === "premium" && (
                          <>
                            <td className="recentSubHead align-middle">
                              <h4 className="tableSubText">${data?.amount}</h4>
                            </td>
                            <td className="recentSubHead align-middle">
                              <h4 className="tableSubText">
                                {data?.markup ? `$${data.markup}` : "----"}
                              </h4>
                            </td>
                          </>
                        )}
                        {/* <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">
                            ${data?.price?.premium}
                          </h4>
                        </td> */}
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">{data?.da}</h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">
                            {data?.region || "---"}
                          </h4>
                        </td>

                        <td className="recentSubHead align-middle">
                          <a
                            className="form_subheading"
                            href={data?.websiteUrl}
                            target="_blank"
                          >
                            {data?.websiteUrl
                              ? data?.websiteUrl?.length <= 20
                                ? data?.websiteUrl
                                : `${data?.websiteUrl?.slice(0, 20)}...`
                              : "---"}
                          </a>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText">{data?.selectTime}</h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText text-capitalize">
                            {data?.sponsored}
                          </h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText text-capitalize">
                            {data?.indexed}
                          </h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText text-capitalize">
                            {data?.niche}
                          </h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText text-capitalize">
                            {data?.doFollow}
                          </h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <h4 className="tableSubText text-capitalize">
                            {data?.image}
                          </h4>
                        </td>
                        <td className="recentSubHead align-middle">
                          <div className="d-flex align-items-center">
                            <img
                              src={images.starImg}
                              alt="starImage"
                              className="img-fluid me-1"
                            />
                            <h4 className="tableSubText">{data?.rating}</h4>
                          </div>
                        </td>

                        <td className="recentSubHead text-center">
                          <div className="categoryOuterBtn">
                            <button
                              className="editBtn"
                              type="button"
                              onClick={() =>
                                navigate("/publicationsDetails", {
                                  state: { publicationId: data?._id },
                                })
                              }
                            >
                              <img
                                src={eyeImg}
                                className="img-fluid "
                                alt="editPen image"
                              />
                              View
                            </button>

                            <button
                              className="editBtn"
                              type="button"
                              onClick={() => {
                                navigate("/editPublications", {
                                  state: {
                                    type: "editPublish",
                                    publishList: { data },
                                  },
                                });
                              }}
                            >
                              <img
                                src={editPen}
                                className="img-fluid "
                                alt="editPen image"
                              />
                              Edit
                            </button>
                            <button
                              className="delete_btn"
                              type="button"
                              onClick={() => {
                                handleOpenModal("deletePublish", data?._id);
                              }}
                            >
                              <img
                                src={DeleteIcon}
                                className="img-fluid "
                                alt="eyeImg"
                              />
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={14} className="noFoundHead">
                      <h3 className="text-center subhead24 m-0">
                        No Publication Found
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>

        {publicationsList && publicationsList?.length > 0 && (
          <div className=" paginateSec">
            <ReactPaginate
              className="paginationBox mb-0"
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalPage}
              previousLabel="< "
              renderOnZeroPageCount={null}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "deletePublish"
            ? "deletCategoryModal"
            : modalDetail.flag === "publicationGenreModal"
            ? "deletCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "deletePublish" ? (
            <DeletePublishModal
              close={() => handleOnCloseModal()}
              deletId={deletId}
              getPublications={() => getPublications()}
            />
          ) : modalDetail.flag === "publicationGenreModal" ? (
            <PublicationGenreModal
              allCategoryNames={allCategoryNames}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "deletePublish" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to delete?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "publicationGenreModal" ? (
            <>
              <h2 className="modal_Heading">Publication Genres</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};
export default Publications;
