import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import { onErrorStopLoad, resetPassword } from "../../redux/slices/auth";
import logo from "../Images/logo.png";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const authData = useAuthSelector();
  const { loading } = authData;
  const toastId = useRef(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.password) {
      if (!toast.isActive(toastId.current)) {
        showToast("Please enter your password");
        return false;
      }
    } else if (!formData.confirmpassword) {
      showToast("Please enter your confirm password");
      return;
    } else if (formData.password !== formData.confirmpassword) {
      showToast("New password or confirm password should be same");
      return;
    }

    let params = {
      new_password: formData.password,
      confirm_password: formData.confirmpassword,
      user_id: state?.user_id,
    };
    dispatch(
      resetPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/");
          }
        },
      })
    );
  };

  // toggle password visibility
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // toggle password visibility
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(passwordConfirmShown ? false : true);
  };
  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // navigate to login page if user_id is not provided
  useEffect(() => {
    if (!state?.user_id) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="login-page authBg resetpwd">
        <div className="brandlogo">
          <img src={logo} alt="brandlogo" onClick={() => navigate("/")} />
        </div>
        <div className="card card-outline card-primary cardTopOutline cardBg p-0">
          <div className="loginPage">
            <div className="card-header text-center border-0 p-0">
              <h3 className="mb-0 logintext">Reset Password</h3>
              <p className="form_subheading mt-3">
                Create your new password here.{" "}
              </p>
            </div>
            <div className="card-body login-card-body p-0">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="input-container ">
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="password"
                    placeholder="Enter New Password"
                    value={formData.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      togglePasswordVisiblity(!passwordShown);
                    }}
                  >
                    <span
                      className={
                        passwordShown
                          ? "hide-icon togglePassword "
                          : "show-icon togglePassword"
                      }
                      id=""
                    ></span>
                  </span>
                </div>
                <div className="input-container mt-3">
                  <input
                    type={passwordConfirmShown ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="confirmpassword"
                    placeholder="Enter Confirm Password"
                    value={formData.confirmpassword}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      toggleConfirmPasswordVisiblity(!passwordConfirmShown);
                    }}
                  >
                    <span
                      className={
                        passwordConfirmShown
                          ? "hide-icon togglePassword "
                          : "show-icon togglePassword "
                      }
                      id=""
                    ></span>
                  </span>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      className="loginBtnCommon btnYellow mt-4 mw-100 loginbtn mb-0"
                      type="submit"
                      disabled={loading}
                    >
                      <span>Reset</span>
                      {loading && (
                        <span className="spinner-border spinner-border-sm ms-1"></span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
