import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTicketSelector } from "../../redux/selector/ticket";
import { getAllHelper } from "../../redux/slices/helper";
import * as images from "../../utilities/images";

const ContentPages = () => {
  document.title = "Content Pages";
  const dispatch = useDispatch();
  const ticketSelector = useTicketSelector();
  const { loading } = ticketSelector;
  const [helperList, setHelperList] = useState([]);
  const [search, setSearch] = useState("");

  // get all users
  useEffect(() => {
    let params = {
      search: search || undefined,
    };
    if (Boolean(search)) {
      params.search = search;
    }
    dispatch(
      getAllHelper({
        ...params,
        cb(res) {
          if (res?.data) {
            const truncatedData = res?.data?.data.map((item) => {
              let truncatedContent = "";
              if (typeof item.content === "string") {
                // Extract the text content of HTML and truncate it
                const textContent = item.content.replace(/<[^>]+>/g, "");
                truncatedContent =
                  textContent.slice(0, 70) +
                  (textContent.length > 70 ? "..." : "");
              } else {
                // If content is not a string, use it as is
                truncatedContent = item.content;
              }
              return { ...item, truncatedContent };
            });
            setHelperList(truncatedData);
          }
        },
      })
    );
  }, [search]);

  return (
    <>
      <div className="content-wrap pb-5 ">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="mainHead32">Content Pages</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="searchSection">
          <input
            class="form-control searchInput"
            type="text"
            placeholder="Search by name"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIcon"
          />
        </div>
        <div className="recentUserSec">
          <div className="table-responsive recentTable contentTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead">
                    Page Name
                  </th>
                  <th scope="col" className="recentHead">
                    Description
                  </th>
                  <th scope="col" className="recentHead text-center">
                    ACTION
                  </th>
                </tr>
              </thead>

              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="loaderOuter d-flex justify-content-center text-light">
                        <div
                          className="spinner-grow text-light"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <>
                  <tbody>
                    {helperList?.length > 0 ? (
                      helperList?.filter((item)=>item?.slug !="about_us")?.map((data, idx) => {
                        return (
                          <tr>
                            <td className="recentSubHead">
                              <h4 className="tableSubText">{idx + 1}</h4>
                            </td>
                            <td className="recentSubHead">
                              <h4 className="tableSubText">{data?.title}</h4>
                            </td>
                            <td className="recentSubHead">
                              <div className="tableSubText">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data.truncatedContent,
                                  }}
                                />
                                {/* {data.truncatedContent?.length >= 50 ? (
                                  <Link
                                    to={{
                                      pathname: `/content-view/${data?._id}`,
                                      state: {
                                        flag: "helper",
                                      },
                                    }}
                                    className="readBtn ms-2"
                                  >
                                    
                                  </Link>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </td>
                            <td className="recentSubHead text-center ">
                              <Link
                                to={{
                                  pathname: `/content-view/${data?._id}`,
                                  state: {
                                    flag: "helper",
                                  },
                                }}
                                className="d-flex justify-content-center"
                              >
                                <button className="editBtn" type="button">
                                  <img
                                    src={images.eyeImg}
                                    className="img-fluid"
                                    alt="editPen image"
                                  />
                                  View
                                </button>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4} className="noFoundHead">
                          <h3 className="text-center subhead24  m-0">
                            No Content Found!
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentPages;
