import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
import { getDashboardUsers } from "../../redux/slices/dashboard";
import * as images from "../../utilities/images";

const Dashboard = () => {
  document.title = "Home";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashBoardSelector = UseDashBoardSelector();
  const { loading } = dashBoardSelector;
  const [userList, setUserList] = useState([]);
  const [totalArticle, setTotalArticle] = useState("");
  const [totalUsers, setTotalUsers] = useState("");

  useEffect(() => {
    dispatch(
      //to get dashboard user data
      getDashboardUsers({
        cb(res) {
          if (res?.data) {
            setUserList(res?.data?.data?.users);
            setTotalArticle(res?.data?.data?.totalArticles);
            setTotalUsers(res?.data?.data?.totalUsers);
          }
        },
      })
    );
  }, []);

  return (
    <div className="content-wrap">
      <h1 className="mainHead32 my-4">Dashboard</h1>
      <div className="topuserCount_">
        <div className="row">
          <div className="col-md-6">
            <Link to={"/users"}>
              <div className="usercountbox_">
                <img className="countr_" src={images.counticon} alt="" />
                <div className="countRightbox_">
                  <h4 className="subhead24 dashboard_Card_Count">User Count</h4>
                  <h3 className="headinmain32">
                    {totalUsers ? (totalUsers > 500 ? "500+" : totalUsers) : "0"}
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to={"/article"}>
              <div className="usercountbox_">
                <img className="countr_" src={images.articleCount} alt="" />
                <div className="countRightbox_">
                  <h4 className="subhead24 dashboard_Card_Count">
                    Article Count
                  </h4>
                  <h3 className="headinmain32">
                    {totalArticle
                      ? totalArticle > 500
                        ? "500+"
                        : totalArticle
                      : "0"}{" "}
                  </h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="recentUserSec">
        <div className="flexDiv my-4">
          <h4 className="recentText m-0">Recent Users</h4>
          <Link to={"/users"}>
            <button type="button" className="seeBtn">
              See All
              <img
                src={images.rightArrow}
                className="img-fluid"
                alt="rightArrowImg"
              />
            </button>
          </Link>
        </div>
        <div className="table-responsive recentTable dashboardTable">
          <table className="table table-dark m-0 ">
            <thead>
              <tr className="recentRow">
                <th scope="col" className="recentHead ">
                  SR. NO.
                </th>
                <th scope="col" className="recentHead recentTable_Head">
                  NAME
                </th>
                <th scope="col" className="recentHead recentTable_Head">
                  EMAIL
                </th>
                <th scope="col" className="recentHead recentTable_Head">
                  PHONE NUMBER
                </th>
                <th scope="col" className="recentHead recentTable_Head">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center text-light">
                      <div
                        className="spinner-grow text-light"
                        role="status"
                      ></div>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {userList?.length > 0 ? (
                    userList?.map((data, idx) => {
                      return (
                        <tr >
                          <td className="recentSubHead">
                            <h4 className="tableSubText">{idx + 1}</h4>
                          </td>
                          <td className="recentSubHead subcribers_Users" onClick={() =>
                          navigate(`/user-detail/${data?._id}`)
                        }>
                            <div className="nameProfile">
                              <div className="profile-outer">
                                <img
                                  src={
                                    data?.userInfo?.profilePhoto
                                      ? data?.userInfo?.profilePhoto
                                      : images.dummyProfile
                                  }
                                  className="img-fluid profileImg_"
                                  alt="profileImg"
                                />
                              </div>

                              <h4 className="tableSubText">
                                {`${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`}
                              </h4>
                            </div>
                          </td>
                          <td className="recentSubHead">
                            <h4 className="tableSubText textTrans">
                              {data?.email}
                            </h4>
                          </td>
                          <td className="recentSubHead">
                            <h4 className="tableSubText">
                              +{data?.userInfo?.dialCode}{" "}
                              {data?.userInfo?.phoneNo}
                            </h4>
                          </td>
                          <td className="recentSubHead text-center">
                            <button
                              className="editBtn"
                              type="button"
                              onClick={() =>
                                navigate(`/user-detail/${data?._id}`)
                              }
                            >
                              <img
                                src={images.eyeImg}
                                className="img-fluid"
                                alt="editPen image"
                              />
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="noFoundHead">
                        <p className="text-center subhead24  m-0">
                          No Users Found!
                        </p>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
