import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createSubscription } from "react-redux/es/utils/Subscription";
import { toast } from "react-toastify";
import { updateSubscription } from "../../redux/slices/subscriptions";
import * as images from "../../utilities/images";
import { useSubscriptionsSelector } from "../../redux/selector/subscriptions";

const CreatePlanModal = (param) => {
  console.log(param?.editValues, "param?.editValues");
  const selectedFreePlanInfo = param?.editValues?.plans?.find(
    (item) => item?.planType === "Free"
  );
  const selectedMonthPlanInfo = param?.editValues?.plans?.find(
    (item) => item?.planType === "month"
  );
  const selectedYearPlanInfo = param?.editValues?.plans?.find(
    (item) => item?.planType === "year"
  );
  console.log(selectedFreePlanInfo, "selectedFreePlanInfo");

  const myInput = useRef();
  const subscriptionSelector = useSubscriptionsSelector();
  const { loading } = subscriptionSelector;
  const toastId = useRef(null);
  const [planChange, setPlanChange] = useState(false);
  const [markUp, setMarkUp] = useState(selectedYearPlanInfo?.markUp || "");
  const [planType, setPlanType] = useState(
    selectedYearPlanInfo?.planType ||
      selectedFreePlanInfo?.planType ||
      selectedFreePlanInfo?.planType
  );
  const [monthlyPrice, setMonthlyPrice] = useState(
    selectedMonthPlanInfo?.price
  );
  const [yearlyPrice, setYearlyPrice] = useState(selectedYearPlanInfo?.price);
  const [yearlyDiscount, setYearlyDiscount] = useState(
    selectedYearPlanInfo?.yearlyDiscount || ""
  );

  const [planName, setPlanName] = useState(
    selectedYearPlanInfo?.planName || selectedFreePlanInfo?.planName || ""
  );
  console.log(planName, "planName");

  const [temp, setTemp] = useState(0);
  const [monthlyMaximumArticle, setMonthlyMaximumArticle] = useState(
    selectedMonthPlanInfo?.maxArticlesPerMonth ||
      selectedFreePlanInfo?.maxArticlesPerMonth ||
      ""
  );
  const [yearlyMaximumArticle, setYearlyMaximumArticle] = useState(
    selectedYearPlanInfo?.maxArticlesPerMonth || ""
  );
  const dispatch = useDispatch();
  const [monthlyInputs, setMonthlyInputs] = useState(
    selectedMonthPlanInfo?.features ||
      selectedFreePlanInfo?.features || [{ id: 0, value: "" }]
  );
  const [yearlyInputs, setYearlyInputs] = useState(
    selectedYearPlanInfo?.features || [{ id: 0, value: "" }]
  );
  console.log(yearlyInputs, "yearlyInputs");

  const freePlan = {
    planName: planName,
    planType: "Free",
    price: 0,
    features: monthlyInputs,
    maxArticlesPerMonth: monthlyMaximumArticle,
    markUp: 0,
  };

  const monthlyPlan = {
    planName: planName,
    planType: "month",
    price: monthlyPrice,
    features: monthlyInputs,
    maxArticlesPerMonth: monthlyMaximumArticle,
    markUp: markUp,
  };

  const yearlyPlan = {
    planName: planName,
    planType: "year",
    // price: monthlyPrice * 12,
    features: yearlyInputs,
    maxArticlesPerMonth: yearlyMaximumArticle,
    markUp: markUp,
    yearlyDiscount: yearlyDiscount,
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  // Function to prevent special characters on key down
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberKey =
      (e.key >= "0" && e.key <= "9") || e.key === "." || e.key === "-";

    if (!allowedKeys.includes(e.key) && !isNumberKey) {
      e.preventDefault();
    }
  };

  const handleChange = (setter) => (e) => {
    const newValue = e.target.value;

    // Regex to match only non-negative numbers
    const validNumberRegex = /^(?:\d+|\d*\.\d*)$/;

    // Check if the value is a non-negative number or an empty string
    if (newValue === "" || validNumberRegex.test(newValue)) {
      setter(newValue);
      setPlanChange(true);
    }
  };

  // get the Feature names
  const handleInputChange = (index, event, flag) => {
    if (flag === "monthly") {
      const values = [...monthlyInputs];
      values[index] = event.target.value;
      setMonthlyInputs(values);
      setPlanChange(true);
    } else {
      const values = [...yearlyInputs];
      values[index] = event.target.value;
      setYearlyInputs(values);
      setPlanChange(true);
    }
  };

  // Function to add a new input field
  const handleAddInput = (flag) => {
    if (flag === "monthly") {
      const isEmptyField = monthlyInputs.some((input) => input.trim() === "");
      if (isEmptyField) {
        showToast("Please fill in all fields before adding a new one.");
        return;
      }

      const updatedInputs = [...monthlyInputs, ""];
      setMonthlyInputs(updatedInputs);
      setPlanChange(true);
      setTemp((prev) => prev + 1);
    } else {
      const isEmptyField = yearlyInputs.some((input) => input.trim() === "");
      if (isEmptyField) {
        showToast("Please fill in all fields before adding a new one.");
        return;
      }

      const updatedInputs = [...yearlyInputs, ""];
      setYearlyInputs(updatedInputs);
      setPlanChange(true);
      setTemp((prev) => prev + 1);
    }
  };

  // Function to remove an input field
  const handleRemoveInput = (index, flag) => {
    if (flag === "monthly") {
      if (monthlyInputs.length <= 1) {
        showToast("Atleast one features is required");
        return;
      }
      const values = [...monthlyInputs];
      values.splice(index, 1);
      setMonthlyInputs(values);
      setPlanChange(true);
    } else {
      if (yearlyInputs.length <= 1) {
        showToast("Atleast one features is required");
        return;
      }
      const values = [...yearlyInputs];
      values.splice(index, 1);
      setYearlyInputs(values);
      setPlanChange(true);
    }
  };

  const planTypeIsValid = (type) => {
    // Define valid plan types
    const validPlanTypes = ["month", "year", "Free"];
    // Check if the provided planType is in the validPlanTypes array
    return validPlanTypes.includes(type);
  };

  //create subscription
  const handleSubscription = () => {
    if (!planName) {
      showToast("Please enter Plan name");
      return;
    } else if (!monthlyPrice && planType !== "Free") {
      showToast("Please enter monthly price");
      return;
    } else if (!yearlyPrice && planType !== "Free") {
      showToast("Please enter yearly price");
      return;
    } else if (!markUp && planType != "Free") {
      showToast("Please enter markup");
      return;
    } else if (!yearlyDiscount && planType != "Free") {
      showToast("Please enter markup");
      return;
    } else if (!monthlyMaximumArticle) {
      showToast("Please enter maximum articles");
      return;
    } else if (monthlyInputs.some((input) => input?.trim() === "")) {
      showToast("Please add monthly feature");
      return;
    } else if (
      planType !== "Free" &&
      yearlyInputs.some((input) => input?.trim() === "")
    ) {
      showToast("Please add yearly feature");
      return;
    }
    if (param?.flag == "editPlan" && planChange) {
      let params = {};
      if (selectedFreePlanInfo?.planType === "Free") {
        params = { freePlan, id: selectedFreePlanInfo?._id };
      } else {
        params = {
          monthlyPlan,
          yearlyPlan,
          id: selectedMonthPlanInfo?._id,
        };
      }
      dispatch(
        updateSubscription({
          ...params,
          cb(res) {
            if (res.status === 200) {
              param?.close();
              param?.getSubscription();
              toast.success("Plan edited successfully");
            }
          },
        })
      );
    } else if (param?.flag == "editPlan" && !planChange) {
      param?.close();
    }
  };
  useEffect(() => {
    if (myInput.current) myInput.current.focus();
  }, [temp]);
  return (
    <>
      <div className="createPlanSec">
        <div className="planInner">
          <div>
            <label className="input_Label">Plan Name</label>
            <input
              class="form-control categoryInput"
              type="text"
              placeholder="Plan Name"
              value={planName}
              // disabled
              onChange={(e) => {
                setPlanName(e.target.value);
                setPlanChange(true);
              }}
            />
          </div>
          {selectedFreePlanInfo?.planType != "Free" && (
            <>
              <div className="mt-3">
                <label className="input_Label">
                  Monthly Price
                </label>
                <input
                  class="form-control categoryInput "
                  type="text"
                  placeholder="Monthly Price"
                  value={monthlyPrice}
                  onChange={(e) => {
                    setMonthlyPrice(e.target.value);
                    setPlanChange(true);
                  }}
                />
              </div>
              {/* <div className="mt-3">
                <label className="categoryText planModal_Lable">
                  Yearly Price:
                </label>
                <input
                  class="form-control categoryInput "
                  type="text"
                  placeholder="Yearly Price"
                  value={yearlyPrice}
                  onChange={(e) => {
                    setYearlyPrice(e.target.value);
                    setPlanChange(true);
                  }}
                />
              </div> */}
              <div className="mt-3">
                <label className="input_Label">
                  Yearly Discount
                </label>
                <input
                  class="form-control categoryInput "
                  type="text"
                  placeholder="Yearly Discount(in Percentage)"
                  value={yearlyDiscount}
                  onChange={(e) => {
                    // Allow only numbers and periods (for percentage)
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^0-9.]/g, "");

                    setYearlyDiscount(filteredValue);
                    setPlanChange(true);
                  }}
                />
              </div>
              <div className="mt-3">
                <label className="input_Label">
                  Add Markup
                </label>
                <input
                  class="form-control categoryInput "
                  type="text"
                  placeholder="Add Markup(in Percentage)"
                  value={markUp}
                  onChange={(e) => {
                    // Allow only numbers and periods (for percentage)
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^0-9.]/g, "");
                    setMarkUp(filteredValue);
                    setPlanChange(true);
                  }}
                />
              </div>
            </>
          )}

          <div>
            <label className="input_Label mb-0 mt-3">
              {selectedFreePlanInfo?.planType != "Free" && "Monthly"} Features:
            </label>
          </div>
          <div className="mt-3">
            <label className="input_Label">
              No. of articles
            </label>
            <input
              class="form-control categoryInput "
              type="number"
              placeholder="No. of articles"
              value={monthlyMaximumArticle}
              onChange={handleChange(setMonthlyMaximumArticle)}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className="mt-3">
            <label className="input_Label">
              Customer Feature
            </label>
            {monthlyInputs?.map((item, index, arr) => (
              <div key={index} className="inputFlex">
                <input
                  ref={myInput}
                  value={item}
                  maxLength={100}
                  onChange={(e) => handleInputChange(index, e, "monthly")}
                  className="form-control categoryInput"
                  type="text"
                  placeholder="customer feature"
                />

                {index + 1 === arr.length ? (
                  <button
                    className="crossBtnCategory"
                    onClick={() => handleRemoveInput(index, "monthly")}
                  >
                    <img
                      src={images.closeBtnImg}
                      className="img-fluid"
                      alt=""
                    />
                  </button>
                ) : (
                  <button
                    onClick={() => handleRemoveInput(index, "monthly")}
                    className="crossBtnCategory"
                  >
                    <img
                      src={images.closeBtnImg}
                      className="img-fluid"
                      alt=""
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
          <button
            className="addMoreBtn"
            onClick={() => handleAddInput("monthly")}
          >
            <img className="addMoreImg" src={images?.addMoreImg} alt="" />
            Add More
          </button>

          {selectedFreePlanInfo?.planType != "Free" && (
            <>
              <label className="categoryText mb-0 mt-1">Yearly Features:</label>
              <div className="mt-3">
                <label className="categoryText planModal_Lable">
                  No. of articles:
                </label>
                <input
                  class="form-control categoryInput "
                  type="number"
                  placeholder="No. of articles"
                  value={yearlyMaximumArticle}
                  onChange={handleChange(setYearlyMaximumArticle)}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="mt-3">
                {" "}
                <label className="categoryText planModal_Lable customerFeat_Field">
                  Customer Feature:
                </label>
                {yearlyInputs?.map((item, index, arr) => (
                  <div key={index} className="inputFlex">
                    <input
                      ref={myInput}
                      value={item}
                      maxLength={100}
                      onChange={(e) => handleInputChange(index, e)}
                      className="form-control categoryInput"
                      type="text"
                      placeholder="custome feature"
                    />
                    {index + 1 === arr.length ? (
                      <button
                        className="crossBtnCategory"
                        onClick={() => handleRemoveInput(index, "yearly")}
                      >
                        <img
                          src={images.closeBtnImg}
                          className="img-fluid"
                          alt=""
                        />
                      </button>
                    ) : (
                      <button
                        onClick={() => handleRemoveInput(index, "yearly")}
                        className="crossBtnCategory"
                      >
                        <img
                          src={images.closeBtnImg}
                          className="img-fluid"
                          alt=""
                        />
                      </button>
                    )}
                  </div>
                ))}
              </div>
              <button
                className="addMoreBtn"
                onClick={() => handleAddInput("yearly")}
              >
                <img className="addMoreImg" src={images?.addMoreImg} alt="" />
                Add More
              </button>
            </>
          )}
        </div>

        <button
          className="blockbtn btnYellow w-100 mt-4"
          onClick={() => {
            handleSubscription();
          }}
          disabled={loading}
        >
          {param?.flag == "editPlan" ? "Update Plan" : "Add Plan"}
          {loading && (
            <span className="spinner-border spinner-border-sm ms-1"></span>
          )}
        </button>
      </div>
    </>
  );
};

export default CreatePlanModal;
