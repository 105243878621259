import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
let messaging = null;

// Feature detection to check if the browser supports notifications and service workers
if ("Notification" in window && "serviceWorker" in navigator) {
  messaging = getMessaging(app);
}
const PARENTCOLLECTIONNAME = "chats";
const ARTICLEPARENTCOLLECTIONNAME = "articlechats";
const TICKETCOLLECTIONNAME = "tickets";
const TICKETCHILDCOLLECTIONNAME = "ticket_status";
const USERPARENTCOLLECTION = "users";
const CHILDCOLLECTIONNAME = "messages";
const ARTICLECHILDCOLLECTIONNAME = "articlemessages";
const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY

export {
  CHILDCOLLECTIONNAME,
  PARENTCOLLECTIONNAME,
  TICKETCHILDCOLLECTIONNAME,
  TICKETCOLLECTIONNAME,
  USERPARENTCOLLECTION,
  ARTICLEPARENTCOLLECTIONNAME,
  ARTICLECHILDCOLLECTIONNAME,
  VAPID_KEY,
  app,
  db,
  messaging,
  storage,
};
