import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  imageLoading: false,
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    getAllRegions: (state) => {
      state.loading = true;
    },
    setGetAllRegions: (state) => {
      state.loading = false;
    },
    checkExistCategory: (state) => {
      state.loading = true;
    },
    setCheckExistCategory: (state) => {
      state.loading = false;
    },
    getDashboardUsers: (state) => {
      state.loading = true;
    },
    setDashboardUsers: (state) => {
      state.loading = false;
    },
    userList: (state) => {
      state.loading = true;
    },
    setUserList: (state) => {
      state.loading = false;
    },
    userDetail: (state) => {
      state.loading = true;
    },
    setuserDetail: (state) => {
      state.loading = false;
    },
    adminDetail: (state) => {
      state.loading = true;
    },
    deleteUserByadmin: (state) => {
      state.loading = true;
    },
    setDeleteUserByadmin: (state) => {
      state.loading = false;
    },
    updateStatus: (state) => {
      state.loading = true;
    },
    setUpdateStatus: (state) => {
      state.loading = false;
    },
    articleCategory: (state) => {
      state.loading = true;
    },
    setArticleCategory: (state) => {
      state.loading = false;
    },
    articleSubCategory: (state) => {
      state.loading = true;
    },
    setArticleSubCategory: (state) => {
      state.loading = false;
    },
    deleteArticle: (state) => {
      state.loading = true;
    },
    setDeleteArticle: (state) => {
      state.loading = false;
    },
    createCategory: (state) => {
      state.loading = true;
    },
    setCreateCategory: (state) => {
      state.loading = false;
    },
    getOneCategory: (state) => {
      state.loading = true;
    },
    setOneCategory: (state) => {
      state.loading = false;
    },
    updateOneCategory: (state) => {
      state.loading = true;
    },
    setupdateOneCategory: (state) => {
      state.loading = false;
    },
    addSubCategory: (state) => {
      state.loading = true;
    },
    setAddSubCategory: (state) => {
      state.loading = false;
    },
    getAllPublications: (state) => {
      state.loading = true;
    },
    setgetAllPublications: (state) => {
      state.loading = false;
    },
    getOnePublications: (state) => {
      state.loading = true;
    },
    setgetOnePublications: (state) => {
      state.loading = false;
    },
    deletePublications: (state) => {
      state.loading = true;
    },
    setDeletePublications: (state) => {
      state.loading = false;
    },
    addPublications: (state) => {
      state.loading = true;
    },
    setAddPublications: (state) => {
      state.loading = false;
    },
    editPublications: (state) => {
      state.loading = true;
    },
    setEditPublications: (state) => {
      state.loading = false;
    },
    uploadPublicationsLogo: (state) => {
      state.imageLoading = true;
    },
    setUploadPublicationsLogo: (state) => {
      state.imageLoading = false;
    },
    getCategories: (state) => {
      state.loading = true;
    },
    setGetCategories: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getDashboardUsers,
  setDashboardUsers,
  userList,
  setUserList,
  userDetail,
  setuserDetail,
  adminDetail,
  deleteUserByadmin,
  setDeleteUserByadmin,
  updateStatus,
  setUpdateStatus,
  articleCategory,
  setArticleCategory,
  deleteArticle,
  setDeleteArticle,
  createCategory,
  setCreateCategory,
  getOneCategory,
  setOneCategory,
  updateOneCategory,
  setupdateOneCategory,
  addSubCategory,
  setAddSubCategory,
  checkExistCategory,
  setCheckExistCategory,
  getAllPublications,
  setgetAllPublications,
  getOnePublications,
  setgetOnePublications,
  deletePublications,
  setDeletePublications,
  addPublications,
  setAddPublications,
  uploadPublicationsLogo,
  setUploadPublicationsLogo,
  articleSubCategory,
  setArticleSubCategory,
  editPublications,
  setEditPublications,
  getCategories,
  setGetCategories,
  onErrorStopLoad,
  getAllRegions,
  setGetAllRegions,
} = webSlice.actions;

export default webSlice.reducer;
