import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-time-picker/assets/index.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
// import "rc-tooltip/assets/bootstrap.css";
import { Multiselect } from "multiselect-react-dropdown";

import {
  addPublications,
  editPublications,
  getCategories,
  uploadPublicationsLogo,
} from "../../redux/slices/dashboard";
import * as images from "../../utilities/images";

const AddPublications = () => {
  const location = useLocation();
  const { state } = location;
  const publishData = state?.publishList?.data;
  console.log(publishData?.markup, "publishData");

  const toastId = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const publicationSelector = UseDashBoardSelector();
  const { loading } = publicationSelector;
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [markup, setMarkUp] = useState(publishData?.markup ?? "");
  console.log(markup, "markup");

  const [selectedSubCategory, setSelectedSubCategory] = useState(
    publishData?.subCategoryId || ""
  );
  console.log("selectedCategory", selectedCategory);
  const [name, setName] = useState(publishData?.name || "");

  const [priceForPremium, setPriceForPremium] = useState(
    publishData?.amount || ""
  );

  const [da, setDa] = useState(publishData?.da || "");
  const [time, setTime] = useState(publishData?.selectTime || "");
  const [sponsored, setSponsored] = useState(publishData?.sponsored || "");
  const [indexed, setIndexed] = useState(publishData?.indexed || "");
  const [image, setImage] = useState(publishData?.publicationLogo || "");

  const [rating, setRating] = useState(publishData?.rating || "");
  const [region, setRegion] = useState(publishData?.region || "");
  const [weburl, setWeburl] = useState(publishData?.websiteUrl || "");
  const [priceField, setPriceField] = useState(
    publishData?.publicationType || "free"
  );
  const [dropDownImg, setDropDownImg] = useState(publishData?.image || "");
  const [niche, setNiche] = useState(publishData?.niche || "");
  const [doFollow, setDoFollow] = useState(publishData?.doFollow || "");
  const [status, setStatus] = useState("");

  const priceHandle = (flag) => {
    setPriceField(flag);
  };

  // Handle file change
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024;

    if (file.size > maxSize) {
      showToast("File size should be less than 5 MB");
      return;
    }

    let params = {
      image: file,
    };
    dispatch(
      uploadPublicationsLogo({
        ...params,
        cb(res) {
          if (res?.data) {
            setImage(res?.data?.data?.profile_photo);
          }
        },
      })
    );
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Ensure the value contains only the digits 1, 2, 3, 4, and 5 and is less than 6 characters long
    if (/^[1-5]*$/.test(inputValue) && inputValue.length < 2) {
      setRating(inputValue);
    }
  };

  //onchange input
  const handleNameChange = (e) => {
    const filteredValue = e.target.value.replace(/[^a-zA-Z\s]/g, "");
    setName(filteredValue);
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCategory?.length <= 0) {
      showToast("Please select genre");
      return;
    } else if (!selectedSubCategory) {
      showToast("Please select type");
      return;
    } else if (!name) {
      showToast("Please enter name");
      return;
    } else if (priceField === "premium" && !priceForPremium) {
      showToast("Please enter premium price");
      return;
    } else if (!da) {
      showToast("Please enter DA");
      return;
    } else if (!region) {
      showToast("Please enter region");
      return;
    } else if (!weburl) {
      showToast("Please enter website URL");
      return;
    } else if (
      weburl &&
      !/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([\/\?].*)?$/i.test(
        weburl
      )
    ) {
      showToast("Please enter valid website URL");
      return;
    } else if (!time) {
      showToast("Please select time");
      return;
    } else if (!sponsored) {
      showToast("Please enter sponsored");
      return;
    } else if (!indexed) {
      showToast("Please select indexed");
      return;
    } else if (!doFollow) {
      showToast("Please select do follow");
      return;
    } else if (!dropDownImg) {
      showToast("Please select image from condition");
      return;
    } else if (!niche) {
      showToast("Please select niche");
      return;
    } else if (!rating) {
      showToast("Please enter rating");
      return;
    } else if (!rating) {
      showToast("Please enter rating");
      return;
    } else if (!image) {
      showToast("Please upload image");
      return;
    }
    let selectedGenreIds = selectedCategory?.map((value) => value?._id);
    // let formatTime = moment(time).format("HH:mm");
    if (state?.type == "editPublish") {
      let params = {
        rating: rating?.toString(),
        id: publishData?._id,
        categoryId: selectedGenreIds,
        subCategoryId: selectedSubCategory,
        name: name,
        publicationType: priceField,
        da: da,
        selectTime: time,
        sponsored: sponsored,
        indexed: indexed,
        publicationLogo: image,
        region: region,
        websiteUrl: weburl,
        image: dropDownImg,
        niche: niche,
        doFollow: doFollow,
      };
      if (priceField === "premium") {
        params.amount = priceForPremium;
        params.markup = markup || undefined;
      } else {
        params.amount = 0;
      }
      dispatch(
        editPublications({
          ...params,
          cb(res) {
            if (res?.data) {
              navigate("/publications");
            }
          },
        })
      );
    } else {
      let params = {
        rating: rating?.toString(),
        categoryId: selectedGenreIds,
        subCategoryId: selectedSubCategory,
        name: name,
        publicationType: priceField,
        da: da,
        selectTime: time,
        sponsored: sponsored, //  yes:no
        indexed: indexed, // yes:no
        publicationLogo: image,
        region: region,
        websiteUrl: weburl,
        image: dropDownImg,
        niche: niche,
        doFollow: doFollow,
      };
      if (priceField === "premium") {
        params.amount = priceForPremium;
        params.markup = markup || undefined;
      } else {
        params.amount = 0;
      }
      dispatch(
        addPublications({
          ...params,
          cb(res) {
            if (res?.data) {
              navigate("/publications");
            }
          },
        })
      );
    }
  };

  const navigateToPublication = () => {
    navigate("/publications"); // Navigate to the desired page
  };

  //API for get all category...............
  // const getAllCategories = () => {
  //   dispatch(
  //     articleCategory({
  //       cb(res) {
  //         if (res?.data) {
  //           setCategory(res?.data?.data?.data);
  //         }
  //       },
  //     })
  //   );
  // };

  const getAllCategories = () => {
    dispatch(
      getCategories({
        cb(res) {
          if (res.status) {
            setCategory(
              res?.data?.data?.data.filter((item) => item.flag === "genre")
            );
            setSubCategory(
              res?.data?.data?.data.filter((item) => item.flag === "type")
            );
          }
        },
      })
    );
  };

  // const getAllSubCategories = (id) => {
  //   dispatch(
  //     articleSubCategory({
  //       cb(res) {
  //         if (res?.data) {
  //           setSubCategory(res?.data?.data?.data);
  //         }
  //       },
  //     })
  //   );
  // };

  const handleCategoryChange = (data) => {
    setSelectedCategory(data);
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  useEffect(() => {
    if (publishData?.categoryIds && category?.length > 0) {
      const selectCatvalues = category?.filter((value) => {
        return publishData.categoryIds
          .map((categoryId) => categoryId.categoryId)
          .includes(value?._id);
      });
      setSelectedCategory(selectCatvalues);
    }
  }, [publishData?.categoryIds, category]);

  useEffect(() => {
    getAllCategories();
    // getAllSubCategories()
  }, []);

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <div className="addPublishSec content-wrap">
        <div className="userDeatilHead my-4">
          <img
            src={images.arrowMain}
            alt="arrowMainimg"
            className="img-fluid pointCursor"
            onClick={() => navigateToPublication()}
          />
          <h1 className="mainHead32">
            {state?.type == "editPublish"
              ? "Edit Publications"
              : "Add Publications"}
          </h1>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="row">
            <div className="col-md-8">
              <div className="addPublishLeft">
                <div className="selectContainer mt-3">
                  <label className="input_Label">Add Genre</label>
                  <Multiselect
                    onSelect={handleCategoryChange}
                    onRemove={handleCategoryChange}
                    displayValue="name"
                    options={category}
                    showArrow={true}
                    selectedValues={selectedCategory}
                    className="genreMultiselect"
                    placeholder="Add Genre"
                    avoidHighlightFirstOption={true}
                    closeOnSelect={false}
                    customArrow={
                      <img
                        className="dropdown-icon-multiselect"
                        src={images.selectArrow}
                        alt="droparrow"
                      />
                    }
                  />
                  {/* <FormControl>
                    <Select
                      className="customSelect"
                      value={selectedCategory}
                      onChange={(e) => handleCategoryChange(e.target.value)}
                      label="Category"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        fontFamily: "Poppins",
                        ".MuiSvgIcon-root": {
                          color: "#fff", // Change this to the desired color
                        },
                        fontStyle: "normal",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "12px",
                            border: "1px solid #FFFFFF4D",
                            background: "#616161",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                              {
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "inherit",
                                marginTop: "3px",
                                "&.Mui-selected": {
                                  backgroundColor: "#757575",
                                },
                                "&:hover": {
                                  backgroundColor: "#757575",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "#757575",
                                },
                              },
                          },
                        },
                      }}
                    >
                      <MenuItem value={selectedCategory} disabled>
                        Add Genre
                      </MenuItem>

                      {category.map((category) => (
                        <MenuItem value={category?._id}>
                          {category?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </div>

                <div className="selectContainer mt-3">
                  <label className="input_Label">Add Type</label>
                  <FormControl>
                    <Select
                      className="customSelect"
                      value={selectedSubCategory}
                      onChange={handleSubCategoryChange}
                      label="Category"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        fontFamily: "Poppins",
                        ".MuiSvgIcon-root": {
                          color: "#fff", // Change this to the desired color
                        },
                        fontStyle: "normal",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "12px",
                            border: "1px solid #FFFFFF4D",
                            background: "#616161",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                              {
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "inherit",
                                marginTop: "3px",
                                "&.Mui-selected": {
                                  backgroundColor: "#757575",
                                },
                                "&:hover": {
                                  backgroundColor: "#757575",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "#757575",
                                },
                              },
                          },
                        },
                      }}
                    >
                      <MenuItem value={selectedSubCategory} disabled>
                        Add Type
                      </MenuItem>

                      {subCategory.map((subCategory) => (
                        <MenuItem value={subCategory?._id}>
                          {subCategory?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="publishInput mt-3">
                  <label className="input_Label">Name</label>
                  <input
                    type="text"
                    className="form-control nameControl"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => handleNameChange(e)}
                  />
                </div>

                <div className="priceInput mt-3 publication_Premium">
                  <div>
                    <input
                      type="radio"
                      id="test1"
                      name="radio-group"
                      checked={priceField === "free"}
                      onClick={() => priceHandle("free")}
                    />
                    <label for="test1" className="premiumLabel free">
                      Free
                    </label>
                  </div>
                  <div className="premiumLabel_Parent">
                    <input
                      type="radio"
                      id="test2"
                      name="radio-group"
                      checked={priceField === "premium"}
                      onClick={() => priceHandle("premium")}
                    />
                    <label for="test2" className="premiumLabel">
                      Premium
                    </label>
                  </div>
                </div>
                {priceField === "premium" && (
                  <>
                    <div className="priceInput mt-3">
                      <p className="priceSign">$</p>
                      <input
                        type="number"
                        className="form-control nameControl"
                        name="Price for premium"
                        placeholder="Price for premium"
                        max={10000}
                        value={priceForPremium}
                        onChange={(e) => {
                          const newValue = Math.min(
                            Number(e.target.value),
                            10000
                          );
                          if (newValue === 0) {
                            setPriceForPremium("");
                          } else {
                            setPriceForPremium(newValue);
                          }
                        }}
                      />
                    </div>

                    {/* <div className="premiumPrice_Parent mt-3 mb-3">
                      <h3 className="premiumPrice_Txt">Price for premium</h3>
                      <Slider
                        min={0}
                        max={10000}
                        className="custom-slider"
                        value={priceForPremium || 0}
                        onChange={setPriceForPremium}
                      />
                      <div className="range-prices">
                        <span className="min-range-price">
                          {USDollar.format(priceForPremium)}
                        </span>
                        <span className="max-range-price">
                          {USDollar.format(10000)}
                        </span>
                      </div>
                    </div> */}
                    <div className="publishInput mt-3">
                      <label className="input_Label">Markup</label>
                      <input
                        type="number"
                        className="form-control nameControl"
                        name="markup"
                        placeholder="Markup(percentage)"
                        value={markup}
                        onChange={(e) => {
                          // Allow only numbers and periods (for percentage)
                          const value = e.target.value;
                          const filteredValue = value.replace(/[^0-9.]/g, "");
                          setMarkUp(filteredValue);
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="publishInput mt-3">
                  <label className="input_Label">DA</label>
                  <input
                    type="number"
                    className="form-control nameControl"
                    name="DA"
                    placeholder="DA"
                    value={da}
                    onChange={(e) => {
                      setDa(e.target.value);
                    }}
                  />
                </div>
                <div className="publishInput mt-3">
                  <label className="input_Label">Region</label>
                  <input
                    type="text"
                    className="form-control nameControl"
                    name="Region"
                    placeholder="Enter Region"
                    value={region}
                    onChange={(e) => {
                      setRegion(e.target.value);
                    }}
                  />
                </div>
                <div className="publishInput mt-3">
                  <label className="input_Label">Website URL</label>
                  <input
                    type="text"
                    className="form-control nameControl"
                    value={weburl}
                    name="weburl"
                    placeholder="Enter Website URL"
                    onChange={(e) => {
                      setWeburl(e.target.value);
                    }}
                  />
                </div>
                <div className="publishInput mt-3">
                  <label className="input_Label">Time</label>
                  <input
                    type="text"
                    className="form-control nameControl"
                    name="time"
                    placeholder="Enter time"
                    value={time}
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="publishInput mt-3 publication_SelectTime">
                  <TimePicker
                    showSecond={false}
                    className="timepicker-outer"
                    onChange={setTime}
                    value={time}
                    format="HH:mm"
                    placeholder="Select time"
                    inputReadOnly
                    // clearIcon={
                    //   <img
                    //     alt="clock-icon"
                    //     className="clock-icon"
                    //     src={Images.modalCross}
                    //   />
                    // }
                    clearIcon=""
                    inputIcon={
                      <img
                        alt="clock-icon"
                        className="clock-icon"
                        src={Images.clockImg}
                      />
                    }
                  />
                </div> */}
                <div className="publishInput mt-3">
                  {/* <select
                    value={sponsored}
                    onChange={(e) => {
                      setSponsored(e.target.value);
                    }}
                    className="w-100 selectBx"
                  >
                    <option value="">Sponsored</option>
                    <option value="yes"> Yes</option>
                    <option value="no">No</option>
                  </select> */}
                  <label className="input_Label">Sponsored</label>
                  <FormControl>
                    <Select
                      className="customSelect"
                      value={sponsored}
                      onChange={(e) => {
                        setSponsored(e.target.value);
                      }}
                      label="Category"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        fontFamily: "Poppins",
                        ".MuiSvgIcon-root": {
                          color: "#fff", // Change this to the desired color
                        },
                        fontStyle: "normal",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "12px",
                            border: "1px solid #FFFFFF4D",
                            background: "#616161",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                              {
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "inherit",
                                marginTop: "3px",
                                "&.Mui-selected": {
                                  backgroundColor: "#757575",
                                },
                                "&:hover": {
                                  backgroundColor: "#757575",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "#757575",
                                },
                              },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Sponsored
                      </MenuItem>
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No </MenuItem>
                      <MenuItem value="discrete">Discrete </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="publishInput mt-3">
                  {/* <select
                    value={indexed}
                    onChange={(e) => {
                      setIndexed(e.target.value);
                    }}
                    className="w-100 selectBx"
                  >
                    <option value="">Indexed</option>
                    <option value="yes"> Yes</option>
                    <option value="no">No</option>
                  </select> */}
                  <label className="input_Label">Indexed</label>
                  <FormControl>
                    <Select
                      className="customSelect"
                      value={indexed}
                      onChange={(e) => {
                        setIndexed(e.target.value);
                      }}
                      label="Category"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        fontFamily: "Poppins",
                        ".MuiSvgIcon-root": {
                          color: "#fff", // Change this to the desired color
                        },
                        fontStyle: "normal",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "12px",
                            border: "1px solid #FFFFFF4D",
                            background: "#616161",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                              {
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "inherit",
                                marginTop: "3px",
                                "&.Mui-selected": {
                                  backgroundColor: "#757575",
                                },
                                "&:hover": {
                                  backgroundColor: "#757575",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "#757575",
                                },
                              },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Indexed
                      </MenuItem>
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                      <MenuItem value="maybe">Maybe</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="publishInput mt-3">
                  <label className="input_Label">Do Follow</label>
                  <FormControl>
                    <Select
                      className="customSelect"
                      value={doFollow}
                      onChange={(e) => setDoFollow(e.target.value)}
                      label="Category"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        fontFamily: "Poppins",
                        ".MuiSvgIcon-root": {
                          color: "#fff", // Change this to the desired color
                        },
                        fontStyle: "normal",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "12px",
                            border: "1px solid #FFFFFF4D",
                            background: "#616161",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                              {
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "inherit",
                                marginTop: "3px",
                                "&.Mui-selected": {
                                  backgroundColor: "#757575",
                                },
                                "&:hover": {
                                  backgroundColor: "#757575",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "#757575",
                                },
                              },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Do Follow
                      </MenuItem>
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="publishInput mt-3">
                  <label className="input_Label">Image</label>
                  <FormControl>
                    <Select
                      className="customSelect"
                      value={dropDownImg}
                      onChange={(e) => setDropDownImg(e.target.value)}
                      label="Category"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        fontFamily: "Poppins",
                        ".MuiSvgIcon-root": {
                          color: "#fff", // Change this to the desired color
                        },
                        fontStyle: "normal",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "12px",
                            border: "1px solid #FFFFFF4D",
                            background: "#616161",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                              {
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "inherit",
                                marginTop: "3px",
                                "&.Mui-selected": {
                                  backgroundColor: "#757575",
                                },
                                "&:hover": {
                                  backgroundColor: "#757575",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "#757575",
                                },
                              },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Image
                      </MenuItem>
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="maybe">Maybe</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="publishInput mt-3">
                  <label className="input_Label">Niche</label>
                  <FormControl>
                    <Select
                      className="customSelect"
                      value={niche}
                      onChange={(e) => setNiche(e.target.value)}
                      label="Category"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        fontFamily: "Poppins",
                        ".MuiSvgIcon-root": {
                          color: "#fff",
                        },
                        fontStyle: "normal",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "12px",
                            border: "1px solid #FFFFFF4D",
                            background: "#616161",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                              {
                                color: "#ffffff",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontFamily: "inherit",
                                marginTop: "3px",
                                "&.Mui-selected": {
                                  backgroundColor: "#757575",
                                },
                                "&:hover": {
                                  backgroundColor: "#757575",
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "#757575",
                                },
                              },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Niche
                      </MenuItem>
                      <MenuItem value="erotic">Erotic</MenuItem>
                      <MenuItem value="health">Health</MenuItem>
                      <MenuItem value="crypto">Crypto</MenuItem>
                      <MenuItem value="cbd">Cbd</MenuItem>
                      <MenuItem value="gambling">Gambling</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="publishInput mt-3">
                  <label className="input_Label">Rating</label>
                  <input
                    type="Number"
                    className="form-control nameControl"
                    name="Rating"
                    placeholder="Rating"
                    value={rating}
                    maxLength={1}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            {/* Image upload section */}
            <div className="col-md-4">
              <div className="addPublishRight">
                <div className="uploadOuter mt-3">
                  <figure className="uploadInner">
                    <label className="uploadLabel">
                      <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={handleImageUpload}
                        accept="image/*"
                      />
                      {image ? (
                        <img
                          src={image}
                          alt="defaultUser"
                          className="publishLogoImg"
                        />
                      ) : (
                        <img
                          src={images.uploadfile}
                          alt="defaultUser"
                          className="fileImg"
                        />
                      )}
                    </label>
                  </figure>
                  <h4 className="form_subheading">Upload Image</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2">
            <button
              className="loginBtnCommon btnYellow mt-3 mw-100 loginbtn"
              type="submit"
              disabled={loading}
            >
              <span>
                {" "}
                {state?.type == "editPublish" ? "Update" : "Add"}
                {loading && (
                  <span className="spinner-border spinner-border-sm ms-1"></span>
                )}
              </span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddPublications;
