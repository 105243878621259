import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateOneSocialLink } from "../../redux/slices/socialLinks";

const EditSocialLinksModal = ({
  close,
  selectedSocialLinks,
  getAllSocialLinks,
}) => {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [name, setName] = useState(selectedSocialLinks?.name);
  const [socialLink, setSocialLink] = useState(selectedSocialLinks?.link);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    // if (!name) {
    //   showToast("Please Enter name");
    //   return;
    // } else if (!name) {
    //   showToast("Please Enter Slug");
    //   return;
    // }
    if (!socialLink) {
      showToast("Please enter link");
      return;
    } else if (
      socialLink &&
      !/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([\/\?].*)?$/i.test(
        socialLink
      )
    ) {
      showToast("Please enter valid url");
      return;
    }
    let params = {
      id: selectedSocialLinks?._id,
      // name: name,
      // slug: slug,
      link: socialLink,
    };
    dispatch(
      updateOneSocialLink({
        ...params,
        cb(res) {
          if (res?.data) {
            getAllSocialLinks();
            close();
          }
        },
      })
    );
  };

  return (
    <>
      <form>
        <div className="categoryModalSection">
          <div className="categoryForm">
            <div>
              <label className="input_Label">Name</label>
              <input
                class="form-control categoryInput disabledCursor"
                type="text"
                placeholder="Name"
                value={name}
                disabled
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/* <input
              class="form-control categoryInput mt-2 disabledCursor"
              type="text"
              placeholder="Slug"
              value={slug}
              disabled
              onChange={(e) => setSlug(e.target.value)}
            /> */}
            <div className="mt-2">
              <label className="input_Label">URL</label>
              <input
                class="form-control categoryInput"
                type="url"
                placeholder="URL"
                value={socialLink}
                onChange={(e) => setSocialLink(e.target.value)}
              />
            </div>
          </div>
          <button
            className="blockbtn btnYellow w-100"
            type="submit"
            onClick={(e) => handleUpdate(e)}
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default EditSocialLinksModal;
