import React from "react";
import { useNavigate } from "react-router-dom";

const PublicationGenreModal = ({ allCategoryNames, flag }) => {
  const navigate = useNavigate();
  console.log(allCategoryNames, "allCategoryNames");

  return (
    <div class="categoryOuterBox">
      {flag === "Publication" ? (
        <div className="publishInfo pointCursor">
          {allCategoryNames?.map((value) => (
            <>
              <div
                className="publishInfo"
                onClick={() =>
                  navigate("/publicationsDetails", {
                    state: {
                      publicationId: value?.publicationId,
                    },
                  })
                }
              >
                <figure className="publishOutLogo">
                  <img
                    src={value?.publicationLogo}
                    alt="publication Logo"
                    className="img-fluid publishImg"
                  />
                </figure>
                <h4 className="tableSubText">
                  {value?.publicationName || "---"}
                </h4>
              </div>
            </>
          ))}
        </div>
      ) : (
        allCategoryNames?.map((value) => (
          <div className="categoryBoxModal">{value?.categoryName}</div>
        ))
      )}
    </div>
  );
};

export default PublicationGenreModal;
