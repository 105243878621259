import React from "react";
import { Outlet } from "react-router-dom";
import MainFooter from "../common/MainFooter";
import MainNav from "../common/MainNav";
import Sidebar from "../common/Sidebar";

const DashboardLayout = () => {


  return (
    <>
      {/* <AuthNav /> */}
      {/* {authRoutes.includes(pathName) ? (
        <>
          <main className="main"><Outlet /></main>
        </>
      ) : (
        <> */}
      <div className="mainBox">
        <Sidebar />
        <main className="maincontainer_">
          <MainNav />
          <Outlet />
        </main>
      </div>

      <MainFooter />
      {/* </>
      )} */}
    </>
  );
};

export default DashboardLayout;
