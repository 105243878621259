import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UseDashBoardSelector } from "../../redux/selector/dashboard";
import { articleCategory } from "../../redux/slices/dashboard";
import * as images from "../../utilities/images";
import { DeleteIcon, editPen } from "../../utilities/images";
import CustomModal from "../Modal/CustomModal";
import DeleteCategoryModal from "../Modal/DeleteCategoryModal";
import CreateCategoryModal from "../Modal/createCategoryModal";
import UpdateCategoryModal from "../Modal/updateCategoryModal";
import SubCategoryModal from "../Modal/subCategoryModal";

const ArticleCategories = () => {
  document.title = "Article Genre";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const caregorySelector = UseDashBoardSelector();
  const [categoryList, setCategoryList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedParentCategory, setSelectedParentCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [key, setKey] = useState(Math.random());
  const [status, setStatus] = useState("genre");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    // List of suggestions (replace with actual data)
    const suggestions = categoryList || [];

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setCurrentPage(1);

    // Filter suggestions based on input
    const filtered = suggestions.filter((item) =>
      item?.name?.toLowerCase().includes(value.toLowerCase())
    );
    
    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion?.name);
    setFilteredSuggestions([]);
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  /**
   *
   * @param {*} flag
   * @param {*} data
   */
  const handleOpenModal = (flag, data) => {
    setSelectedParentCategory(data);
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  //on change page pagination
  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected + 1);
  };

  //API for get all type category...............
  const getAllCategories = () => {
    let params = {
      flag: status,
      page: currentPage,
      limit: 10,
    };
    if (Boolean(searchValue)) {
      params.search = searchValue;
    }
    dispatch(
      articleCategory({
        ...params,
        cb(res) {
          if (res?.data) {
            setCategoryList(res?.data?.data?.data);
            setTotalPage(res?.data?.data?.total_pages);
          }
        },
      })
    );
  };

  /**
   * to navigate to sub categroy page
   * @param {*} data
   */

  const handleGoToSubCategory = (data) => {
    navigate(`/sub-category/${data?._id}`, {
      state: {
        selectedParentCategory: data?.name,
      },
    });
  };

  useEffect(() => {
    getAllCategories();
  }, [currentPage, searchValue, status]);

  return (
    <>
      <div className="content-wrap categories article_Genre">
        <div className="content-header">
          <div className="innerHeadFlex mt-0">
            <h1 className="mainHead32">Article Genre & Type</h1>
            <div>
              <button
                className="loginBtnCommon btnYellow add_btn m-0"
                onClick={() => {
                  handleOpenModal("createcategory", "genre");
                }}
              >
                Create New Genre
              </button>
              <button
                className="loginBtnCommon btnYellow add_btn  ms-2"
                onClick={(e) => {
                  handleOpenModal("createcategory", "type");
                }}
              >
                Create New Type
              </button>
            </div>
          </div>
          <div className="searchSection">
            <input
              class="form-control searchInput"
              type="text"
              placeholder="Search by Genre & Type"
              value={searchValue}
              // onChange={(e) => {
              //   setSearchValue(e.target.value);
              //   setCurrentPage(1);
              // }}
              onChange={handleSearchChange}
            />
            <img
              src={images.searchImg}
              alt="searchImg"
              className="img-fluid searchBarIcon"
            />
            {searchValue && filteredSuggestions.length > 0 && (
            <ul className="autocomplete-list pointCursor">
              {filteredSuggestions.map((suggestion) => (
                <li
                  key={suggestion._id}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="autocomplete-item"
                >
                  {suggestion?.name}
                </li>
              ))}
            </ul>
          )}
          </div>
          <div className="userListStatus">
            <h4
              className={
                status === "genre" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("genre")}
            >
              Genre
            </h4>
            <h4
              className={
                status === "type" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => setStatus("type")}
            >
              Type
            </h4>
          </div>
          <div className="table-responsive recentTable categoryTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  {status === "genre" ? (
                    <th scope="col" className="recentHead">
                      Genre
                    </th>
                  ) : (
                    <th scope="col" className="recentHead">
                      Type
                    </th>
                  )}

                  <th scope="col" className="recentHead text-start">
                    ACTION
                  </th>
                </tr>
              </thead>
              {caregorySelector?.loading ? (
                <>
                  <tbody>
                    <tr>
                      <td colSpan={9}>
                        <div className="loaderOuter d-flex justify-content-center text-light">
                          <div
                            className="spinner-grow text-light"
                            role="status"
                          ></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <tbody>
                  {categoryList?.length > 0 ? (
                    categoryList?.map((data, idx) => {
                      const serialNumber = (currentPage - 1) * 10 + idx + 1;
                      return (
                        <tr>
                          <td className="recentSubHead">
                            <h4 className="tableSubText">{serialNumber}</h4>
                          </td>
                          <td className="recentSubHead">
                            <div
                              className="nameProfile"
                              // onClick={(e) => handleGoToSubCategory(data)}
                            >
                              <span className="tableSubText">{data?.name}</span>
                            </div>
                          </td>
                          {/* <td className="recentSubHead">
                            <h4 className="tableSubText">
                              {data?.subCategories
                                ? data?.subCategories
                                : "---"}
                            </h4>
                          </td> */}

                          <td className="recentSubHead text-center">
                            <div className="categoryOuterBtn">
                              <button
                                className="editBtn"
                                type="button"
                                onClick={() => {
                                  handleOpenModal("updateCategoryModal", data);
                                }}
                              >
                                <img
                                  src={editPen}
                                  className="img-fluid "
                                  alt="editPen image"
                                />
                                Edit
                              </button>
                              <button
                                className="delete_btn"
                                type="button"
                                onClick={(e) =>
                                  handleOpenModal("deleteCategoryModal", data)
                                }
                              >
                                <img
                                  src={DeleteIcon}
                                  className="img-fluid "
                                  alt="eyeImg"
                                />
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className=" bg-transparent noFoundHead">
                        <h3 className="text-center subhead24 m-0">
                          No {status === "genre" ? "Genre" : "Type"} Found!
                        </h3>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
          {categoryList && categoryList?.length > 0 && (
            <div className=" paginateSec">
              <ReactPaginate
                className="paginationBox mb-0"
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="< "
                renderOnZeroPageCount={null}
                forcePage={currentPage - 1}
              />
            </div>
          )}
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "deleteCategoryModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "createcategory" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "deleteCategoryModal"
            ? "createCategoryModal"
            : modalDetail.flag === "createsubcategory"
            ? "subCategoryModal"
            : ""
        }
        child={
          modalDetail.flag === "createsubcategory" ? (
            <SubCategoryModal
              getAllCategories={getAllCategories}
              close={() => {
                handleOnCloseModal();
                // close();
              }}
              flag={"addSubCate"}
              // categoryName={categoryName}
            />
          ) : modalDetail.flag === "createcategory" ? (
            <CreateCategoryModal
              getAllCategories={getAllCategories}
              selectedParentCategory={selectedParentCategory}
              close={() => handleOnCloseModal()}
              categoryType={setStatus}
            />
          ) : modalDetail.flag === "updateCategoryModal" ? (
            <UpdateCategoryModal
              getAllCategories={() => getAllCategories()}
              selectedParentCategory={selectedParentCategory}
              close={() => handleOnCloseModal()}
              status={status}
            />
          ) : modalDetail.flag === "deleteCategoryModal" ? (
            <DeleteCategoryModal
              getAllCategories={() => getAllCategories()}
              selectedParentCategory={selectedParentCategory}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "createcategory" ? (
            <>
              <h2 className="modal_Heading">
                Create New{" "}
                {selectedParentCategory === "genre" ? "Genre" : "Type"}
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "updateCategoryModal" ? (
            <>
              <h2 className="modal_Heading">
                Update {status === "genre" ? "Genre" : "Type"}
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "deleteCategoryModal" ? (
            <>
              <h2 className="modal_Heading">
                Are you sure you want to Delete?
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "createsubcategory" ? (
            <>
              <h2 className="modal_Heading">Add Type</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCross}
                  className="ModalCancel img-fluid"
                  alt=""
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};
export default ArticleCategories;
