import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLogout } from "../../redux/slices/auth";

const LogoutModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // logout handler
  const handleLogout = () => {
    dispatch(
      adminLogout({
        cb(res) {
          if (res?.status === 200) {
            navigate("/");
          }
        },
      })
    );
  };

  return (
    <>
      <div className="deleteModalsec text-center my-3">
        <button className="blockbtn btnYellow w-100" onClick={handleLogout}>
          Yes, Logout
        </button>
      </div>
    </>
  );
};

export default LogoutModal;
